export const hideBannerRoutes = [
  '/sign-in',
  '/sign-up',
  '/user-verification',
  '/user-verification/accept',
  '/user-verification/reject',
  '/user-verification/refer',
  '/user-verification/resubmit',
  '/user-verification/review',
  '/timeout',
  '/beta_success',
  '/account/confirm-password',
  '/account/forgot-password',
  '/account/reset-password-success',
];
