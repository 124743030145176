import { showNotification } from '@mantine/notifications';
import { useCallback, useContext, useMemo } from 'react';

import { UserContext } from '~/components/providers/UserProvider';
import { reportEvent } from '~/domains/analytics';
import { Routes } from '~/domains/common/constants/routes';

export const useGenerateContestLink = (contestId: string, utm_medium = 'ContestDetails') => {
  const { user } = useContext(UserContext);
  const contestURL = `${window.location.origin}${Routes.contestDetail(contestId, {
    utm_source: 'SplashWeb',
    utm_medium,
    utm_content: 'btn_invite',
    ...(contestId && { utm_campaign: contestId }),
    ...(user?.id && { utm_referrer: user?.id }),
  })}`;
  return contestURL;
};

export const useCopyContestLink = (
  contestId: string,
  contestName = '',
  utm_medium = 'ContestDetails'
) => {
  const contestURL = useGenerateContestLink(contestId, utm_medium);
  const handleCopyLink = useCallback(async () => {
    try {
      if (
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/iu.exec(navigator.userAgent) &&
        navigator.share
      ) {
        await navigator.share({ title: contestName, url: contestURL });
      } else {
        await window.navigator.clipboard.writeText(contestURL);

        showNotification({
          message: `Contest URL Copied!`,
          color: 'green',
          autoClose: 2500,
        });
      }

      reportEvent('Contest > Copy Contest Link', {
        contest_id: contestId,
        contest_name: contestName,
      });
    } catch (err) {
      // Native share throws "AbortError" type when users dismisses the share modal
      // We don't want to show an error for that
      if (err.name !== 'AbortError') {
        showNotification({
          message: `Could not copy URL: ${contestURL}.`,
          color: 'red',
          autoClose: 2500,
        });
      }
    }
  }, [contestId, contestName, contestURL]);

  return { handleCopyLink };
};
