import Cookies from 'js-cookie';

import { jwtDecodeSafe } from '~/utils/jwt';

interface AccessTokenDataRaw {
  sub: string; // old user id
  id: string; // user id
  'cognito:username': string;
  ryp_user_id?: string;
  ofp_user_id?: string;
}

interface AccessTokenData {
  id: string;
  username: string;
  rypUserId?: string;
  ofpUserId?: string;
}

function getAccessTokenData(
  accessToken: string | undefined = Cookies.get('accessToken')
): AccessTokenData {
  const rawData = jwtDecodeSafe<AccessTokenDataRaw>(accessToken);
  return {
    id: rawData?.id ?? rawData?.sub,
    username: rawData?.['cognito:username'],
    rypUserId: rawData?.ryp_user_id,
    ofpUserId: rawData?.ofp_user_id,
  };
}

function getHasUserLinkedRYP(
  accessToken: string | undefined = Cookies.get('accessToken')
): boolean {
  const { rypUserId } = getAccessTokenData(accessToken);
  return !!rypUserId;
}

function getHasUserLinkedOFP(
  accessToken: string | undefined = Cookies.get('accessToken')
): boolean {
  const { ofpUserId } = getAccessTokenData(accessToken);
  return !!ofpUserId;
}
export { getAccessTokenData, getHasUserLinkedRYP, getHasUserLinkedOFP };
