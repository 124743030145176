import Cookies from 'js-cookie';

import { isBrowserFn } from '~/utils/browser';

function isWebView() {
  if (!isBrowserFn()) return false;

  return (
    Boolean(window.location.search.match(/[&?]webView=true/i)) || Cookies.get('webView') === 'true'
  );
}

export default isWebView;
