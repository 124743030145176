const enum PermissionStatusState {
  PROMPT = 'prompt',
  GRANTED = 'granted',
  DENIED = 'denied',
  UNAVAILABLE = 'unavailable',
  TIMEOUT = 'timeout',
  UNKNOWN = 'unknown',
}

function saveGeoPermissionStatus(
  geoPermissions: PermissionStatus,
  callback: (status: PermissionStatusState) => void
) {
  if (!geoPermissions?.state) return;
  const geoPermissionStatus = geoPermissions.state
    .toString()
    .toLowerCase() as PermissionStatusState;

  // Some browsers (e.g. Safari) only return the 'prompt' state.
  // We need to call geolocation API to get the actual permission status
  if (geoPermissionStatus === PermissionStatusState.PROMPT) {
    navigator.geolocation.getCurrentPosition(
      () => callback(PermissionStatusState.GRANTED),
      (error) => {
        let permissionError;

        switch (error.code) {
          case error.PERMISSION_DENIED:
            permissionError = PermissionStatusState.DENIED;
            break;
          case error.POSITION_UNAVAILABLE:
            permissionError = PermissionStatusState.UNAVAILABLE;
            break;
          case error.TIMEOUT:
            permissionError = PermissionStatusState.TIMEOUT;
            break;
          default:
            permissionError = PermissionStatusState.UNKNOWN;
        }

        callback(permissionError);
      },
      { timeout: 30000 }
    );
  }

  callback(geoPermissionStatus);
}

const getGeoPermissions = async (callback: (status: PermissionStatusState) => void) => {
  if (!navigator?.permissions) return;

  // First check if we have a cached permission
  const geoPermissions = await navigator.permissions.query({ name: 'geolocation' });
  saveGeoPermissionStatus(geoPermissions, callback);

  // Start the onchange listener
  geoPermissions.onchange = () => saveGeoPermissionStatus(geoPermissions, callback);
  return Promise.resolve(geoPermissions);
};

export { PermissionStatusState, getGeoPermissions };
