import { hideBannerRoutes } from './consts';

export const isHideBannerRoute = (route) => hideBannerRoutes.includes(route);
export const isUserTimeout = (route, userDetails) =>
  userDetails && userDetails.timeout && route !== '/timeout';

// Banners
export const isUserExcluded = (userDetails) => userDetails && userDetails.status === 'excluded';
export const isUserLocked = (userDetails) =>
  userDetails && (userDetails.status === 'blocked' || userDetails.status === 'closed');
export const isUserDuplicate = (userDetails) => userDetails && userDetails.status === 'duplicate';
