import { SimpleGrid, Skeleton, Stack } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { PAYMENT_TYPE_TO_METHOD, PaymentType } from '~/common/enums/payments.enum';
import { Title } from '~/domains/design-system/Title';
import { PaymentTypeRadio } from '~/domains/payments/withdrawal/components/PaymentTypeRadio';

import { MAX_AEROPAY_ACCOUNTS } from '../../constants';
import { useAeropayLinkedBankAccounts } from '../../hooks/useAeropayLinkedBankAccounts';
import type { useAvailablePaymentMethods } from '../../hooks/useAvailablePaymentMethods';

type DepositMethodsProps = {
  availablePaymentMethods: ReturnType<typeof useAvailablePaymentMethods>['availablePaymentMethods'];
  bankAccountId?: string;
  paymentType: PaymentType;
  filteredPaymentTypes: PaymentType[];
  isLoading: boolean;
  onChange: (type: PaymentType, bankAccountId?: string) => void;
  isAeropayEnabled?: boolean;
};

export function DepositMethods({
  availablePaymentMethods,
  bankAccountId,
  filteredPaymentTypes,
  isLoading,
  paymentType,
  onChange,
  isAeropayEnabled,
}: DepositMethodsProps) {
  const { t } = useTranslation('payments');

  const { bankAccounts, isLoading: isLoadingAeropayLinkedBankAccounts } =
    useAeropayLinkedBankAccounts({ enabled: isAeropayEnabled });

  const getIsPaymentTypeDisabled = (type: PaymentType) => {
    if (type === PaymentType.AEROPAY && bankAccounts?.length >= MAX_AEROPAY_ACCOUNTS) {
      return { isDisabled: true, disabledReason: t('deposits.errors.aeropay.maxAccounts') };
    }

    const isDisabled = availablePaymentMethods?.some(
      (item) => item.paymentMethod === PAYMENT_TYPE_TO_METHOD[type] && item.status === 'disabled'
    );

    return {
      isDisabled,
    };
  };

  const data = filteredPaymentTypes.map((type) => {
    const { isDisabled, disabledReason } = getIsPaymentTypeDisabled(type);

    return {
      type,
      settings: { isFree: true, description: '', isDisabled, disabledReason },
    };
  });

  return (
    <Stack spacing={32}>
      {isLoadingAeropayLinkedBankAccounts ? <Skeleton height={110} /> : null}
      {isAeropayEnabled && bankAccounts?.length > 0 ? (
        <Stack spacing={16} align="center">
          <Title variant="subhead-medium">{t('deposits.savedDepositMethods')}</Title>
          <Stack spacing={8} w="100%">
            {bankAccounts?.map((bankAccount, index) => (
              <PaymentTypeRadio
                key={bankAccount.bankAccountId}
                type={PaymentType.AEROPAY}
                value={bankAccount.bankAccountId}
                settings={{ isFree: true, isDisabled: false }}
                isActive={bankAccountId === bankAccount.bankAccountId}
                superscriptIndex={index + 1}
                isWithdrawal={false}
                onChange={() => {
                  onChange(PaymentType.AEROPAY, bankAccount.bankAccountId);
                }}
                bankAccount={bankAccount}
                isDeleteBankAccountVisible
              />
            ))}
          </Stack>
        </Stack>
      ) : null}

      <Stack align="center">
        {isAeropayEnabled ? (
          <>
            {(() => {
              const aeropayMethods = data.filter(({ type }) => type === PaymentType.AEROPAY);
              return !isLoadingAeropayLinkedBankAccounts ? (
                <>
                  {bankAccounts?.length > 0 && aeropayMethods.length > 0 ? (
                    <Title variant="subhead-medium">{t('deposits.connectAnotherBank')}</Title>
                  ) : null}

                  <SimpleGrid cols={1} spacing={8} w="100%">
                    {aeropayMethods.map(({ type, settings }, index) => (
                      <PaymentTypeRadio
                        key={type}
                        type={type}
                        value={type}
                        settings={settings}
                        isActive={type === paymentType && bankAccountId === undefined}
                        superscriptIndex={index + 1}
                        isWithdrawal={false}
                        recommended={!bankAccounts?.length && !isLoadingAeropayLinkedBankAccounts}
                        description={t('deposits.recommendedDepositDescription')}
                        onChange={() => {
                          if (!settings.isDisabled) {
                            onChange(type);
                          }
                        }}
                      />
                    ))}
                  </SimpleGrid>
                </>
              ) : null;
            })()}
          </>
        ) : null}
      </Stack>

      <Stack spacing={16} align="center">
        {isAeropayEnabled && (
          <Title variant="subhead-medium">{t('deposits.otherDepositMethods')}</Title>
        )}
        {isLoading ? <Skeleton height={310} /> : null}

        <SimpleGrid cols={2} spacing={8} w="100%">
          {data
            .filter(({ type }) => type !== PaymentType.AEROPAY)
            .map(({ type, settings }, index) => (
              <PaymentTypeRadio
                key={type}
                type={type}
                value={type}
                settings={settings}
                isActive={type === paymentType && bankAccountId === undefined}
                superscriptIndex={index + 1}
                isWithdrawal={false}
                onChange={() => {
                  if (!settings.isDisabled) {
                    onChange(type);
                  }
                }}
              />
            ))}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
}
