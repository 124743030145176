import type { CardProps } from '@mantine/core';
import { Card } from '@mantine/core';
import { useCallback } from 'react';

import useGamecenterPath from '../../../gamecenter/domains/common/hooks/useGamecenterPath';

import type { ContestCardProps } from './types';

function CardContainer({
  contest,
  children,
  onClick,
  isStatic,
  promoSource,
  promoIndex,
  currentSlateStatus,
  ...paperProps
}: CardProps & ContestCardProps) {
  const contestPath = useGamecenterPath({
    contestId: contest.id,
    contestType: contest.contest_type,
    sport: contest.league.sport,
    contestStatus: contest.status,
    userEntriesCount: contest.entries.user,
    promoSource,
    promoIndex,
    currentSlateStatus,
  });

  const handleContestDetailOpen = useCallback(
    (e) => {
      onClick?.(e);
      // Dropdown menu should not trigger this event
      const ignoreLinkContainer = e.currentTarget.querySelector('[data-ignore-link="true"]');
      if (
        e.target !== e.currentTarget &&
        (ignoreLinkContainer?.contains(e.target) || e.target?.tagName === 'A')
      ) {
        e.preventDefault();

      }
    },
    [onClick]
  );

  return (
    <Card
      {...paperProps}
      component={isStatic ? 'div' : 'a'}
      href={contestPath}
      onClick={!isStatic ? handleContestDetailOpen : onClick}
      sx={{ border: 'none' }}
    >
      {children}
    </Card>
  );
}

export default CardContainer;
