import { Center, Skeleton, Stack, Text, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { ErrorAlert } from '~/domains/common/components/Alerts/Alerts';
import { Routes } from '~/domains/common/constants/routes';
import useProtectedGuard from '~/domains/protectionGuards/hooks/useProtectedGuard';
import { ProtectionGuardType } from '~/domains/protectionGuards/types';
import { BalanceBox } from '~/domains/payments/components/BalanceBox';
import { getTextColor } from '~/domains/payments/utils/utils';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { useAppDownloadPrompt, PromptLocation } from '~/hooks/useAppDownloadPrompt';
import dt from '~/testing';

import DepositCompleted from './DepositCompleted';
import { DepositFormLegacy } from './DepositFormLegacy';
import { DepositForm } from './DepositForm';

type DepositViewProps = {
  contestIdForPostDeposit?: string;
  onContinue?: VoidFunction;
  fallbackUrl?: string;
  isRedirectOnInvalidAccess?: boolean;
};

function DepositView({
  contestIdForPostDeposit,
  onContinue,
  fallbackUrl,
  isRedirectOnInvalidAccess,
}: DepositViewProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation('payments');
  const [completedDepositAmount, setCompletedDepositAmount] = useState<number>(null);

  const isDepositEnabled = useIsFeatureEnabled(
    FEATURE.ENABLE_CONTEST_DETAILS_JOIN_AND_DEPOSIT_BUTTONS
  );
  const isNewDepositUIEnabled = useIsFeatureEnabled(FEATURE.ENABLE_DEPOSIT_FACELIFT);

  const router = useRouter();
  const redirectTo = router.query.redirectTo as string;
  const previousRoute = (router.query.redirectTo as string) || Routes.home;

  const { isLoading: isProtectionLoading, isAccessible } = useProtectedGuard({
    action: 'make a deposit',
    protectionLevels: [ProtectionGuardType.KYC, ProtectionGuardType.LOCATION],
    checkOnMount: true,
    redirectOnClose: isRedirectOnInvalidAccess ? previousRoute : undefined,
  });

  const handleShowAppDownloadPrompt = useAppDownloadPrompt(PromptLocation.DEPOSIT);

  const handleSetCompletedDepositAmount = (amount: number) => {
    window.scrollTo(0, 0);
    setCompletedDepositAmount(amount);
  };

  const handleOnContinue = () => {
    if (!handleShowAppDownloadPrompt({ redirectTo })) {
      if (redirectTo) {
        router.push(redirectTo);
        return;
      }
      onContinue?.();
    }
  };

  if (completedDepositAmount !== null) {
    return <DepositCompleted depositAmount={completedDepositAmount} onClose={handleOnContinue} />;
  }

  return (
    <Stack
      data-test-id={dt.payments.deposits.depositView}
      maw={isNewDepositUIEnabled ? 450 : undefined}
      mx="auto"
      pos="relative"
    >
      {!isNewDepositUIEnabled && (
        <Center>
          <Text
            fw={700}
            size={32}
            color={getTextColor(theme)}
            data-test-id={dt.payments.deposits.depositViewTitle}
          >
            {t('deposits.form.title')}
          </Text>
        </Center>
      )}

      {!isNewDepositUIEnabled && <BalanceBox variant="availableBalance" />}

      {(() => {
        if (!isDepositEnabled) {
          return <ErrorAlert>{t('deposits.errors.blockedByFeatureFlag')}</ErrorAlert>;
        }

        if (isProtectionLoading || !isAccessible) {
          return (
            <Stack spacing="md">
              <Skeleton height={80} animate />
              <Skeleton height={100} animate />
              <Skeleton height={170} animate />
              <Skeleton height={340} animate />
            </Stack>
          );
        }

        return isNewDepositUIEnabled ? (
          <DepositForm
            contestIdForPostDeposit={contestIdForPostDeposit}
            setCompletedDepositAmount={handleSetCompletedDepositAmount}
            fallbackUrl={fallbackUrl}
          />
        ) : (
          <DepositFormLegacy
            contestIdForPostDeposit={contestIdForPostDeposit}
            setCompletedDepositAmount={handleSetCompletedDepositAmount}
            fallbackUrl={fallbackUrl}
          />
        );
      })()}
    </Stack>
  );
}

export { DepositView };
