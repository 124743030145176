/* eslint-disable prefer-rest-params */
/* eslint-disable no-restricted-globals */
import { logError } from '~/common/utils/log';

const initSegment = (anonymousId: string) => {
  if (!process.env.NEXT_PUBLIC_SEGMENT_KEY) {
    return;
  }

  const i = 'analytics';
  // eslint-disable-next-line no-multi-assign
  const analytics = (window[i] = window[i] || []);
  // global.analytics = analytics;

  if (analytics.initialize) {
    return;
  }

  if (analytics.invoked) {
    logError('Segment snippet included twice.');
    return;
  }

  analytics.invoked = !0;
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'screen',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
    'register',
  ];

  analytics.factory = function (e) {
    return function () {
      // eslint-disable-next-line prefer-spread
      if (window[i].initialized) return window[i][e].apply(window[i], arguments);
      const n = Array.prototype.slice.call(arguments);
      if (['track', 'screen', 'alias', 'group', 'page', 'identify'].indexOf(e) > -1) {
        const c = document.querySelector("link[rel='canonical']");
        n.push({
          __t: 'bpc',
          // eslint-disable-next-line no-void
          c: (c && c.getAttribute('href')) || void 0,
          p: location.pathname,
          u: location.href,
          s: location.search,
          t: document.title,
          r: document.referrer,
        });
      }
      n.unshift(e);
      analytics.push(n);
      return analytics;
    };
  };
  // eslint-disable-next-line no-plusplus
  for (let n = 0; n < analytics.methods.length; n++) {
    const key = analytics.methods[n];
    analytics[key] = analytics.factory(key);
  }
  analytics.load = function (key, n) {
    const t = document.createElement('script');
    t.type = 'text/javascript';
    t.async = !0;
    t.setAttribute('data-global-segment-analytics-key', i);
    t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
    const r = document.getElementsByTagName('script')[0];
    r.parentNode.insertBefore(t, r);
    analytics._loadOptions = n;
  };
  analytics.setAnonymousId(anonymousId);
  analytics._writeKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;
  analytics.SNIPPET_VERSION = '5.2.1';
  analytics.load(process.env.NEXT_PUBLIC_SEGMENT_KEY);

  if (process.env.NEXT_PUBLIC_SEGMENT_DEBUG === 'true') {
    analytics.debug(true);
  }
};

const isSegmentInitialised = () => !!global.analytics?.ready();

const stopSegment = () => {
  const analytics = global.analytics || [];
  global.analytics = analytics;

  if (isSegmentInitialised()) {
    analytics.user?.().traits?.({});
    analytics.reset();
  }
};

export { initSegment, isSegmentInitialised, stopSegment };
