import styled from '@emotion/styled';
import type { AnchorProps, MantineTheme } from '@mantine/core';
import { Anchor } from '@mantine/core';
import NextLink from 'next/link';
import { forwardRef } from 'react';

/** @deprecated use LinkNew instead */
export const Link = styled(NextLink)`
  color: ${({ theme }) =>
    theme.colorScheme === 'dark' ? theme.colors.splash[5] : theme.colors.splash[7]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const getLinkStyles = (theme: MantineTheme) => ({
  color: theme.colorScheme === 'dark'
    ? theme.colors.splash[5]
    : theme.colors.splash[7],
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
});


type LinkProps = AnchorProps & {
  href: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const LinkNew = forwardRef<HTMLAnchorElement, LinkProps>(({ href, sx, ...props }, ref) => (
  <Anchor
    href={href}
    component={NextLink}
    ref={ref}
    // @ts-expect-error Mantine types are wrong
    sx={(theme) => ({
      color: theme.colorScheme === 'dark' ? theme.colors.splash[5] : theme.colors.splash[7],
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      ...sx,
    })}
    {...props}
  />
));
LinkNew.displayName = 'Link';

export const ExternalLink = styled.a`
  color: ${({ theme }) =>
    theme.colorScheme === 'dark' ? theme.colors.splash[5] : theme.colors.splash[7]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
