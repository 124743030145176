import { createStyles } from '@mantine/core';
import React from 'react';

import { Title } from '~/domains/design-system/Title';

export type TagProps = {
  children: React.ReactNode;
  className?: string;
  marginBottom?: number | string;
  background?: string;
  titleColor?: string;
};

const useStyles = createStyles((theme, { background }: { background?: string }) => ({
  base: {
    display: 'flex',
    padding: `0 ${theme.other.spacing._8}px`,
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.other.spacing._4,
    borderRadius: '100px',
    background: background || theme.colors.blue[2],
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 900,
  },
}));

export function Tag({
  children,
  className,
  marginBottom,
  background,
  titleColor = 'gray2.6',
}: TagProps) {
  const { classes, cx } = useStyles({ background });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom }}>
      <div className={cx(classes.base, className)}>
        <Title variant="subhead-small" c={titleColor}>
          {children}
        </Title>
      </div>
    </div>
  );
}

Tag.displayName = 'Tag';
