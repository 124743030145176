/* eslint-disable no-template-curly-in-string */
export default {
  common: {
    fieldIsRequired: '{{field}} is required',
    fieldIsInvalid: '{{field}} is invalid',
    or: 'or',
    here: 'here',
  },
  signIn: {
    title: 'Log in',
    rypTitle: 'LOG IN TO YOUR ACCOUNT',
    emailOrUsername: {
      label: 'Email',
      placeholder: 'name@email.com',
      info: 'Please use your account email. For security purposes, you can no longer use your username to log in.',
    },
    password: {
      label: 'Password',
      placeholder: 'Your Password',
    },
    dontHaveAnAccount: "Don't have an account?",
    signUpCTA: "Don't have an account? <signUpLink>Sign up</signUpLink>",
    oauthButton: 'Log in via',
    errors: {
      title: 'Login error',
      messages: {
        wrongCredentials: 'Invalid email or password. Please check your credentials and try again.',
      },
    },
  },
  signUp: {
    title: 'Sign up',
    createNewAccount: 'Create new account',
    eoCallout: '{{eoBrandName}} is a Splash Sports product. Create a Splash account to continue.',
    handle: {
      label: 'Handle',
      placeholder: 'Handle',
      requirements: {
        notAllowedChars: 'Handle contains not allowed characters',
        startsEndsSpecialChars: 'Handle cannot start or end with special characters',
        hasConsecutiveSpecialChars: 'Handle cannot contain consecutive special characters',
        tooShort: 'Handle must not have less than 3 characters',
        tooLong: 'Handle must not have more than 20 characters',
        containsWhiteSpaces: 'Handle must not contain any whitespaces',
        isEmailAddress: 'Handle must not be an email address',
        atLeastOneLetter: 'Handle must contain at least one letter',
      },
    },
    phone: {
      label: 'Phone number',
      requirements: {
        errorSize: 'Phone number must be 10 digits',
        invalid: 'Invalid phone number',
      },
    },
    address: {
      label: 'Address',
    },
    city: {
      label: 'City',
      error: 'City cannot be empty',
    },
    state: {
      label: {
        US: 'State',
        CA: 'Province',
      },
      US: {
        error: 'Invalid state',
      },
      CA: {
        error: 'Invalid province',
      },
      error: 'Please choose a state',
    },
    zip: {
      label: {
        US: 'Zipcode',
        CA: 'Postal Code',
      },
      error: {
        US: 'Please enter a valid zip code',
        CA: 'Please enter a valid postal code',
      },
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
      error: 'Please choose a country',
    },
    name: {
      label: 'Name',
      placeholder: 'Your name',
    },
    firstName: {
      label: 'First Name',
      requirements: {
        required: 'First Name is required',
      },
    },
    lastName: {
      label: 'Last Name',
      requirements: {
        required: 'Last Name is required',
      },
    },
    dob: {
      month: {
        label: 'Month',
        requirements: {
          invalid: 'Invalid month',
        },
      },
      day: {
        label: 'Day',
        requirements: {
          invalid: 'Invalid day',
        },
      },
      year: {
        label: 'Year',
        requirements: {
          invalid: 'Invalid year',
        },
      },
    },
    email: {
      label: 'Email',
      placeholder: 'name@email.com',
      requirements: {
        restricted: 'We are unable to create an account with this email address.',
      },
    },
    password: {
      label: 'Password',
      placeholder: 'Your Password',
      requirements: {
        minLength: 'Includes at least 8 characters',
        number: 'Includes number',
        lowercase: 'Includes lowercase letter',
        uppercase: 'Includes uppercase letter',
        specialChar: 'Includes special symbol',
        match: 'Passwords must match',
      },
    },
    confirmPassword: {
      label: 'Confirm Password',
      placeholder: 'Confirm Password',
      error: 'Passwords must match',
    },
    referralCode: {
      label: 'Referral Code (optional)',
      placeholder: 'Referral Code (optional)',
      error: 'Invalid referral code',
      info: 'You and your referrer will receive rewards after you enter a $10+ contest and it starts.',
      success:
        'Code verification successful! You and your referrer will receive rewards after you enter a $10+ contest and it starts.',
    },
    referralBonus: {
      header: 'Sign up complete!',
      title: 'YOU UNLOCKED A BONUS!',
      copy: 'Redeem your new account bonus and start playing!',
      subTitle: 'NEW ACCOUNT BONUS',
      rewardAmount: '${{amount}} FREE',
      rewardAmountCopy:
        'Receive ${{amount}} from us when you deposit your first ${{amount}} or more.',
      rewardRedeem: 'Redeem',
      disclaimer:
        'Due to third party requirements, deposit via Venmo on the Splash Sports app requires that you set your default browser to Safari (only required for the first deposit via Venmo).',
    },
    confirmation: {
      title: 'Confirmation',
      copy: 'Does this info you provided match exactly what’s on your license or ID card? If not, your account may fail verification.',
      confirmButton: 'Yes, this info matches my license',
      goBackButton: 'Go back',
    },
    cta: 'Play Now',
    goToAccount: 'Go to my account',
    alreadyLoggedIn: "You're already signed in",
    alreadyHaveAnAccount: 'Already have an account?',
    signInCTA: 'Already have an account? <signInLink>Sign in</signInLink>',
    logInCTA: 'Already have an account? <signInLink>Log in</signInLink>',
    logIn: 'Log in',
    skip: 'Skip',
    legalBlurb:
      'Clicking "Play Now" confirms you are age 18+ (19+ in AL & 21+ MA, IA, LA, AZ) and agree to our <termsLink></termsLink> & <privacyLink></privacyLink>. You\'ll also get offers and messages from the SplashSports companies. Find out where Splash Sports is <whereCanIPlayLink></whereCanIPlayLink>.',
    legalBlurbV2:
      'Clicking "Continue" confirms you are age 18+ (19+ in AL & 21+ MA, IA, LA, AZ) and agree to our <termsLink></termsLink> & <privacyLink></privacyLink>. You\'ll also get offers and messages from the SplashSports companies. Find out where Splash Sports is <whereCanIPlayLink></whereCanIPlayLink>.',
    gamblingProblemBlurb:
      'If you or someone you know has a gambling problem and wants help, call <phoneLink></phoneLink> or chat <chatLink></chatLink>',
    terms: 'Terms',
    privacyPolicy: 'Privacy Policy',
    whereCanIPlay: 'currently available',
    infoCallout:
      'Make sure the information you provide matches exactly what’s on your driver’s license or ID card!',
    ssoCopy: 'Sign in via',
    headers: {
      email: {
        title: "WHAT'S YOUR EMAIL?",
        copy: "You'll use your email to log in.",
      },
      password: {
        title: 'SET YOUR PASSWORD',
        copy: 'Passwords must be at least 8 characters long and contain 1 uppercase, 1 lower case and 1 special character.',
      },
      handle: {
        title: 'CREATE A HANDLE',
        eoTitle: 'COMPLETE YOUR ACCOUNT',
        copy: 'This is how other players will find you on Splash. You can edit your handle by going to My Channel > Edit.',
        rypCopy: 'Enter your name and a unique handle to help other players find and identify you.',
        ofpCopy:
          'This is how other players will see you in the various standings pages of your contests. You can edit your default handle by going to your My Account page.',
      },
      name: {
        title: "WHAT'S YOUR NAME AND DOB?",
        copy: 'Enter your name and date of birth, as it appears on your license.',
      },
      address: {
        title: "WHAT'S YOUR ADDRESS & PHONE NUMBER?",
        copy: 'This information is required to verify your identity.',
      },
    },
    steps: {
      step1: 'Step 1',
      step2: 'Step 2',
      step3: 'Step 3',
      step4: 'Step 4',
      step5: 'Step 5',
      status: '{{current}} of {{total}}',
    },
  },
  eoValueProp: {
    title: 'Benefits are as easy as 1, 2, 3',
    linkYourExistingAccount: 'Sign up or link your existing RunYourPool Account',
    importedYourInfo: 'Transform the way you play.',
    verifyDetails: 'Make sure the following details are correct.',
    easilyImportInfo: 'Play against your friends for cash',
    inviteConnectionsToSplash: 'Earn money for creating the games everyone wants',
    getMoneyForYourFirstContest: 'We handle all entry fees and payouts for commissioners',
    blurb: {
      main: 'Sign up with RYP',
    },
  },
  resetPassword: {
    title: 'RESET PASSWORD',
    subtitle: 'We’ll send a link to your email address that you will use to reset your password.',
    heading: 'PASSWORD RESET',
  },
  forgotPassword: {
    cta: 'Forgot password?',
    heading: 'FORGOT PASSWORD',
    title: 'WHAT’S YOUR EMAIL?',
    subtitle:
      'Enter the email linked to your account and we’ll send a link to reset your password.',
    linkSentTitle: 'CHECK YOUR EMAIL',
    linkSentSubtitle:
      'We sent a password link to <text>{{email}}</text>. Open the link to reset your password.',
    linkSentNoEmailSubtitle:
      'We sent a password link to the email linked to your account. Open the link to reset your password.',
    fields: {
      emailOrUsername: 'Email',
    },
    errors: {
      minLength: 'This field cannot be empty',
    },
    errorTitle: 'Forgot Password Error',
    errorMessage: 'There was an error while requesting forgot password link',
    buttonLabel: 'Send me a password link',
    support:
      "If you forgot your email or can't access your account <link>send support a message</link>.",
  },
  confirmPassword: {
    title: 'CHOOSE A NEW PASSWORD',
    relayEmailSubtitle:
      'Going forward, Apple ID login will no longer be available. To continue, enter your email and choose a new password to link your account to your email address.',
    subtitle: 'Enter your new password and then click the button to change it.',
    yourNewPassword: 'Your new password',
    successTitle: 'Your password has been changed',
    successMessage: 'You can now log in with your new password',
    errorTitle: 'Confirm Password Error',
    errorMessage: 'There was an error while resetting your password.',
    buttonLabel: 'Reset Password',
    resetPasswordSuccess: {
      title: 'SUCCESS!',
      copy: 'Your password was reset. Log in to your account using your new password.',
    },
    invalidToken: {
      title: 'Invalid Password Link',
      copy: 'The password reset link is no longer valid.',
      buttonLabel: 'Resend Password Link',
    },
  },
  passwordManagement: {
    eoUpdatePassword: {
      heading: 'PASSWORD RESET',
      title: 'UPDATE YOUR PASSWORD',
      subtitle:
        'Your password is not meeting our minimum security requirements. Please update your password.',
      emailSentCopy: 'Your password needs to be reset in order to upgrade your account.',
      buttonCTA: 'Update my password',
    },
    ssoUpdatePassword: {
      heading: 'SWITCH TO EMAIL LOGIN',
      title: 'SET YOUR PASSWORD',
      subtitle:
        'Going forward, social login options will no longer be available. To continue, set up a password to link your account to your email address.',
      emailSentCopy:
        'To continue, you need to set a password that you will use to log in going forward.',
      buttonCTA: 'Set my password',
    },
  },
  errors: {
    password: {
      tooShort: 'Password should include at least 8 characters',
      needsUppercase: 'Password should have at least one uppercase letter',
      needsLowercase: 'Password should have at least one lowercase letter',
      needsNumber: 'Password should have at least one number',
      needsSpecialCharacter: 'Password should have at least one special character',
    },
    authError: {
      title: 'Your session has timed out',
      copy: 'If you continue to receive this message after logging in, your browser or corporate network may be blocking cookies.',
    },
    generic: {
      title: 'Failed to sign in',
      copy: 'Please try again.',
    },
    ssoAccountNotFound: {
      title: 'Account not found',
      copy: 'Please try again.',
    },
  },
  ssoLoginDisabled: {
    title: 'Going forward, social login options will no longer be available.',
    copy: 'To continue, you need to log in using your email and password.',
  },
  signUpPromo: {
    subtitle: 'Never miss out on the action!',
    copy: 'Download the Splash Sports mobile app and turn on notifications to get pick reminders, the most up-to-date gameplay experience, social features, and additional payment methods!',
  },
  signUpActions: {
    title: 'Download and sign up on mobile',
    fastestOption: 'Fastest',
    downloadIos: 'Download for iOS',
    downloadAndroid: 'Download for Android',
    signUpVia: 'Sign up via',
    signUpOnWeb: 'Sign up on web',
    continueOnWeb: 'Continue on web',
    continue: 'Continue',
  },
  restrictedDomain: {
    title: 'Restricted Domain',
    copy1: 'This email domain will only allow for self-managed play.',
    copy2: 'Real Money contests will not be available.',
  },
};
