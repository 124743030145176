import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { Button, ButtonLink } from '~/domains/design-system/Button';
import IconLocationOff from '~/domains/design-system/icons/IconLocationOff';
import { LocationProviderQueryKeys } from '~/components/providers/query';

import StatusModalBody from './components/StatusModalBody';

function LocationPermissionRequiredModal({
  isOpen,
  onClose,
  onRecheckPermission,
}: {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
  onRecheckPermission: () => void;
}) {
  const { t } = useTranslation('contest');
  const queryClient = useQueryClient();

  const handleRecheckPermission = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: LocationProviderQueryKeys.GEO_PERMISSION_STATUS,
    });
    onRecheckPermission();
  }, [queryClient, onRecheckPermission]);

  return (
    <StatusModalBody
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconLocationOff size={40} />}
      title={t('common.userStatusModals.locationPermissionRequired.title')}
      text={t('common.userStatusModals.locationPermissionRequired.text')}
      text2={
        <Trans
          i18nKey="contest:common.userStatusModals.common.explanation"
          components={{
            link: (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore children are filled by the Trans component
              <ButtonLink
                mode="inlineLink"
                href="https://knowledge.splashsports.com/blog/location-verification"
                target="_blank"
              />
            ),
          }}
        />
      }
      cta={
        <Button variant="secondary-fill" fullWidth onClick={handleRecheckPermission}>
          {t('common.userStatusModals.locationPermissionRequired.cta')}
        </Button>
      }
    />
  );
}

export default LocationPermissionRequiredModal;
