import type { CheckRequestError, CheckRequestStatus } from '@xpointtech/xpoint-js';
import type { FullContest } from '@betterpool/api-types';

import type { ModalType } from '~/domains/common/context/RootModalsContext/types';
import type { SocureKycStatus } from '~/services/users/types';

export enum ProtectionGuardType {
  USER,
  KYC,
  LOCATION,
  CONTEST,
}

type UserChecksProps = {
  isKYCloading: boolean;
  KYCStatus?: SocureKycStatus;
};

type LocationChecksProps = {
  geoPermissionStatus?: PermissionState;
  isLocationLoading: boolean;
  locationErrors?: CheckRequestError[];
  locationState?: string;
  locationStatus?: CheckRequestStatus;
  userAge: number;
};

type ContestChecksProps = {
  contestData?: FullContest | undefined;
  geoPermissionStatus?: PermissionState;
  isContestLoading: boolean;
  isLocationLoading: boolean;
  isLocationRestricted: boolean;
  locationCountry?: string;
  locationState?: string;
};

export type AccessChecksProps<T extends ProtectionGuardType> = {
  // Shared
  action: string;
  allowAnnonymous: boolean;
  redirectTo: string;
  userId: string;
  contestId: string;
  closeModalsByType: (modalType: string) => void;
  handleOnAccessFailed: (isSubmit: boolean) => void;
  openModalUnique: (modal: ModalType) => Promise<unknown>;
  forceRerender: () => void;
} & (T extends ProtectionGuardType.USER | ProtectionGuardType.KYC
  ? UserChecksProps
  : T extends ProtectionGuardType.LOCATION
    ? LocationChecksProps
    : T extends ProtectionGuardType.CONTEST
      ? ContestChecksProps
      : never);
