import { Grid } from '@mantine/core';
import type { CSSProperties, ReactNode } from 'react';
import React from 'react';

import { Text } from '~/domains/common/components/Text/Text';

import useStyles from '../ContestCard.styles';
import type { ContestCardActionBanner } from '../types';

type ContestCardFooterMetaProps = {
  meta: {
    value: string;
    label: string | ReactNode;
    valueColor?: string;
    span?: number;
    textAlign?: string;
  }[];
  actionBanner: ContestCardActionBanner;
};

function ContestCardFooterMeta({ meta, actionBanner }: ContestCardFooterMetaProps) {
  const { classes } = useStyles({
    actionBannerColor: typeof actionBanner === 'object' ? actionBanner.color : undefined,
  });

  return (
    <>
      <Grid columns={12}>
        {meta.filter(Boolean).map(({ value, label, valueColor, span = 4, textAlign = 'left' }) => (
          <Grid.Col span={span} key={`${label}-${value}`}>
            <Text
              tag="div"
              ta={textAlign as CSSProperties['textAlign']}
              className={classes.infoItemValue}
              c={valueColor}
            >
              {value}
            </Text>
            <Text
              tag="div"
              ta={textAlign as CSSProperties['textAlign']}
              variant="web-body-small"
              c="gray.6"
            >
              {label}
            </Text>
          </Grid.Col>
        ))}
      </Grid>
      {actionBanner ? (
        <Text tag="div" className={classes.actionBanner}>
          {typeof actionBanner === 'string' ? actionBanner : actionBanner.content}
        </Text>
      ) : null}
    </>
  );
}

export default ContestCardFooterMeta;
