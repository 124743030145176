export const LocationProviderQueryKeys = {
  XPOINT_INIT: ['location/xPoint/init'],
  LOCATION_TOKEN: (userId: string | undefined, geoPermissionStatus: PermissionState) => [
    'location/xPoint/liteCheck',
    userId,
    geoPermissionStatus,
  ],
  GEO_PERMISSION_STATUS: ['location/geoLocationPermissionState'],
};

export const UserProviderQueryKeys = {
  USER_DETAILS: (userId?: string) => ['userDetails', userId],
  USER_SSN_STATUS: (userId?: string) => ['userSsnStatus', userId],
};
