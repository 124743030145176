export default {
  modals: {
    selfManageInfoModal: {
      title: 'Are you sure you want to self manage?',
      subtitle: 'Splash supports:',
      list: {
        1: {
          title: 'Hassle-free entry fee collection',
          description: 'Your members deposit and pay securely on Splash.',
        },
        2: {
          title: 'Automated prize payouts',
          description: 'Payouts are automatically distributed when the contest ends.',
        },
      },
      recommendation: {
        text: "“I didn’t have to worry about collecting entry fees… paying people out… tracking people down to make sure that their picks were in – all that commissioner headache stuff really went away when I started using Splash. So it's been a huge time-saver for me… I could not recommend Splash Sports more highly.”",
        author: 'RickRunGood',
        authorDescription: 'Splash Sports Commissioner, $500k Contest',
      },
      cta: 'Got it!',
    },
    collectEntryFeesInfoModal: {
      title: 'Let Splash collect your entry fees',
      list: {
        1: {
          title: 'Effortless contest management',
          description: 'Easily invite your members to join the action.',
        },
        2: {
          title: 'Seamless entry fee collection',
          description:
            'No more chasing payments—players deposit and pay directly through our secure platform.',
        },
        3: {
          title: 'Automated prize payouts',
          description:
            'Customize your payouts, and let Splash handle the rest. Winnings are automatically distributed when the contest ends.',
        },
      },
      footer: {
        1: 'All users will need to pass Identity verification and be in an eligible state to enter a contest. Find out where Splash Sports is currently available via the info page below.',
        2: 'To participate in real money games on Splash you must be age 18+ (19+ in AL & 21+ MA, AZ) and agree to our Terms & Privacy Policy.',
        3: 'Have additional questions? View our <a href="https://splashsports.com/ncaa-survivor-commissioners">March Madness info page</a>',
      },
      cta: 'Got it!',
    },
    rewardsProgramModal: {
      title: 'Splash commissioner rewards program',
      list: {
        1: {
          title: 'Earn more with Splash',
          description:
            'Take home {{commissionerFeePercentage}}% of the total paid prizes. The more players and higher the entry fee, the bigger your rewards.',
        },
        2: {
          title: 'Low platform fees',
          description:
            'Splash charges just {{bpFeePercentage}}% for payment processing and admin costs, with a total of {{totalFeePercentage}}% deducted from the total paid prizes.',
        },
        3: {
          title: 'Grow your earnings',
          description:
            'Run more contests across all major sports and contest types to grow your earnings.',
        },
      },
      footer:
        'Have additional questions? Our support team is happy to help! Reach out to <a href="mailto:vip@splashsports.com">vip@splashsports.com</a>',
      cta: 'Got it!',
    },
    introModal: {
      wrapperTitle: 'WELCOME TO',
      player_tier: {
        title: '{{templateName}} contests on Splash',
        text: 'You can host your {{templateName}} contest on Splash Sports for free. Your contest settings will be saved.',
        features: {
          entertainmentOnly: {
            _1: 'Free to play! No hosting fees for {{templateName}}',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
          paid: {
            _1: 'Hassle-free entry fee collection',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
        },
        bulletPointsTitle: 'Please note some key considerations:',
        bulletPoints: {
          RYP: {
            _1: {
              title: 'Commissioner Console: ',
              description:
                'You can send invites, mange entrants, and download email lists. Custom messages to your members need to be sent manually through your personal email.',
            },
            _2: {
              title: 'Member Pick Maintenance: ',
              description: 'You can not edit or make picks on behalf of members in your contest.',
            },
            _3: {
              title: 'Contest Settings: ',
              description:
                'Splash does not support tiebreakers based on total score or salary based games (yet).',
            },
          },
          OFP: {
            _1: {
              title: 'Pool Admin: ',
              description:
                'You can send invites, mange entrants, and download email lists. Custom messages to your members need to be sent manually through your personal email.',
            },
            _2: {
              title: 'Pick Management: ',
              description:
                'Splash does not support backup picks for golfers removed from the player pool or automatic picks for missed picks.',
            },
            _3: {
              title: 'Contest Settings: ',
              description:
                'Commissioners will not be able to customize each player tier or the season long tournament schedule.',
            },
          },
        },
        cta: 'Got it!',
        back: 'Go back',
      },
      player_one_and_done: {
        title: '{{templateName}} contests on Splash',
        text: 'You can host your {{templateName}} contest on Splash Sports for free. Your contest settings will be saved.',
        features: {
          entertainmentOnly: {
            _1: 'Free to play! No hosting fees for {{templateName}}',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
          paid: {
            _1: 'Hassle-free entry fee collection',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
        },
        bulletPointsTitle: 'Please note some key considerations:',
        bulletPoints: {
          RYP: {
            _1: {
              title: 'Commissioner Console: ',
              description:
                'You can send invites, mange entrants, and download email lists. Custom messages to your members need to be sent manually through your personal email.',
            },
            _2: {
              title: 'Member Pick Maintenance: ',
              description: 'You can not edit or make picks on behalf of members in your contest.',
            },
            _3: {
              title: 'Contest Settings: ',
              description:
                'Splash does not support tiebreakers based on total score or salary based games (yet).',
            },
          },
          OFP: {
            _1: {
              title: 'Pool Admin: ',
              description:
                'You can send invites, mange entrants, and download email lists. Custom messages to your members need to be sent manually through your personal email.',
            },
            _2: {
              title: 'Pick Management: ',
              description:
                'Splash does not support backup picks for golfers removed from the player pool or automatic picks for missed picks.',
            },
            _3: {
              title: 'Contest Settings: ',
              description:
                'Commissioners will not be able to customize each player tier or the season long tournament schedule.',
            },
          },
        },
        cta: 'Got it!',
        back: 'Go back',
      },
      team_survivor: {
        title: '{{templateName}} has been upgraded',
        text: "All {{templateName}} contests are now hosted on Splash Sports. Don't worry, your contest settings have been saved.",
        features: {
          entertainmentOnly: {
            _1: 'Free to play! No hosting fees for {{templateName}}',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
          paid: {
            _1: 'Hassle-free entry fee collection',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
        },
        cta: 'Continue',
      },
      team_pick_em: {
        title: '{{templateName}} has been upgraded',
        text: "All {{templateName}} contests are now hosted on Splash Sports. Don't worry, your contest settings have been saved.",
        features: {
          entertainmentOnly: {
            _1: 'Free to play! No hosting fees for {{templateName}}',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
          paid: {
            _1: 'Hassle-free entry fee collection',
            _2: 'Fast & automated live scoring',
            _3: 'Updated mobile app to follow your contests',
          },
        },
        cta: 'Continue',
      },
    },
  },
};
