import { init, isInitialized, FullStory } from '@fullstory/browser';

import log from '~/common/utils/log';
import isWebView from '~/utils/isWebView';

// ==================== PRIVATE METHODS ====================
const fullStoryActionBuffer: (() => unknown)[] = [];

let isFullStoryInitialising = false;

// Function wrapper using buffer to ensure that the function is called after the FS SDK is initialised
function runFullStoryAction(func: () => unknown) {
  return isInitialized() ? func() : fullStoryActionBuffer.push(func) && undefined;
}

// ==================== PUBLIC METHODS ====================
function initFullStory() {
  isFullStoryInitialising = true;

  return new Promise<boolean>((resolve) => {
    // Don't initialise if ORG ID is missing, we're already initialised or we're in a webview
    if (!process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || isInitialized() || isWebView()) {
      isFullStoryInitialising = false;
      resolve(false);
    }

    init({
      orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
      namespace: 'FS',
      devMode: process.env.NEXT_PUBLIC_ENV !== 'production',
      // Always start capture manually to ensure we can start it only on demand
      startCaptureManually: true,
    });

    // Triggered on FS capture start
    FullStory('observe', {
      type: 'start',
      callback: () => {
        log('FullStory recording initialised: ', FullStory('getSession'));
      },
    });

    // Triggered on FS SDK initialisation, unloading the buffer
    // Note: Only triggered when `devMode` is false
    FullStory('observe', {
      type: 'internal/fs-init',
      callback: () => {
        log('FullStory fs-init');
        isFullStoryInitialising = false;
        resolve(true);

        fullStoryActionBuffer.forEach((func) => func());
      },
    });
  });
}

async function startFullStoryCapture(captureReason = '') {
  // Add the action to the buffer
  runFullStoryAction(() => {
    log(`FullStory ${captureReason} request detected`);
    FullStory('start');
  });

  // Initialise the FullStory SDK first time when we want the recording to start
  if (!isFullStoryInitialising && !isInitialized()) await initFullStory();
}

function stopFullStoryCapture() {
  runFullStoryAction(() => FullStory('shutdown'));
}

type FullStoryUser = {
  displayName: string;
  email: string;
  id: string;
};
function setFullStoryUser(user: FullStoryUser) {
  runFullStoryAction(() => {
    log('FullStory - Set user', user);
    FullStory('setProperties', {
      type: 'user',
      properties: user,
    });
  });
}

function trackFullStoryEvent(name: string, eventProperties: Record<string, unknown>) {
  runFullStoryAction(() => FullStory('trackEvent', { name, properties: eventProperties }));
}

function trackFullStoryPageView(pageProperties: Record<string, unknown>) {
  runFullStoryAction(() =>
    FullStory('setProperties', { type: 'page', properties: pageProperties })
  );
}

function getFullStorySessionUrl(): string | undefined {
  return isInitialized() ? FullStory('getSession', { format: 'url.now' }) : undefined;
}

export {
  startFullStoryCapture,
  stopFullStoryCapture,
  setFullStoryUser,
  trackFullStoryEvent,
  trackFullStoryPageView,
  getFullStorySessionUrl,
};
