import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

import { Routes } from '~/domains/common/constants/routes';
import { ButtonLink } from '~/domains/design-system/Button';
import IconShieldCheck from '~/domains/design-system/icons/IconShieldCheck';

import StatusModalBody from './components/StatusModalBody';

function UserNotVerifiedModal({
  redirectTo,
  isOpen,
  onClose,
  desiredAction,
}: {
  redirectTo?: string;
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
  desiredAction: string;
}) {
  const { t } = useTranslation('contest');

  return (
    <StatusModalBody
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconShieldCheck />}
      title={t('common.userStatusModals.userNotVerifiedModal.title')}
      text={
        <Trans
          i18nKey="contest:common.userStatusModals.userNotVerifiedModal.text"
          values={{
            desiredAction,
          }}
        />
      }
      cta={
        <ButtonLink
          variant="primary-fill"
          fullWidth
          href={Routes.userVerification({ redirectTo })}
          onClick={() => {
            onClose(true);
          }}
        >
          {t('common.userStatusModals.userNotVerifiedModal.verifyNow')}
        </ButtonLink>
      }
    />
  );
}

export default UserNotVerifiedModal;
