import queryString from 'query-string';

import type { PaginatedData } from '~/domains/services/interfaces/paginated-data.interface';
import type { GetEntriesFilter } from '~/domains/entries/types/get-entries-filter.type';
import { createQueryString } from '~/domains/common/utils/queryString';

import type { APIUserEntry, UserEntry } from './interfaces/user-entry.interface';
import type { ContestEntry } from './interfaces/entry.interface';
import type { GetMyEntriesPayload } from './interfaces/get-my-entries-payload.interface';
import { contestsServiceInstance } from './contest.service.instance';
import type { EntryWithPicks } from './interfaces/entry-with-picks.interface';
import type { PickForEntry } from './interfaces/picks-for-entry.interface';
import type { PlayerPoolMember } from './interfaces/player-pool-member.interface';
import parseEntriesForContest from './parsers/parseEntriesForContest';
import type { BulkPicksResponse } from './interfaces/bulk-picks';

const MAX_ENTRIES = 150;

const getEntries = async (payload: GetMyEntriesPayload) => {
  const response = await contestsServiceInstance.post<PaginatedData<ContestEntry>>(
    `/contests/entries`,
    payload
  );
  return response.data;
};

const getUserEntriesForContest = async (
  contestId: string,
  userId: string,
  limit = MAX_ENTRIES,
  offset = 0
): Promise<PaginatedData<UserEntry>> => {
  try {
    const query = queryString.stringify({ limit, offset });
    const response = await contestsServiceInstance.get<PaginatedData<APIUserEntry>>(
      `contests/${contestId}/users/${userId}/entries?${query}`
    );

    return parseEntriesForContest(response.data);
  } catch (error) {
    if (error.response.status === 404) {
      return {
        data: [],
        limit: 0,
        offset: 0,
        total: 0,
      };
    }
    throw error;
  }
};

const getPicksForEntries = async (
  contestId: string,
  slateId: string
): Promise<EntryWithPicks[]> => {
  try {
    const response = await contestsServiceInstance.get<EntryWithPicks[]>(
      `/contests/${contestId}/slates/${slateId}/entries`
    );
    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      return [];
    }
    throw error;
  }
};

const getPicksForEntry = async (
  contestId: string,
  slateId: string,
  entryId: string
): Promise<{ dropWorstCount?: number; picks: PickForEntry[] }> => {
  try {
    const response = await contestsServiceInstance.get<{
      dropWorstCount?: number;
      picks: PickForEntry[];
    }>(`/contests/${contestId}/slates/${slateId}/entries/${entryId}/picks`);
    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      return { picks: [] };
    }
    throw error;
  }
};

const getBulkPicksForEntries = async (payload: {
  entryIds: string[];
  slateIds: string[];
}): Promise<BulkPicksResponse> => {
  try {
    const response = await contestsServiceInstance.post<BulkPicksResponse>('/picks', payload);

    return response.data;
  } catch (error) {
    if ([404, 400].includes(error.response.status)) {
      return { entries: {} };
    }
    throw error;
  }
};

const getBulkContestEntries = async (
  payload: GetEntriesFilter
): Promise<PaginatedData<ContestEntry>> => {
  const response = await contestsServiceInstance.post<PaginatedData<ContestEntry>>(
    `/contests/entries`,
    { limit: 100, offset: 0, ...payload }
  );

  return response.data;
};

const updateEntry = async (entryId: string, payload: { name: string }) => {
  const response = await contestsServiceInstance.put(`/entries/${entryId}`, payload);

  return response.data;
};

interface CreateEntryPayload {
  contestId: string;
  contestPassword?: string;
  entriesCount?: number;
}

const createEntry = async (
  payload: CreateEntryPayload,
  { skipLocationValidation }: { skipLocationValidation?: boolean } = {}
) => {
  const response = await contestsServiceInstance.post<string[]>(`/v2/entries`, payload, {
    skipLocationValidation,
  });
  return response.data;
};

const cancelEntry = async (entryId: string) => {
  const response = await contestsServiceInstance.delete(`/entries/${entryId}`);
  return response.data;
};

const getPlayerPoolsByTierId = async ({
  contestId,
  slateId,
  tierId,
  offset = 0,
  limit = 20,
}: {
  contestId: string;
  slateId: string;
  tierId: number;
  offset?: number;
  limit?: number;
}): Promise<PaginatedData<PlayerPoolMember>> => {
  const response = await contestsServiceInstance.get<PaginatedData<PlayerPoolMember>>(
    `contests/${contestId}/slates/${slateId}/player-pool${createQueryString({ tierId, offset, limit })}`
  );
  return response.data;
};

export {
  cancelEntry,
  createEntry,
  getBulkContestEntries,
  getBulkPicksForEntries,
  getEntries,
  getPicksForEntries,
  getPicksForEntry,
  getPlayerPoolsByTierId,
  getUserEntriesForContest,
  updateEntry,
};
