import { Fragment, useContext } from 'react';
import { match } from 'ts-pattern';

import DepositModal from '~/domains/payments/deposits/components/DepositModal';
import ConnectedEnterContestModal from '~/domains/contest/domains/common/components/EnterContestModal/ConnectedEnterContestModal';
import EnterContestSuccessScreenModal from '~/domains/contest/domains/common/components/EnterContestModal/EnterContestSuccessScreenModal/EnterContestSuccessScreenModal';
import DialogContainer from '~/domains/design-system/components/Dialog/DialogContainer';
import {
  AgeRestrictionModal,
  InvalidLocationModal,
  LocationErrorModal,
  LocationPermissionRequiredModal,
  LocationRestrictionModal,
  UserNotVerifiedModal,
  VerifyingDocumentsModal,
} from '~/domains/protectionGuards/Modals';

import useAnimatedDialogModals from '../useAnimatedDialogModals';

import { RootModalsContext } from './RootModalsContext';

function RootModals() {
  const { modals, closeModal, isCloseDisabled } = useContext(RootModalsContext);
  const { modalsCache, activeModal, closingModal } = useAnimatedDialogModals({
    modals,
  });

  return (
    <DialogContainer
      isOpen={!!modals.length}
      onClose={isCloseDisabled ? undefined : closeModal}
      lockScroll
    >
      {modalsCache.map((modal) => {
        const commonProps = {
          onClose: closeModal,
          isCloseDisabled,
          isOpen: activeModal === modal,
          isClosing: closingModal === modal,
        };

        return (
          <Fragment key={modal.type}>
            {match(modal)
              .returnType<React.ReactNode>()
              .with({ type: 'EnterContestModal' }, (rest) => (
                <ConnectedEnterContestModal {...rest} {...commonProps} />
              ))
              .with({ type: 'EnterContestModalSuccess' }, (rest) => (
                <EnterContestSuccessScreenModal {...rest} {...commonProps} />
              ))
              .with({ type: 'DepositModal' }, (rest) => <DepositModal {...rest} {...commonProps} />)
              // Protection Guards Modals
              .with({ type: 'AgeRestrictionModal' }, (rest) => (
                <AgeRestrictionModal {...rest} {...commonProps} />
              ))
              .with({ type: 'InvalidLocationModal' }, (rest) => (
                <InvalidLocationModal {...rest} {...commonProps} />
              ))
              .with({ type: 'LocationErrorModal' }, (rest) => (
                <LocationErrorModal {...rest} {...commonProps} />
              ))
              .with({ type: 'LocationPermissionRequiredModal' }, (rest) => (
                <LocationPermissionRequiredModal {...rest} {...commonProps} />
              ))
              .with({ type: 'LocationRestrictionModal' }, (rest) => (
                <LocationRestrictionModal {...rest} {...commonProps} />
              ))
              .with({ type: 'UserNotVerifiedModal' }, (rest) => (
                <UserNotVerifiedModal {...rest} {...commonProps} />
              ))
              .with({ type: 'VerifyingDocumentsModal' }, (rest) => (
                <VerifyingDocumentsModal {...rest} {...commonProps} />
              ))
              .otherwise(() => null)}
          </Fragment>
        );
      })}
    </DialogContainer>
  );
}

export default RootModals;
