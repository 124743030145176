'use client';

import Script from 'next/script';
import { NotificationsProvider } from '@mantine/notifications';
import { QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouter } from 'next/router';

import FeatureFlagProvider from '~/components/providers/FeatureFlagProvider';
import KYCProvider from '~/components/providers/KYCProvider';
import LocationProvider from '~/components/providers/LocationProvider';
import UserProvider from '~/components/providers/UserProvider';
import TranslationsProvider from '~/components/providers/TranslationsProvider';
import queryClient from '~/config/initReactQuery';
import { AnalyticsWrapper } from '~/domains/analytics/AnalyticsWrapper';
import { SignUpModalContextProvider } from '~/domains/common/context/SignUpModalContext';
import { MaintenanceWrapper } from '~/domains/app/components/MaintenanceWrapper/MaintenanceWrapper';
import { TimeoutWrapper } from '~/domains/app/components/TimeoutWrapper/TimeoutWrapper';
import RootModalsContextProviderDeprecated from '~/domains/common/context/RootModalsContextDeprecated/RootModalsContextProvider';
import RootModalsContextProvider from '~/domains/common/context/RootModalsContext/RootModalsContextProvider';
import { GamecenterPrevPathContextProvider } from '~/domains/contest/domains/gamecenter/domains/common/context/GamecenterPrevPathContext/GamecenterPrevPathContext';
import { QuickPickSlipDetailPrevPathContextProvider } from '~/domains/contest/domains/quickPick/context/QuickPickSlipDetailPrevPathContext/QuickPickSlipDetailPrevPathContext';
import isWebView from '~/utils/isWebView';

import { PrevLocationContextProvider } from '../context/PrevLocationContext';

import SingleUseTokenProvider from './SingleUseTokenProvider';

const ROUTES_WITHOUT_COOKIE_BANNER = ['/account/deposit/vipp', '/account/withdrawal/vipp'];

export function AppProviders({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const showCookieBanner = !ROUTES_WITHOUT_COOKIE_BANNER.includes(router.route) && !isWebView();

  return (
    <QueryClientProvider client={queryClient}>
      {/* Osano = Cookie consent */}
      {showCookieBanner && (
        <Script src="https://cmp.osano.com/16BVIfT9cJhAu25Hp/66d19d54-6055-460a-adf1-0016fe26af65/osano.js" />
      )}
      <TranslationsProvider>
        <NotificationsProvider>
          <PrevLocationContextProvider>
            <GamecenterPrevPathContextProvider>
              <QuickPickSlipDetailPrevPathContextProvider>
                <SignUpModalContextProvider>
                  <UserProvider>
                    <SingleUseTokenProvider>
                      <FeatureFlagProvider>
                        <MaintenanceWrapper>
                          <AnalyticsWrapper>
                            <TimeoutWrapper>
                              {/* XPoint = Location SaaS */}
                              <LocationProvider>
                                {/* Socure = KYC SaaS */}
                                <Script
                                  src="https://sdk.dv.socure.io/latest/device-risk-sdk.js"
                                  data-public-key={process.env.NEXT_PUBLIC_SOCURE_SDK_KEY}
                                />
                                <Script src="https://websdk.socure.com/bundle.js" />
                                <KYCProvider>
                                  {/* Paysafe = Payments SaaS */}
                                  <Script src="https://hosted.paysafe.com/js/v1/latest/paysafe.min.js" />
                                  <Script src="https://hosted.paysafe.com/checkout/v2/paysafe.checkout.min.js" />

                                  <RootModalsContextProviderDeprecated>
                                    <RootModalsContextProvider>
                                      {children}
                                    </RootModalsContextProvider>
                                  </RootModalsContextProviderDeprecated>
                                </KYCProvider>
                              </LocationProvider>
                            </TimeoutWrapper>
                          </AnalyticsWrapper>
                        </MaintenanceWrapper>
                      </FeatureFlagProvider>
                    </SingleUseTokenProvider>
                  </UserProvider>
                </SignUpModalContextProvider>
              </QuickPickSlipDetailPrevPathContextProvider>
            </GamecenterPrevPathContextProvider>
          </PrevLocationContextProvider>
        </NotificationsProvider>
      </TranslationsProvider>
      {/*  **ONLY FOR DEVELOPMENT** Docs says it won't build in prod */}
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}
