import type { Theme } from '@emotion/react';

import type { TMantineColors } from './types';

const isDarkMode = (theme: Theme) => theme.colorScheme === 'dark';

const getPrimaryThemeColor = (theme: Theme) =>
  theme.colors[theme.primaryColor][theme.primaryShade[theme.colorScheme]];

const setBlackOrWhiteText = (theme: Theme) => (isDarkMode(theme) ? theme.black : theme.white);

const isLightColor = (colorValue) => {
  let r;
  let g;
  let b;

  let color = colorValue;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    /* eslint-disable no-bitwise */
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
    /* eslint-enable no-bitwise */
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  // hsp < 127.5 === light color
  // hsp >= 127.5 === dark color
  return hsp > 127.5;
};

const generateColors = (colors: string[]) => {
  const getColorForIndex = (index) => {
    if (index >= 0 && index <= 2) return colors[0];
    if (index >= 3 && index <= 6) return colors[1];
    return colors[2];
  };

  return Array.from({ length: 10 }, (_, index) => getColorForIndex(index)) as TMantineColors;
};

function importantRule(styles) {
  return Object.entries(styles).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: `${value} !important` }),
    {}
  );
}

export {
  generateColors,
  getPrimaryThemeColor,
  importantRule,
  isDarkMode,
  isLightColor,
  setBlackOrWhiteText,
};
