import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import type { AxiosError } from 'axios';

import { getAnonymousId, reportEvent } from '~/domains/analytics';
import { UserContext } from '~/components/providers/UserProvider';
import { isLoadingFixedForDisabledQuery } from '~/domains/common/utils/isLoadingFixedForEnabled';
import { getExchangeSingleUseToken } from '~/domains/services/nextApi.service';
import { sutSchema } from '~/domains/auth/schemas/unified-login.schema';
import type { APIAuthUserResponse } from '~/services/users/types';
import isWebViewFn from '~/utils/isWebView';

import validateTokenOnSchema from '../utils/validateTokenOnSchema';

type RedirectString = string;

const useUnifiedLogin = () => {
  const { user, setUser } = useContext(UserContext);
  const router = useRouter();
  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const anonymousId = getAnonymousId();
  const userId = user?.id;
  const isWebView = isWebViewFn();
  const sut = searchParams.get('sut');

  const sutValidation = validateTokenOnSchema(sut, sutSchema);
  const [isLoading, setIsLoading] = useState(sut ? sutValidation.success : false);

  function reportLoginEvent(eventName: string, additionalProps = {}) {
    reportEvent(eventName, {
      user_id: sutValidation.success ? sutValidation.data.userId : anonymousId,
      is_sut: true,
      ...additionalProps,
    });
  }

  function handleLoginSuccess(data: { url: RedirectString; user: APIAuthUserResponse['data'] }) {
    setUser(data.user);
    reportLoginEvent('User > Login');
  }

  async function handleLoginError(error: AxiosError<{ url: RedirectString }>) {
    reportLoginEvent('User > Login Error', { reason: error.message });
    router.push(error.response?.data?.url);
  }

  const handleSutValidation = useCallback(() => {
    if (!sut) return;
    if ((sutValidation.success && sutValidation.data.userId === userId) || !sutValidation.success) {
      const current = new URLSearchParams(Array.from(searchParams.entries()));

      current.delete('sut');

      const search = current.toString();
      const query = search ? `?${search}` : '';

      router.replace(`${window.location.pathname}${query}`, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, sut, userId]);

  const {
    data,
    isLoading: isLoadingQuery,
    fetchStatus,
    isError,
  } = useQuery({
    queryKey: ['single-use-token-login', sut],
    queryFn: () => getExchangeSingleUseToken({ sut, webView: isWebView }),
    cacheTime: 0,
    staleTime: 0,
    enabled: sutValidation.success && sutValidation.data.userId !== userId,
    onSuccess: handleLoginSuccess,
    onError: handleLoginError,
  });

  const updateLoadingState = useCallback(() => {
    if (!sutValidation.success || !sut) return;
    if (data?.user.id === userId || sutValidation.data.userId === userId || isError) {
      setIsLoading(false);
    }
  }, [data?.user?.id, isError, sut, sutValidation, userId]);

  useEffect(() => {
    handleSutValidation();
  }, [handleSutValidation]);

  useEffect(() => {
    updateLoadingState();
  }, [updateLoadingState]);

  return {
    isLoading: isLoadingFixedForDisabledQuery(isLoadingQuery, fetchStatus) || isLoading,
  };
};

export default useUnifiedLogin;
