import axios from 'axios';

import RequestHeadersBase from '~/common/constants/RequestHeadersBase';

import { handleRefreshInterceptor } from '../interceptors/handle-refresh.interceptor';
import { setAuthTokenInterceptor } from '../interceptors/set-auth-token.interceptor';
import { Method, setLocationInterceptor } from '../interceptors/set-location.interceptor';
import { verifyUserSessionInterceptor } from '../interceptors/verify-user-session.interceptor';

export const walletServiceInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_PAYMENTS_SERVICE}`,
  headers: {
    ...RequestHeadersBase,
  },
});

setAuthTokenInterceptor(walletServiceInstance);
verifyUserSessionInterceptor(walletServiceInstance);
handleRefreshInterceptor(walletServiceInstance);
setLocationInterceptor(walletServiceInstance, [
  [Method.GET, '/settings/available-payment-methods/deposit'],
  [Method.POST, '/wallets/aeropay/confirm-user'],
  [Method.POST, '/wallets/cards'],
  [Method.POST, '/wallets/deposits'],
  [Method.POST, '/wallets/payment-handles/card'],
  [Method.POST, '/wallets/payment-handles/paypal'],
  [Method.POST, '/wallets/payment-handles/vip-preferred'],
]);
