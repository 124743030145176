import type { PropsWithChildren } from 'react';
import React from 'react';

import useAnalytics from './index';

// This is a wrapper initialising analytics on first load
// Wrapper pattern had to be used because we need FeatureFlag provider for FullStory control
export function AnalyticsWrapper({ children }: PropsWithChildren) {
  useAnalytics();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
