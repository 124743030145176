import React from 'react';
import { createStyles } from '@mantine/core';

import { SplashLoader } from '../SplashLoader/SplashLoader';

const useStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, .8)',
    maxHeight: '100vh',
  },
}));

export function SplashLoaderOverlay() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SplashLoader />
    </div>
  );
}
