import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { IconLocationBroken } from '@tabler/icons-react';

import { Button, ButtonLink } from '~/domains/design-system/Button';

import StatusModalBody from './components/StatusModalBody';

function LocationRestrictionModal({
  isOpen,
  onClose,
  detectedLocation,
  desiredAction,
  queryKey,
}: {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
  detectedLocation: string;
  desiredAction: string;
  queryKey: string[];
}) {
  const { t } = useTranslation('contest');

  const isFetching = useIsFetching({ queryKey });
  const queryClient = useQueryClient();

  const handleRecheckLocationClick = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return (
    <StatusModalBody
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconLocationBroken size={40} stroke={1.5} />}
      title={t('common.userStatusModals.locationRestriction.title')}
      text={
        <Trans
          i18nKey="contest:common.userStatusModals.locationRestriction.text"
          values={{
            detectedLocation,
            desiredAction,
          }}
          components={{
            link: (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore children are filled by the Trans component
              <ButtonLink
                mode="inlineLink"
                href="https://splashsports.com/where-can-you-play"
                target="_blank"
              />
            ),
          }}
        />
      }
      text2={
        <Trans
          i18nKey="contest:common.userStatusModals.locationRestriction.note"
          components={{ bold: <strong /> }}
        />
      }
      cta={
        <Button
          variant="secondary-fill"
          fullWidth
          onClick={handleRecheckLocationClick}
          loading={isFetching > 0}
        >
          {t('common.userStatusModals.common.recheckLocationCta')}
        </Button>
      }
    />
  );
}

export default LocationRestrictionModal;
