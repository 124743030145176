import Bugsnag from '@bugsnag/js';

const logForce = (...args: unknown[]) => {
  // eslint-disable-next-line no-console
  console.log(...args);
};

const logError = (...args: unknown[]) => {
  // eslint-disable-next-line no-console
  console.error(...args);

  if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED) {
    // @ts-expect-error Bugsnag is not typed
    Bugsnag.notify(...args);
  }
};

const log = (...args: unknown[]) => {
  if (process.env.NEXT_PUBLIC_DEBUG !== 'true') return;

  logForce(...args);
};

export default log;

export { logError };
