import { useQuery } from '@tanstack/react-query';
import type { CheckRequestError, CheckRequestStatus } from '@xpointtech/xpoint-js';
import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { updateAnalyticsUser } from '~/domains/analytics';
import useCurrentLocation from '~/domains/contest/domains/services/hooks/useCurrentLocation';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { useLocation } from '~/hooks/useLocation';
import { SocureKycStatus } from '~/services/users/types';
import { PermissionStatusState, getGeoPermissions } from '~/utils/geoPermissions';
import { isEoBrand } from '~/utils/isEoBrand';
import XPointSingleton from '~/utils/xpoint';
import isWebView from '~/utils/isWebView';

import { LocationProviderQueryKeys } from './query';
import { UserContext } from './UserProvider';

type LocationCountryName = 'united states' | 'canada';
const AllowedLocations: LocationCountryName[] = ['united states', 'canada'];
const DEFAULT_LOCATION_COUNTRY_NAME: LocationCountryName = 'united states';

export const LocationContext = createContext<{
  locationCountry?: string;
  locationCountryNameSafe?: LocationCountryName;
  locationErrors?: CheckRequestError[];
  locationState?: string;
  locationStatus?: CheckRequestStatus;
  geoPermissionStatus?: PermissionState;
  isLoading?: boolean;
  isXpointEnabled: boolean;
  setLocationCountryNameOverride: (country: LocationCountryName) => void;
  refetchGeoPermissionStatus: () => void;
  refetchLocationToken: () => void;
}>({
  locationCountry: undefined,
  locationErrors: undefined,
  locationState: undefined,
  locationStatus: undefined,
  geoPermissionStatus: undefined,
  refetchGeoPermissionStatus: () => {},
  refetchLocationToken: () => {},
  setLocationCountryNameOverride: () => {},
  isXpointEnabled: false,
});

function LocationProvider({ children }: { children?: ReactNode }) {
  const { user, userDetails } = useContext(UserContext);
  const [geoPermissionStatus, setGeoPermissionStatus] = useState<PermissionState>();
  const isLocationCheckOnLoginEnabled = useIsFeatureEnabled(FEATURE.ENABLE_LOCATION_CHECK_ON_LOGIN);
  const [locationCountryNameOverride, setLocationCountryNameOverride] =
    useState<LocationCountryName>();

  // Step 1. We only enable xPoint location checks for users who are:
  // - not using Unify Auth (EO brands)
  // - not using a webview
  // - are KYC verified
  const isEOorWebView = isWebView() || isEoBrand();
  const isXpointEnabled = useMemo(
    // @TODO: Use KYCStatus from KYCProvider
    () => !!user && userDetails?.verificationStatus === SocureKycStatus.ACCEPT && !isEOorWebView,
    [user, userDetails?.verificationStatus, isEOorWebView]
  );

  // Step 2: Init location status closures
  const { locationStatus, locationState, locationCountry, locationErrors } = useLocation();

  // Step 3: Log in into the Web Platform
  const { isFetched: isXPointReady } = useQuery({
    queryKey: LocationProviderQueryKeys.XPOINT_INIT,
    queryFn: () => XPointSingleton.init(user?.id),
    staleTime: Infinity,
    cacheTime: 0,
    enabled: isXpointEnabled,
  });

  // Step 4: Ensure any token unless disabled in SplitIO
  // The business logic is "fetch location token after login"
  // If user just logged in, there is no locationToken in localStorage so this will fetch a new one
  // If user just refreshed a browser, the previous token is saved in localStorage
  // so the fetch won't trigger
  // Added 6/20: If the users geoPermissionStatus updates, we will refetch location token
  const {
    refetch: refetchLocationToken,
    isInitialLoading: isLocationTokenLoading,
    isFetching: isLocationFetching,
  } = useQuery({
    queryKey: LocationProviderQueryKeys.LOCATION_TOKEN(user?.id, geoPermissionStatus),
    queryFn: async () => {
      // `-Infinity` means we prefer an expired token to a new one
      await XPointSingleton.getValidLocationToken(
        -Infinity,
        geoPermissionStatus === PermissionStatusState.GRANTED
      );

      return null;
    },
    staleTime: Infinity,
    cacheTime: 0,
    enabled: isXPointReady && isLocationCheckOnLoginEnabled,
  });

  // Step 5: Use navigator permissions obtain geolocation permission
  // Do not fire location prompt if we're in a webview
  const { refetch: refetchGeoPermissionStatus, isInitialLoading: isGeoPermissionLoading } =
    useQuery({
      queryKey: LocationProviderQueryKeys.GEO_PERMISSION_STATUS,
      queryFn: async () =>
        getGeoPermissions(setGeoPermissionStatus as (status: PermissionStatusState) => void),
      enabled: !!navigator?.permissions && isXpointEnabled,
    });

  // Anonymous user location detection via contest-service using GeoIP headers
  const { data: anonUserCurrentLocation, isInitialLoading: anonUserCurrentLocationIsLoading } =
    useCurrentLocation({
      enabled: !isXpointEnabled,
    });

  // @ts-expect-error TODO Fix later
  const locationCountryName: LocationCountryName = useMemo(
    () =>
      isXpointEnabled
        ? locationCountry?.toLowerCase()
        : anonUserCurrentLocation?.country.toLowerCase(),
    [anonUserCurrentLocation?.country, locationCountry, isXpointEnabled]
  );

  // Step 6: Report the location status, location and country changes to Segment
  useEffect(() => {
    if (user) {
      updateAnalyticsUser({
        id: user.id,
        geo_status: locationStatus || 'NO GEO STATUS SET',
        geo_detected_state: locationState,
        geo_detected_country: locationCountry,
      });
    }
  }, [user, locationStatus, locationState, locationCountry]);

  // Step 7: Return memoised location props to the provider
  // `locationCountryNameSafe` is used for displaying filtered content based on user's location
  // If the location is not in the allowed list, we default to 'united states'
  // and show e.g. homepage content for as if the user is in the US
  const locationCountryNameSafe: LocationCountryName = useMemo(() => {
    if (locationCountryNameOverride) {
      return locationCountryNameOverride;
    }

    if (!AllowedLocations.includes(locationCountryName)) {
      return DEFAULT_LOCATION_COUNTRY_NAME;
    }

    return locationCountryName;
  }, [locationCountryName, locationCountryNameOverride]);

  const value = useMemo(
    () => ({
      locationErrors,
      locationStatus,
      locationState,
      locationCountry: locationCountryNameOverride ?? locationCountryName,
      locationCountryNameSafe,
      isLoading: isXpointEnabled
        ? isGeoPermissionLoading || isLocationTokenLoading || isLocationFetching
        : anonUserCurrentLocationIsLoading,
      isXpointEnabled,
      setLocationCountryNameOverride,
      geoPermissionStatus,
      refetchGeoPermissionStatus,
      refetchLocationToken,
    }),
    [
      locationErrors,
      locationStatus,
      locationState,
      locationCountryNameOverride,
      locationCountryName,
      locationCountryNameSafe,
      isXpointEnabled,
      isGeoPermissionLoading,
      isLocationTokenLoading,
      isLocationFetching,
      anonUserCurrentLocationIsLoading,
      geoPermissionStatus,
      refetchGeoPermissionStatus,
      refetchLocationToken,
    ]
  );

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>;
}

export default LocationProvider;
