function IconShieldCheck({ color = 'currentColor', size = 40 }: { color?: string; size?: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 20L18.3334 23.3333L25 16.6667M20 5C23.8931 8.44429 28.9742 10.2376 34.1667 10C34.9227 12.5717 35.154 15.2691 34.8469 17.932C34.5398 20.5948 33.7006 23.1688 32.3791 25.5009C31.0575 27.833 29.2807 29.8756 27.1541 31.5075C25.0276 33.1393 22.5947 34.327 20 35C17.4054 34.327 14.9725 33.1393 12.846 31.5075C10.7194 29.8756 8.94257 27.833 7.62104 25.5009C6.29952 23.1688 5.46027 20.5948 5.15317 17.932C4.84608 15.2691 5.0774 12.5717 5.83338 10C11.0259 10.2376 16.107 8.44429 20 5Z" stroke={color} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

IconShieldCheck.displayName = 'IconShieldCheck';

export default IconShieldCheck;
