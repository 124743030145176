import styled from '@emotion/styled';
import type { StackProps } from '@mantine/core';
import {
  Center,
  Group,
  Footer as MantineFooter,
  MantineProvider,
  Text as MantineText,
  Space,
  Stack,
  useMantineTheme,
} from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { type HTMLAttributeAnchorTarget, type ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Routes } from '~/domains/common/constants/routes';
import { useIsCanada } from '~/domains/common/hooks/useIsCanada';
import isWebView from '~/utils/isWebView';

import { LogoTypes, SplashLogo } from '../Logos/SplashLogo';

type LinkStackLink =
  | {
      component: ReactNode;
      text?: never;
      href?: never;
      target?: never;
    }
  | {
      component?: never;
      text: string;
      href: string;
      target?: HTMLAttributeAnchorTarget;
    };

interface LinkStackProps extends StackProps {
  title: string;
  links: LinkStackLink[];
}

const Text = styled(Link)`
  color: ${({ theme }) => theme.white};
  text-decoration: none;
  font-weight: 400;
`;

function LinkStack({ title, links, ...rest }: LinkStackProps) {
  return (
    <Stack spacing={4} {...rest}>
      <MantineText color="white" size={12} mt={16} mb={8} fw={800} transform="uppercase">
        {title}
      </MantineText>
      {links.map((link) =>
        link.component ? (
          link.component
        ) : (
          <Text key={link.text} href={link.href} color="white" target={link.target}>
            {link.text}
          </Text>
        )
      )}
    </Stack>
  );
}

interface ResponsibleGamingLinksProps {
  isStackedFooter: boolean;
}

function ResponsibleGamingLinks({ isStackedFooter }: ResponsibleGamingLinksProps) {
  const { t } = useTranslation('common');
  const links: LinkStackLink[] = [
    { text: t('footer.responsibleGaming.commitment'), href: '/account?open=RGInfo' },
    {
      text: t('footer.responsibleGaming.limits'),
      href: '/account?open=RGLimits',
    },
    {
      text: t('footer.responsibleGaming.policy'),
      href: 'https://legal.splashsports.com/legal/responsible-gaming',
      target: '_blank',
    },
    {
      text: t('footer.responsibleGaming.selfExclusion'),
      href: '/account?open=selfExclusion',
    },
    { component: <Space h={32} key="spacer-1" /> },
    {
      text: t('footer.responsibleGaming.gamblingProblemBlurb'),
      href: t('footer.responsibleGaming.gamblingProblemTelephoneNumber'),
    },
  ];
  return (
    <LinkStack
      title={t('footer.responsibleGaming.header')}
      links={links}
      maw={isStackedFooter ? '60%' : '30%'}
    />
  );
}

function MyAccountLinks() {
  const { t } = useTranslation('common');
  const links: LinkStackLink[] = [
    { text: t('footer.myAccount.deposit'), href: '/account/wallet/deposit' },
    {
      text: t('footer.myAccount.withdrawal'),
      href: '/account/wallet/withdraw',
    },
    {
      text: t('footer.myAccount.transactionHistory'),
      href: '/account?open=transactionHistory',
    },
    {
      text: t('footer.myAccount.accountSettings'),
      href: '/account',
    },
  ];
  return <LinkStack title="My Account" links={links} />;
}

function CompanyLinks() {
  const isCanada = useIsCanada();
  const { t } = useTranslation('common');
  const links: LinkStackLink[] = [
    {
      text: t('footer.company.terms'),
      href: Routes.termsOfUse(isCanada),
      target: '_blank',
    },
    {
      text: t('footer.company.privacyPolicy'),
      href: Routes.privacyPolicy(isCanada),
      target: '_blank',
    },
    {
      text: t('footer.company.cookiePolicy'),
      href: 'https://legal.splashsports.com/legal/cookie-notice',
      target: '_blank',
    },
    {
      text: t('footer.company.faqs'),
      href: 'https://legal.splashsports.com/',
    },
    {
      text: t('footer.company.contactUs'),
      href: 'mailto:support@splashsports.com?subject=Splash Support&body=Hello, Splash Support',
    },
  ];
  return <LinkStack title="Company" links={links} />;
}

function WhatsNewLinks() {
  const { t } = useTranslation('common');
  const links: LinkStackLink[] = [
    {
      text: t('footer.whatsNew.brandGuidelines'),
      href: 'https://branding-guidelines.splashsports.com/',
      target: '_blank',
    },
    {
      text: t('footer.whatsNew.commissionerRewards'),
      href: 'https://www.splashsports.com/rewards',
      target: '_blank',
    },
    {
      text: t('footer.whatsNew.whereCanIPlay'),
      href: 'https://legal.splashsports.com/map/splash-fantasy-contest-availability',
      target: '_blank',
    },
    {
      text: t('footer.whatsNew.gameRules'),
      href: 'https://legal.splashsports.com/legal/splash-sports-house-rules',
      target: '_blank',
    },
    // TODO: Comment back in when rewards are rolled out
    // {
    //   text: t('commissionerCalculator'),
    //   href: 'https://splashsports.com/commissioner-calculator',
    //   target: '_blank',
    // },
  ];
  return <LinkStack title={t('footer.whatsNew.header')} links={links} />;
}

function AppLinks() {
  const { t } = useTranslation('common');
  const links: LinkStackLink[] = [
    {
      text: t('footer.splashApps.iosApp'),
      href: 'https://apps.apple.com/us/app/splash-sports/id6451190245',
      target: '_blank',
    },
    {
      text: t('footer.splashApps.androidApp'),
      href: 'https://play.google.com/store/apps/details?id=com.splash.splashsports',
      target: '_blank',
    },
    {
      text: t('footer.splashApps.androidCanada'),
      href: 'https://splashsports.com/canada/android',
      target: '_blank',
    },
  ];
  return <LinkStack title={t('footer.splashApps.header')} links={links} />;
}

type FooterProps = {
  isRestricted?: boolean;
};

export function Footer({ isRestricted }: FooterProps) {
  const isStackedFooter = useMediaQuery({ maxWidth: 768 });
  const theme = useMantineTheme();

  if (isWebView()) {
    return false;
  }

  return (
    <MantineProvider inherit theme={{ colorScheme: 'dark', globalStyles: undefined }}>
      <MantineFooter height="auto" maw="100%" style={{ overflow: 'hidden' }}>
        <Center w="100%" py="md">
          <Group
            w="100%"
            maw={theme.other.layout.maxWidth}
            mx="auto"
            px={{
              base: theme.other.layout.paddingSm,
              md: theme.other.layout.paddingMd,
              lg: theme.other.layout.paddingLg,
            }}
            position="apart"
            align="start"
          >
            <Group w="100%">
              <SplashLogo type={LogoTypes.WHITE_NO_TEXT} size={30} />
            </Group>
            <ResponsibleGamingLinks isStackedFooter={isStackedFooter} />
            {isStackedFooter && (
              <Stack spacing={4}>
                <AppLinks />
                <WhatsNewLinks />
                {!isRestricted && <MyAccountLinks />}
                <CompanyLinks />
              </Stack>
            )}
            {!isStackedFooter && (
              <>
                <AppLinks />
                <WhatsNewLinks />
                {!isRestricted && <MyAccountLinks />}
                <CompanyLinks />
              </>
            )}
          </Group>
        </Center>

        <Group
          w="100%"
          bg="dark.9"
          py={16}
          position={isStackedFooter ? 'center' : 'left'}
          align="center"
        >
          <MantineText
            color="white"
            w={theme.other.layout.maxWidth}
            mx="auto"
            px={{
              base: theme.other.layout.paddingSm,
              md: theme.other.layout.paddingMd,
              lg: theme.other.layout.paddingLg,
            }}
          >
            {new Date().getFullYear()} Splash All Rights Reserved
          </MantineText>
        </Group>
      </MantineFooter>
    </MantineProvider>
  );
}
