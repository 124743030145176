import React from 'react';

import isWebView from '~/utils/isWebView';

import { MaintenanceBanner } from '../MaintenanceBanner/MaintenanceBanner';
import ConnectedNewAppVersionBanner from '../NewAppVersionBanner/ConnectedNewAppVersionBanner';
import VerificationBanner from '../VerificationBanner/VerificationBanner';
import { useIsMobile } from '../../hooks/mediaQueries';

function AppBanners() {
  const isMobile = useIsMobile();

  if (isWebView()) {
    return false;
  }

  return (
    <>
      <VerificationBanner minimal={isMobile} />
      <MaintenanceBanner />
      <ConnectedNewAppVersionBanner />
    </>
  );
}

export default AppBanners;
