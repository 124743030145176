import type {
  FullContest as APIFullContest,
  FullContestBase as APIFullContestBase,
  FullContestPlayerCategory as APIFullContestPlayerCategory,
  FullContestPlayerTiers as APIFullContestPlayerTiers,
  FullContestPlayerOneAndDone as APIFullContestPlayerOneAndDone,
  FullContestTeamPickEm as APIFullContestTeamPickEm,
  FullContestTeamSurvivor as APIFullContestTeamSurvivor,
} from '@betterpool/api-types/contests-service';
import { ContestType } from '@betterpool/api-types/contests-service';

import pickPropertiesFromObject from '~/domains/common/utils/pickPropertiesFromObject';
import type { ContestSlate } from '~/domains/games/interfaces/contest.slates.interface';

import type {
  FullContest,
  FullContestBase,
  FullContestPlayerCategory,
  FullContestPlayerOneAndDone,
  FullContestPlayerTiers,
  FullContestTeamPickEm,
  FullContestTeamSurvivor,
} from '../interfaces/full-contest.interface';

import parseContest from './parseContest';

function parseFullContestBase(response: APIFullContestBase): FullContestBase {
  return {
    ...pickPropertiesFromObject(response, [
      'schedule_id',
      'contest_template_name',
      'rules',
      'short_rules',
      'long_rules',
      'rich_short_rules',
      'roster_requirements',
      'payout_schedule',
      'is_private',
      'description',
      'public_viewing',
      // @ts-expect-error: this will be added to api-types in the future
      'manage_entrants',
    ]),
    ...parseContest(response),
    slates: response.slates.map(
      (slate) =>
        ({
          ...slate,
          start_date: new Date(slate.start_date),
          end_date: new Date(slate.end_date),
          late_swap_deadline: slate.late_swap_deadline
            ? new Date(slate.late_swap_deadline)
            : undefined,
          generatesRosterAt: slate.generatesRosterAt
            ? new Date(slate.generatesRosterAt)
            : undefined,
        } as ContestSlate)
    ),
  };
}

function parseFullContestPlayerCategory(
  response: APIFullContestPlayerCategory
): FullContestPlayerCategory {
  return {
    ...parseFullContestBase(response),
    contest_type: response.contest_type,
    roster_category_rules: response.roster_category_rules,
    roster_category_rules_settings: response.roster_category_rules_settings,
  } as FullContestPlayerCategory;
}

function parseFullContestPlayerTiers(response: APIFullContestPlayerTiers): FullContestPlayerTiers {
  const parsedFullContestBase = parseFullContestBase(response);

  return {
    ...parsedFullContestBase,
    contest_type: response.contest_type,
    tier_rules_settings: response.tier_rules_settings,
    tier_rules: response.tier_rules,
    settings: {
      ...parsedFullContestBase.settings,
      expectedPicksCount: response.settings.expectedPicksCount ??
        (response.tier_rules_settings?.numberOfTiers ?? 0) * (response.tier_rules_settings?.numberPerTier ?? 0),
    }
  } as FullContestPlayerTiers;
}

function parseFullContestPlayerOneAndDone(
  response: APIFullContestPlayerOneAndDone
): FullContestPlayerOneAndDone {
  const parsedFullContestBase = parseFullContestBase(response);

  return {
    ...parsedFullContestBase,
    contest_type: response.contest_type,
    roster_category_rules: response.roster_category_rules,
    roster_category_rules_settings: response.roster_category_rules_settings,
    tier_rules_settings: response.tier_rules_settings,
    tier_rules: response.tier_rules,
    settings: {
      ...parsedFullContestBase.settings,
      expectedPicksCount: response.settings.expectedPicksCount ??
        (response.tier_rules_settings?.numberOfTiers ?? 0) * (response.tier_rules_settings?.numberPerTier ?? 0),
    }
  } as FullContestPlayerOneAndDone;
}

function parseFullContestTeamSurvivor(
  response: APIFullContestTeamSurvivor
): FullContestTeamSurvivor {
  return {
    ...parseFullContestBase(response),
    contest_type: response.contest_type,
    final_slate_id: response.final_slate_id,
  } as FullContestTeamSurvivor;
}

function parseFullContestTeamPickEm(response: APIFullContestTeamPickEm): FullContestTeamPickEm {
  return {
    ...parseFullContestBase(response),
    contest_type: response.contest_type,
  } as FullContestTeamPickEm;
}

function parseFullContest(response: APIFullContest): FullContest {
  switch (response.contest_type) {
    case ContestType.PLAYER_CATEGORY:
      return parseFullContestPlayerCategory(response);
    case ContestType.PLAYER_TIER:
      return parseFullContestPlayerTiers(response);
    case ContestType.PLAYER_ONE_AND_DONE:
      return parseFullContestPlayerOneAndDone(response);
    case ContestType.TEAM_SURVIVOR:
      return parseFullContestTeamSurvivor(response);
    case ContestType.TEAM_PICK_EM:
      return parseFullContestTeamPickEm(response);
    default:
      // @ts-expect-error contest_type is never and I can't override it :/
      throw new Error(`Unknown contest type: ${response.contest_type}`);
  }
}

export default parseFullContest;
