import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import IntercomLoggedIn from '~/components/Intercom/IntercomLoggedIn';
import { createQueryString } from '~/domains/common/utils/queryString';

import { UserContext } from './UserProvider';

interface AuthGuardProps {
  children: React.ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, initializing } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (!initializing) {
      if (!user) {
        // If they are signing out we use this so we don't show login modal
        const originalParams = new URLSearchParams(window.location.search);
        if (window.sessionStorage.getItem('sign_out')) {
          window.sessionStorage.removeItem('sign_out');

          const newParams = originalParams.has('brand')
            ? { brand: originalParams.get('brand') }
            : {};

          const queryString = createQueryString(newParams);

          router.push(`/sign-in${queryString}`);
        } else {
          const query =
            window.location.pathname === '/logout'
              ? Object.fromEntries(originalParams)
              : { redirectTo: `${window.location.pathname}${window.location.search}` };
          router.push({
            pathname: '/sign-in',
            query,
          });
        }
      }
    }
  }, [initializing, router, user]);

  if (initializing) {
    return null;
    // add initalizing loader?
  }

  // if auth initialized with a valid user show protected page
  if (user) {
    return (
      <>
        <IntercomLoggedIn />
        {children}
      </>
    );
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null;
}
