import { kycServiceInstance } from './kyc.service.instance';
import { type IGetBlockedDomainResponse, type IGetKYCVerificationResponse } from './kyc.interface';

export const postKycVerify = async (payload: any) => {
  const response = await kycServiceInstance.post<IGetKYCVerificationResponse>(
    '/verification',
    payload
  );
  return response.data;
};

export const getKycVerify = async () => {
  const response = await kycServiceInstance.get<IGetKYCVerificationResponse>('/verification');
  return response.data;
};

export const getBlockedDomain = async (email: string) => {
  const response = await kycServiceInstance.get<IGetBlockedDomainResponse>(
    `/blocked-domain/${email}`
  );
  return response.data;
};
