import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { AUTH_PATHS, BRANDS, EO_BRANDS } from '~/domains/auth/consts';
import type { PLATFORMS } from '~/domains/auth/consts';

export const useGetBranding = (): {
  isLoading: boolean;
  brand?: BRANDS;
  platform?: PLATFORMS;
  isEOBrand?: boolean; // Make this optional to reflect an "unknown" state
  destination?: keyof typeof EO_BRANDS;
} => {
  const { query } = useRouter();
  const path = usePathname();

  const brand = typeof query.brand === 'string' ? (query.brand.toUpperCase() as BRANDS) : undefined;
  const platform =
    typeof query.platform === 'string' ? (query.platform.toUpperCase() as PLATFORMS) : undefined;
  const isAuthPath = AUTH_PATHS.includes(path);
  const isEOBrand = !!brand && isAuthPath && brand in EO_BRANDS;
  const destination = isEOBrand ? EO_BRANDS[brand] : undefined;

  if (!isAuthPath || !brand) {
    return { isLoading: false, brand: BRANDS.SPLASH, platform, isEOBrand, destination };
  }

  switch (brand) {
    case 'RYP':
      return { isLoading: false, brand: BRANDS.RYP, platform, isEOBrand, destination };
    case 'OFP':
      return { isLoading: false, brand: BRANDS.OFP, platform, isEOBrand, destination };
    default:
      return { isLoading: false, brand: BRANDS.SPLASH, platform, isEOBrand, destination };
  }
};
