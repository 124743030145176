import { BRANDS, AUTH_PATHS } from '~/domains/auth/consts';

export function isEoBrand() {
  const queryParams = new URLSearchParams(window.location.search);
  const brand = queryParams.get('brand')?.toUpperCase();

  return brand === BRANDS.RYP || brand === BRANDS.OFP;
}

export function isEoBrandAuth() {
  const path = window.location.pathname;
  const isAuthPath = AUTH_PATHS.includes(path);

  return isAuthPath && isEoBrand();
}
