export const TUTORIAL_PLACEMENTS = {
  TUTORIAL_ONE_AND_DONE_GAMECENTER: 'TUTORIAL_ONE_AND_DONE_GAMECENTER',
  TUTORIAL_PICKEM_CONFIDENCE_GAMECENTER: 'TUTORIAL_PICKEM_CONFIDENCE_GAMECENTER',
  TUTORIAL_PICKEM_GAMECENTER: 'TUTORIAL_PICKEM_GAMECENTER',
  TUTORIAL_QUICK_PICKS_BOARD: 'TUTORIAL_QUICK_PICKS_BOARD',
  TUTORIAL_QUICK_PICKS_GAMECENTER: 'TUTORIAL_QUICK_PICKS_GAMECENTER',
  TUTORIAL_SURVIVOR_GAMECENTER: 'TUTORIAL_SURVIVOR_GAMECENTER',
  TUTORIAL_TIERS_GAMECENTER: 'TUTORIAL_TIERS_GAMECENTER',
} as const;

export const TUTORIAL_PLACEMENTS_TO_SHOW_ON_LOAD = [
  TUTORIAL_PLACEMENTS.TUTORIAL_ONE_AND_DONE_GAMECENTER,
  TUTORIAL_PLACEMENTS.TUTORIAL_PICKEM_CONFIDENCE_GAMECENTER,
  TUTORIAL_PLACEMENTS.TUTORIAL_PICKEM_GAMECENTER,
  TUTORIAL_PLACEMENTS.TUTORIAL_QUICK_PICKS_BOARD,
  TUTORIAL_PLACEMENTS.TUTORIAL_SURVIVOR_GAMECENTER,
  TUTORIAL_PLACEMENTS.TUTORIAL_TIERS_GAMECENTER,
];
