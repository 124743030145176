import { useEffect, useState } from 'react';

import XPointSingleton from '~/utils/xpoint';

function getLocation() {
  const tokenData = XPointSingleton.getLocationTokenData();

  return {
    locationState: tokenData?.state,
    locationStatus: tokenData?.status,
    locationCountry: tokenData?.country,
    locationErrors: tokenData?.errors,
  };
}

function useLocation() {
  const [location, setLocation] = useState<ReturnType<typeof getLocation>>(getLocation());

  useEffect(() => {
    const unsubscribe = XPointSingleton.subscribeToLocationToken(() => {
      setLocation(getLocation());
    });
    return () => unsubscribe();
  }, []);

  return location;
}

export { useLocation };
