// TODO: Remove this once we can auth
import { useRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { logError } from '~/common/utils/log';
import { useKYCStatus } from '~/hooks/useKYCStatus';
import type { SocureKycStatus } from '~/services/users/types';

import { UserContext } from './UserProvider';

export const KYCContext = createContext<{
  KYCStatus: SocureKycStatus | null;
  setKYCStatus: Dispatch<SetStateAction<SocureKycStatus | null>>;
  KYCDeviceToken: string | null;
  isKYCloading: boolean;
  isKYCfetching: boolean;
}>({
  KYCStatus: null,
  setKYCStatus: null,
  KYCDeviceToken: null,
  isKYCloading: false,
  isKYCfetching: false,
});

interface KYCProviderProps {
  children: React.ReactNode;
}

function KYCProvider({ children }: KYCProviderProps) {
  const [KYCStatus, setKYCStatus] = useState<SocureKycStatus | null>(null);
  const [KYCDeviceToken, setKYCDeviceToken] = useState(null);
  const { userId } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (document.readyState !== 'loading' && window.SigmaDeviceManager && !KYCDeviceToken) {
      window.SigmaDeviceManager.getSessionToken().then((sessionToken) => {
        setKYCDeviceToken(sessionToken);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [KYCDeviceToken, router, window.SigmaDeviceManager]);

  const { data: kycStatus, isInitialLoading, isFetching, error } = useKYCStatus(userId);

  useEffect(() => {
    if (kycStatus) {
      setKYCStatus(kycStatus);
    }

    if (error) {
      logError(error);
    }
  }, [kycStatus, error]);

  const value = useMemo(
    () => ({
      KYCStatus,
      setKYCStatus,
      KYCDeviceToken,
      isKYCloading: isInitialLoading,
      isKYCfetching: isFetching,
    }),
    [KYCDeviceToken, KYCStatus, isInitialLoading, isFetching]
  );

  return <KYCContext.Provider value={value}>{children}</KYCContext.Provider>;
}

export default KYCProvider;
