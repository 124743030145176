import { useCallback, useContext } from 'react';
import type { EntriesDetails, ContestStatus } from '@betterpool/api-types/contests-service';
import { useRouter } from 'next/router';

import { UserContext } from '~/components/providers/UserProvider';
import { useSignUpModalDispatchContext } from '~/domains/common/context/SignUpModalContext';
import { getCurrentLocationForRef } from '~/domains/common/utils/getCurrentLocationForRef';
import { useIsAnonymousPicksAllowedForContest } from '~/domains/contest/hooks/useIsAnonymousPicksAllowedForContest';
import { RootModalsContext } from '~/domains/common/context/RootModalsContext/RootModalsContext';
import useProtectedGuard from '~/domains/protectionGuards/hooks/useProtectedGuard';
import { ProtectionGuardType } from '~/domains/protectionGuards/types';

import useGamecenterPicksPath from '../../gamecenter/domains/common/hooks/useGamecenterPicksPath';

import useEnterContestValidation from './useEnterContestValidation';

type UseContestEntryButtonProps = {
  contestId: string;
  isAfterDeadline: boolean;
  isEntertainmentOnly?: boolean;
  entries?: EntriesDetails;
  isAllowedInLocation?: boolean;
  isFirstSlatePicksheetReady?: boolean;
  status: ContestStatus;
};

const useEnterContestButton = ({
  contestId,
  isAfterDeadline,
  isEntertainmentOnly,
  entries,
  isAllowedInLocation,
  isFirstSlatePicksheetReady = true,
  status,
}: UseContestEntryButtonProps) => {
  const router = useRouter();
  const dispatch = useSignUpModalDispatchContext();
  const { openModal } = useContext(RootModalsContext);

  // ENTRY VALIDATION (deadlines, max entries, etc)
  const {
    error,
    errorMessage,
    isLoading: isContestValidationLoading,
    isDisabled,
  } = useEnterContestValidation({
    isAfterDeadline,
    isEntertainmentOnly,
    entries,
    isAllowedInLocation,
    status,
  });

  // PROTECTION GUARD (KYC, location status, etc)
  const { isLoading: isProtectionLoading, runProtectionChecks } = useProtectedGuard({
    action: 'create an entry',
    protectionLevels: [
      ProtectionGuardType.KYC,
      ProtectionGuardType.LOCATION,
      ProtectionGuardType.CONTEST,
    ],
    // Anonymous picks support
    allowAnnonymous: true,
    contestId,
  });

  const { user } = useContext(UserContext);
  const { fullContest, isAnonymousPicksEnabled, isAnonymousPicksAllowedForContest } =
    useIsAnonymousPicksAllowedForContest(contestId);

  const picksPath = useGamecenterPicksPath({
    id: contestId,
    contestType: fullContest?.data?.contest_type,
    sport: fullContest?.data?.league.sport,
    ref: getCurrentLocationForRef(),
  });

  // Final onClick handler (opens a new entry modal)
  const handleClick = useCallback(
    async (onSuccess?: (entryIDs?: string[]) => Promise<void> | void) => {
      if (!user) {
        if (!isFirstSlatePicksheetReady) {
          dispatch({ type: 'openSignUpModal', payload: { contestId } });
          return;
        }
        if (isAnonymousPicksEnabled && isAnonymousPicksAllowedForContest) {
          await router.push(picksPath);
          return;
        }

        dispatch({ type: 'openSignUpModal', payload: { contestId } });

        return;
      }

      // Entertainment only contests are accessible without protection guard
      const isAccessible = isEntertainmentOnly || (await runProtectionChecks());

      if (!isDisabled && isAccessible) {
        openModal({
          type: 'EnterContestModal',
          contestId,
          onSuccess,
        });
      }
    },
    [
      contestId,
      dispatch,
      isEntertainmentOnly,
      isAnonymousPicksAllowedForContest,
      isAnonymousPicksEnabled,
      isDisabled,
      isFirstSlatePicksheetReady,
      openModal,
      runProtectionChecks,
      picksPath,
      router,
      user,
    ]
  );

  return {
    error,
    errorMessage,
    isDisabled,
    isLoading: isContestValidationLoading || isProtectionLoading,
    onClick: handleClick,
  };
};

export default useEnterContestButton;

export type { UseContestEntryButtonProps };
