import React, { useState, useCallback, useRef, useMemo } from 'react';

type ContextValue<MT> = {
  isCloseDisabled: boolean;
  modals: MT[];
  closeModal: (args?: unknown) => void;
  closeModalsByType: (type: string) => void;
  disableClose: () => void;
  enableClose: () => void;
  openModal: (modal: MT) => Promise<unknown>;
  openModalUnique: (modal: MT) => Promise<unknown>;
};

type ProviderProps = {
  children: React.ReactNode;
};

function createModalsContext<MT extends { type: string }>() {
  const ModalsContext = React.createContext<ContextValue<MT>>({
    isCloseDisabled: false,
    modals: [],
    closeModal: () => {},
    closeModalsByType: () => {},
    disableClose: () => {},
    enableClose: () => {},
    openModal: () => Promise.resolve(),
    openModalUnique: () => Promise.resolve(),
  });

  function ModalsContextProvider({ children }: ProviderProps) {
    const [modals, setModals] = useState<MT[]>([]);
    const [isCloseDisabled, setIsCloseDisabled] = useState(false);
    const resolvers = useRef<((value: unknown) => void)[]>([]);
    const promises = useRef<Promise<unknown>[]>([]);

    const handleCloseModal = useCallback(
      (args: unknown) => {
        setModals((v) => {
          const newModals = v.slice(0, -1);

          if (newModals.length === 0) {
            setIsCloseDisabled(false);
          }

          return newModals;
        });
        try {
          const resolve = resolvers.current.pop();
          promises.current.pop();
          resolve(args);
          // eslint-disable-next-line no-empty
        } catch (e) {
          console.error('Error while closing modal', e);
        }
      },
      [setModals]
    );

    const handleCloseModalsByType = useCallback(
      (type: string) => {
        // Remove the resolver for all modal types
        modals.forEach((modal, index) => {
          if (type === modal.type) {
            resolvers.current.splice(index, 1);
            promises.current.splice(index, 1);
          }
        });

        setModals((v) => v.filter((m) => m.type !== type));
      },
      [setModals]
    );

    const handleOpenModal = useCallback((modal: MT) => {
      const resolverPromise = new Promise((resolve) => {
        setModals((v) => [...v, modal]);
        resolvers.current.push(resolve);
      });

      promises.current.push(resolverPromise);

      return resolverPromise;
    }, []);

    const handleOpenModalUnique = useCallback(
      (modal: MT) => {
        const existingModal = modals.find(({ type }) => type === modal.type);
        if (!existingModal) {
          return handleOpenModal(modal);
        }
        const index = modals.indexOf(existingModal);

        return promises.current[index];
      },
      [modals]
    );

    const handleDisableClose = useCallback(() => {
      setIsCloseDisabled(true);
    }, [setIsCloseDisabled]);

    const handleEnableClose = useCallback(() => {
      setIsCloseDisabled(false);
    }, [setIsCloseDisabled]);

    const value = useMemo(
      () => ({
        isCloseDisabled,
        modals,
        closeModal: handleCloseModal,
        closeModalsByType: handleCloseModalsByType,
        disableClose: handleDisableClose,
        enableClose: handleEnableClose,
        openModal: handleOpenModal,
        openModalUnique: handleOpenModalUnique,
      }),
      [
        isCloseDisabled,
        modals,
        handleCloseModal,
        handleCloseModalsByType,
        handleDisableClose,
        handleEnableClose,
        handleOpenModal,
        handleOpenModalUnique,
      ]
    );

    return <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>;
  }

  return {
    ModalsContext,
    ModalsContextProvider,
  };
}

export default createModalsContext;
