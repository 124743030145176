import Link from 'next/link';
import { createStyles } from '@mantine/core';
import { match } from 'ts-pattern';

import { Routes } from '~/domains/common/constants/routes';
import { useGetBranding } from '~/domains/auth/hooks/useGetBranding';
import { BRANDS } from '~/domains/auth/consts';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { LogoSplashText } from './SitesMenu/logos/LogoSplashText';
import { LogoRunYourPool } from './SitesMenu/logos/LogoRunYourPool';
import { LogoOfficeFootballPool } from './SitesMenu/logos/LogoOfficeFootballPool';

const useStyles = createStyles((theme) => ({
  logo: {
    display: 'flex',

    '@media (hover: hover)': {
      '& svg': {
        transition: 'fill 150ms ease-in-out',
      },
      '&:hover svg': {
        fill: theme.colors.blue[4],
      },
    },
  },
  logoHideOnMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));

function Logo() {
  const { brand, isLoading } = useGetBranding();
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();

  if (isLoading) {
    return;
  }

  return (
    <>
      {match(brand)
        .with(BRANDS.RYP, () => (
          <Link href={Routes.authRYP()} className={classes.logo}>
            <LogoRunYourPool color="white" />
          </Link>
        ))
        .with(BRANDS.OFP, () => (
          <Link href={Routes.authOFP()} className={classes.logo}>
            <LogoOfficeFootballPool color="white" size={isMobile ? 20 : 30} />
          </Link>
        ))
        .otherwise(() => (
          <Link href={Routes.home()} className={cx(classes.logo, classes.logoHideOnMobile)}>
            <LogoSplashText color="white" />
          </Link>
        ))}
    </>
  );
}

export default Logo;
