import Cookies from 'js-cookie';

import { REFRESH_TOKEN_EXPIRATION_TIME, ACCESS_TOKEN_EXPIRATION_TIME } from '~/domains/auth/consts';
import log from '~/common/utils/log';
import { Routes } from '~/domains/common/constants/routes';

import { identityInstance } from './identity-instance';
import type { APIAuthRefreshTokenResponse } from './types';

let isRedirecting = false;

export const fetchRefreshToken = async (
  shouldRedirect = true,
  onErrorRedirectTo = Routes.signIn({
    brand: new URLSearchParams(window.location.search).get('brand') || undefined,
  })
): Promise<APIAuthRefreshTokenResponse['data']> => {
  try {
    const refreshToken = Cookies.get('refreshToken');
    const currentAccessToken = Cookies.get('accessToken');

    if (!refreshToken) {
      throw Error('No refresh token');
    }

    if (!currentAccessToken) {
      throw Error('No access token');
    }

    const response: APIAuthRefreshTokenResponse = await identityInstance.post(
      '/universal-auth/refresh',
      {
        refreshToken,
        accessToken: currentAccessToken,
      }
    );

    const tokenData = response.data;
    const { accessToken } = tokenData;

    Cookies.set('accessToken', accessToken, {
      expires: ACCESS_TOKEN_EXPIRATION_TIME,
    });
    Cookies.set('isLoggedIn', 'true', {
      expires: REFRESH_TOKEN_EXPIRATION_TIME,
      domain: '.splashsports.com',
      secure: true,
      sameSite: 'strict',
    });

    return tokenData;
  } catch (error) {
    Cookies.remove('accessToken');
    Cookies.remove('isLoggedIn', { domain: '.splashsports.com' });
    Cookies.remove('refreshToken');
    sessionStorage.clear();
    localStorage.clear();

    if (shouldRedirect && !isRedirecting) {
      isRedirecting = true;
      const fullUrl = `${window.location.origin}${onErrorRedirectTo}`;
      log(`Redirecting to ${fullUrl} because of a fetchRefreshToken error.`, error);
      window.location.href = fullUrl;
    }
    throw error;
  }
};
