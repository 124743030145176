import { useQuery } from '@tanstack/react-query';

import { PAYMENTS_QUERY_STALE_TIME } from '~/domains/payments/hooks/consts';
import { getKycVerify } from '~/services/kyc/kyc.service.api';

export function useKYCStatus(userId?: string) {
  return useQuery({
    queryKey: ['kyc/verification', userId],
    queryFn: async () => {
      const response = await getKycVerify();
      return response.data.status;
    },
    enabled: !!userId,
    staleTime: PAYMENTS_QUERY_STALE_TIME,
  });
}
