import { IconAeropay } from './IconAeropay';
import { IconAeropayWithText } from './IconAeropayWithText';
import { IconBankOfAmerica } from './IconBankOfAmerica';
import { IconCapitalOne } from './IconCapitalOne';
import { IconCharlesSchwab } from './IconCharlesSchwab';
import { IconChase } from './IconChase';
import { IconCITI } from './IconCITI';
import { IconUSAA } from './IconUSAA';
import { IconWellsFargo } from './IconWellsFargo';

export {
  IconAeropay,
  IconAeropayWithText,
  IconBankOfAmerica,
  IconCapitalOne,
  IconCharlesSchwab,
  IconChase,
  IconCITI,
  IconUSAA,
  IconWellsFargo,
};
