export const objectToTuples = (object: Record<string, unknown>) =>
  Object.entries(object)
    .filter(
      ([, value]) =>
        value !== null &&
        value !== undefined &&
        value !== 'undefined' &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0)
    )
    .map(([key, value]) => [key, String(value)]);
