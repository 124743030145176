import { createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import Cookies from 'js-cookie';

import packageJSON from '~/../package.json';

import { FEATURE } from '~/common/enums/feature.enum';
import { CurrencyConvertor } from '~/components/utils/formatters';
import { Routes } from '~/domains/common/constants/routes';
import { useIsTablet } from '~/domains/common/hooks/mediaQueries';
import { ContestStatusFilter } from '~/domains/contest/domains/services/enums/contest-status-filter.enum';
import useContestsUser from '~/domains/contest/hooks/useContestsUser';
import { ButtonLink } from '~/domains/design-system/Button';
import { titleStyles } from '~/domains/design-system/Title';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import type { APIAuthUserData } from '~/services/users/types';
import { isBrowserFn } from '~/utils/browser';
import { TUTORIAL_PLACEMENTS } from '~/domains/cms/tutorials/const';
import dt from '~/testing';
import { useGetBranding } from '~/domains/auth/hooks/useGetBranding';

import tc from '../../testing.const';
import { AppShellHeaderNotificationsButton } from '../AppShellHeaderNotificationsButton';
import { AppShellHeaderSearchButton } from '../AppShellHeaderSearchButton';
import Logo from '../common/Logo';
import SitesMenu from '../common/SitesMenu/SitesMenu';
import { HEADER_HEIGHT } from '../constants';
import NotificationBadge from '../NotificationBadge';

import MobileMenu from './MobileMenu';
import UserMenu from './UserMenu';
import EnvLabel from '../common/EnvLabel';

const useStyles = createStyles((theme) => ({
  nav: {
    display: 'flex',
    marginLeft: -theme.spacing.lg,

    [theme.fn.smallerThan('lg')]: {
      marginLeft: -theme.spacing.sm,
    },

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    ...titleStyles['subhead-medium'],
    borderTop: '2px solid transparent',
    borderBottom: '2px solid transparent',
    padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',

    '@media (hover: hover)': {
      transition: 'opacity 150ms ease-in-out',
      '&:hover': {
        opacity: 0.8,
      },
    },

    [theme.fn.smallerThan('lg')]: {
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
    },

    '&.isActive': {
      color: theme.colors.blue[4],
      borderBottomColor: theme.colors.blue[4],
    },
  },
  navLinkLabel: {
    position: 'relative',
  },
  navLinkBadge: {
    display: 'flex',
    position: 'absolute',
    top: -4,
    left: '100%',
  },
  divider: {
    height: 24,
    width: 1,
    background: theme.colors.gray2[4],
    [theme.fn.smallerThan('lg')]: {
      display: 'none',
    },
  },
  balanceValue: {
    textAlign: 'right',
    color: theme.colors.green[4],
    ...titleStyles['headline-small'],
  },
  balanceLabel: {
    textAlign: 'right',
    color: theme.colors.gray[4],
    ...titleStyles['subhead-x-small'],
  },
  depositButton: {
    [theme.fn.smallerThan('lg')]: {
      display: 'none',
    },
  },
  rightSlot: {
    marginLeft: 'auto',
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  rightSlotNav: {
    display: 'flex',
    alignItems: 'center',
    marginRight: -16,
  },
  rightSlotNavButtons: {
    display: 'flex',
    gap: 16,
  },
  updatesBadge: {},
}));

type AppShellHeaderSignedInProps = {
  user: APIAuthUserData;
  channelHandle?: string;
  channelSlug?: string;
  avatarUrl?: string;
  balance?: number;
  isUserVerified?: boolean;
};

function AppShellHeaderSignedIn({
  user,
  channelHandle,
  channelSlug,
  avatarUrl,
  balance,
  isUserVerified,
}: AppShellHeaderSignedInProps) {
  const { classes, cx } = useStyles();
  const router = useRouter();
  const { t } = useTranslation('common');
  const isMobile = useIsTablet();
  const { isEOBrand } = useGetBranding();
  const showNav = !isEOBrand;

  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);
  const isPropsEnabled = useIsFeatureEnabled(FEATURE.ENABLE_PROPS);
  const isEntriesPageEnabled = useIsFeatureEnabled(FEATURE.ENABLE_ENTRIES_PAGE);
  const { itemsTotal: entriesActionRequiredTotal } = useContestsUser({
    status: ContestStatusFilter.ACTION_REQUIRED,
    limit: 0,
    enabled: isEntriesPageEnabled,
  });

  const navLinks = useMemo(
    () => [
      { href: Routes.main(), label: t('appShellHeader.home'), isExact: true },
      { href: Routes.contestLobby(), label: t('appShellHeader.contestLobby') },
      ...(isPropsEnabled
        ? [
            {
              href: Routes.quickPicksBoard(),
              label: (
                <>
                  {t('appShellHeader.quickPicks')}
                  {!Cookies.get(TUTORIAL_PLACEMENTS.TUTORIAL_QUICK_PICKS_BOARD) && (
                    <div
                      data-test-id={dt.components.shared.header.quickPicksButton}
                      className={classes.navLinkBadge}
                    >
                      <NotificationBadge showEmpty />
                    </div>
                  )}
                </>
              ),
            },
          ]
        : []),
      ...(isEntriesPageEnabled
        ? [
            {
              href: Routes.entries(),
              label: (
                <>
                  {t('appShellHeader.entries')}
                  {entriesActionRequiredTotal ? (
                    <div className={classes.navLinkBadge}>
                      <NotificationBadge>{entriesActionRequiredTotal}</NotificationBadge>
                    </div>
                  ) : null}
                </>
              ),
            },
          ]
        : [{ href: Routes.myContests(), label: t('appShellHeader.myContests') }]),
      // eslint-disable-next-line no-nested-ternary
      ...(isChannelsV1Enabled && channelHandle
        ? [{ href: Routes.channel(channelHandle), label: t('appShellHeader.myChannel') }]
        : channelSlug
          ? [{ href: Routes.commissionerHub(channelSlug), label: t('appShellHeader.myChannel') }]
          : []),
    ],
    [
      t,
      isPropsEnabled,
      isEntriesPageEnabled,
      entriesActionRequiredTotal,
      classes.navLinkBadge,
      isChannelsV1Enabled,
      channelHandle,
      channelSlug,
    ]
  );

  if (isMobile) {
    return (
      <>
        <EnvLabel />
        <SitesMenu />
        <Logo />
        {showNav && (
          <div className={classes.rightSlot}>
            <AppShellHeaderSearchButton />
            <AppShellHeaderNotificationsButton />
            <MobileMenu
              user={user}
              channelHandle={channelHandle}
              channelSlug={channelSlug}
              avatarUrl={avatarUrl}
              isUserVerified={isUserVerified}
              balance={balance}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <EnvLabel />
      <SitesMenu />
      <Logo />
      {showNav && (
        <>
          <nav className={classes.nav}>
            {navLinks.map(({ href, label, isExact }) => (
              <Link
                data-test-id={dt.common.components.appShellHeader.homeButton}
                href={href}
                className={cx(classes.navLink, {
                  isActive: isExact ? router.asPath === href : router.asPath.startsWith(href),
                })}
                key={href}
              >
                <div className={classes.navLinkLabel}>{label}</div>
              </Link>
            ))}
          </nav>
          <div className={classes.rightSlot}>
            <div className={classes.rightSlotNav}>
              <div className={classes.rightSlotNavButtons}>
                <AppShellHeaderSearchButton />
                <AppShellHeaderNotificationsButton />
              </div>
              <UserMenu
                handle={channelHandle || user?.handle || user?.username}
                email={user?.email}
                channelHandle={channelHandle}
                channelSlug={channelSlug}
                avatarUrl={avatarUrl}
                isUserVerified={isUserVerified}
              />
            </div>
            <div className={classes.divider} />
            {typeof balance === 'number' && (
              <div>
                <div
                  className={classes.balanceValue}
                  data-test-id={tc.components.appShellHeader.balance}
                >
                  {CurrencyConvertor(balance)}
                </div>
                <div className={classes.balanceLabel}>{t('appShellHeader.balance')}</div>
              </div>
            )}
            <ButtonLink
              variant="primary-fill"
              size="small"
              href={Routes.deposit(
                isBrowserFn()
                  ? { redirectTo: `${window.location.pathname}${window.location.search}` }
                  : undefined
              )}
              className={classes.depositButton}
              data-test-id={tc.components.appShellHeader.depositButton}
            >
              {t('appShellHeader.deposit')}
            </ButtonLink>
          </div>
        </>
      )}
    </>
  );
}

export default AppShellHeaderSignedIn;
