import { createStyles, Stack, Tooltip } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { PaymentType } from '~/common/enums/payments.enum';
import { snakeCaseToTitleCase, upperCaseToTitleCase } from '~/components/utils/formatters';
import { Text } from '~/domains/design-system/Text';
import { useIsCanada } from '~/domains/common/hooks/useIsCanada';
import dt from '~/testing';
import type { BankAccount as BankAccountType } from '~/services/wallet/interfaces/wallets.interface';
import { IconAeropayWithText } from '~/domains/payments/icons/BankIcons';
import { Tag } from '~/domains/design-system/Tag';

import PaymentTypeIcon from '../../icons/PaymentTypeIcon';
import type { PaymentTypeSettings } from '../hooks/useWithdrawalPaymentMethods';
import { useDeleteAeropayAccount } from '../../hooks/useDeleteAeropay';
import { BankAccount } from '../../deposits/components/BankAccount';

const useStyles = createStyles((theme, { isBankAccount }: { isBankAccount?: boolean }) => ({
  container: {
    '&.isFullWidthRadio': {
      gridColumn: '1 / span 2',
    },
  },
  label: {
    width: '100%',
    border: `1px solid ${theme.colors.gray2[1]}`,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: isBankAccount ? 'auto' : 96,
    transition: 'border-color 150ms ease-in-out',

    ...(!isBankAccount && {
      '&[data-payment-type="AEROPAY"]': {
        padding: '24px',
        height: 'auto',
      },
    }),

    '&[data-disabled]': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
  },
  input: {
    display: 'none',

    '&:checked ~ label': {
      background: theme.white,
      borderColor: theme.colors.gray[7],
    },
  },
}));

type PaymentTypeRadioProps = {
  type: PaymentType;
  value: string;
  settings: PaymentTypeSettings;
  isActive: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  superscriptIndex: number;
  // in withdrawal form, we have a full width radio for recommended VIPPreferred and Skrill
  isWithdrawal?: boolean;
  bankAccount?: BankAccountType;
  isDeleteBankAccountVisible?: boolean;
  description?: string;
  recommended?: boolean;
};

export function PaymentTypeRadio({
  type,
  value,
  settings,
  isActive,
  onChange,
  superscriptIndex,
  isWithdrawal = true,
  bankAccount,
  isDeleteBankAccountVisible = false,
  description,
  recommended = false,
}: PaymentTypeRadioProps) {
  const { t } = useTranslation('payments');
  const isCanada = useIsCanada();
  const { classes, cx, theme } = useStyles({ isBankAccount: !!bankAccount });

  const { mutate: deleteAeropayAccount, isLoading: isDeletingAeropayAccount } =
    useDeleteAeropayAccount();

  const isFullWidthRadio =
    isWithdrawal &&
    (type === PaymentType.VIPPREFERRED || (type === PaymentType.SKRILL && isCanada));

  const CustomContent = (
    <div className={cx(classes.container, { isFullWidthRadio })}>
      {recommended && <Tag marginBottom={-12}>{t('deposits.recommendedDepositMethods')}</Tag>}
      <input
        type="radio"
        name="paymentType"
        id={`${type}-${value}`}
        value={value}
        checked={isActive}
        onChange={onChange}
        disabled={settings.isDisabled}
        aria-label={t(`paymentTypes.${type}`)}
        className={classes.input}
      />

      <label
        className={classes.label}
        htmlFor={`${type}-${value}`}
        data-disabled={settings.isDisabled ? true : undefined}
        data-payment-type={!bankAccount ? type : undefined}
      >
        {bankAccount ? (
          <BankAccount
            bankAccount={bankAccount}
            isDeleteBankAccountVisible={isDeleteBankAccountVisible}
            isDeletingAeropayAccount={isDeletingAeropayAccount}
            deleteAeropayAccount={deleteAeropayAccount}
          />
        ) : (
          <Stack data-test-id={dt.payments.withdrawal.components.paymentType} spacing={8}>
            <Stack
              w={type === PaymentType.AEROPAY ? 76 : 36}
              h={24}
              align="center"
              justify="center"
              sx={
                type === PaymentType.AEROPAY || type === PaymentType.CARD
                  ? { alignSelf: 'center' }
                  : {
                      alignSelf: 'center',
                      borderRadius: 4,
                      border: `1px solid ${theme.colors.gray[2]}`,
                    }
              }
            >
              <PaymentTypeIcon
                type={type}
                color={settings.isDisabled ? theme.colors.gray[6] : undefined}
                size={type === PaymentType.AEROPAY ? 10 : 20}
              />
            </Stack>
            <Text
              variant="subhead-small"
              color={settings.isDisabled ? 'gray.6' : undefined}
              ta="center"
            >
              {
                // It's possible to withdraw only to a debit cards. Credit cards are filtered out in usePaymentMethods, so we can safely assume that the card is a debit card.
                type === PaymentType.CARD ? t('paymentTypes.DEBIT_CARD') : t(`paymentTypes.${type}`)
              }
              {type === PaymentType.AEROPAY && (
                <Text component="span" inline variant="subhead-small">
                  {' via '}
                  <IconAeropayWithText />
                </Text>
              )}
              {!settings.isFree ? (
                <Text variant="body-small" component="sup" c="gray.6" sx={{ marginLeft: 2 }}>
                  {superscriptIndex}
                </Text>
              ) : null}

              {description && (
                <Text variant="body-small" color="gray.6" ta="center" component="div">
                  {description}
                </Text>
              )}
            </Text>
          </Stack>
        )}
      </label>
    </div>
  );

  return settings.isDisabled ? (
    <Tooltip
      key={type}
      label={
        settings.disabledReason ??
        t('deposits.tooltip.disabledPaymentMethod', {
          paymentMethod: upperCaseToTitleCase(snakeCaseToTitleCase(type)),
        })
      }
      multiline
      events={{ hover: true, focus: true, touch: true }}
      zIndex={99}
    >
      <div
        //  Div wrapper is required for proper event propagation. Without it, hover/focus events might not bubble up correctly to the Tooltip.
        style={{ width: '100%' }}
      >
        {CustomContent}
      </div>
    </Tooltip>
  ) : (
    CustomContent
  );
}
