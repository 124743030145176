import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';

import { Routes } from '~/domains/common/constants/routes';
import { PrevLocationContext } from '~/domains/common/context/PrevLocationContext';

type GamecenterPrevPathContextValue = {
  prevLocationBeforeGamecenter: string;
};

const GamecenterPrevPathContext = createContext<GamecenterPrevPathContextValue>({
  prevLocationBeforeGamecenter: Routes.myContests(),
});

export function GamecenterPrevPathContextProvider({ children }: PropsWithChildren) {
  const { prevAsPath } = useContext(PrevLocationContext);
  const [prevLocationBeforeGamecenter, setPrevLocationBeforeGamecenter] = useState<string | null>(
    null
  );
  const prevRoute = prevLocationBeforeGamecenter ?? Routes.entries();

  useEffect(() => {
    // next router is not reliable and does not return params on the first render
    const contestUuid = (() => {
      const parts = prevAsPath?.split('/');
      if (parts?.[1] !== 'contest') { return; }

      return parts[2];
    })();

    if (!prevAsPath || (contestUuid && prevAsPath?.startsWith(`/contest/${contestUuid}/`))) {
      return;
    }
    if (
      [Routes.signIn(), Routes.signUp(), Routes.userVerification()].some((path) =>
        prevAsPath.startsWith(path)
      )
    ) {
      return;
    }

    setPrevLocationBeforeGamecenter(prevAsPath);
  }, [prevAsPath]);

  const value = useMemo(
    () => ({
      prevLocationBeforeGamecenter: prevRoute,
    }),
    [prevRoute]
  );

  return (
    <GamecenterPrevPathContext.Provider value={value}>
      {children}
    </GamecenterPrevPathContext.Provider>
  );
}

export default GamecenterPrevPathContext;
