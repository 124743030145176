import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useContext } from 'react';

import { KYCContext } from '~/components/providers/KYCProvider';
import { UserContext } from '~/components/providers/UserProvider';
import { reportEvent } from '~/domains/analytics';
import dt from '~/testing';

import { Banner, BannerType } from '../Banner/Banner';
import { ExternalLink } from '../Link/Link';
import { Text } from '../Text/Text';

import { isHideBannerRoute, isUserDuplicate, isUserExcluded, isUserLocked } from './utils';

interface VerificationBannerProps {
  minimal?: boolean;
}
interface VerificationBannerChildProps {
  minimal?: boolean;
  bannerType?: BannerType;
}

export function GeoLocationPromptedBanner({
  minimal,
  bannerType = BannerType.GEO_WARNING,
}: VerificationBannerChildProps) {
  const { t } = useTranslation('common');
  return (
    <Banner
      title={
        <Trans
          i18nKey="common:banner.geoLocationPrompted.messageMobile"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.geoLocationPrompted.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="https://knowledge.splashsports.com/blog/location-verification"
                target="_blank"
              />
            ),
          }}
        />
      }
      message={
        <Trans
          i18nKey="common:banner.geoLocationPrompted.messageDesktop"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.geoLocationPrompted.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="https://knowledge.splashsports.com/blog/location-verification"
                target="_blank"
              />
            ),
          }}
        />
      }
      type={bannerType}
      allowClose={false}
      minimal={minimal}
      dataTestId={dt.common.components.banner.verificationBanner}
    />
  );
}

export function UserExcludedBanner({
  minimal,
  bannerType = BannerType.ERROR,
}: VerificationBannerChildProps) {
  return (
    <Banner
      title={
        <Trans
          i18nKey="common:banner.userExcluded.messageMobile"
          components={{
            link: (
              <ExternalLink
                href="https://legal.splashsports.com/legal/responsible-gaming"
                target="_blank"
              />
            ),
          }}
        />
      }
      truncate={false}
      message={
        <Trans
          i18nKey="common:banner.userExcluded.messageDesktop"
          components={{
            link: (
              <ExternalLink
                href="https://legal.splashsports.com/legal/responsible-gaming"
                target="_blank"
              />
            ),
          }}
        />
      }
      type={bannerType}
      allowClose
      minimal={minimal}
      dataTestId={dt.common.components.banner.verificationBanner}
    />
  );
}

export function UserLockedBanner({
  minimal,
  bannerType = BannerType.ERROR,
}: VerificationBannerChildProps) {
  return (
    <Banner
      message={
        <Trans
          i18nKey="common:banner.userLocked.messageDesktop"
          components={{
            link: (
              <ExternalLink
                href="mailto:support@splashsports.com?subject=Splash Support&body=Hello, Splash Support"
                target="_blank"
              />
            ),
          }}
        />
      }
      title={
        <Trans
          i18nKey="common:banner.userLocked.messageMobile"
          components={{
            link: (
              <ExternalLink
                href="mailto:support@splashsports.com?subject=Splash Support&body=Hello, Splash Support"
                target="_blank"
              />
            ),
          }}
        />
      }
      type={bannerType}
      allowClose={false}
      minimal={minimal}
      dataTestId={dt.common.components.banner.verificationBanner}
    />
  );
}

export function UnderLegalAgeBanner({
  minimal,
  state,
  requiredAge,
  bannerType = BannerType.ERROR,
}: VerificationBannerChildProps & { state: string; requiredAge: number }) {
  const { t } = useTranslation('common');
  return (
    <Banner
      title={t('banner.underLegalAge.messageMobile', {
        state,
        requiredAge,
      })}
      message={t('banner.underLegalAge.messageDesktop', {
        state,
        requiredAge,
      })}
      type={bannerType}
      allowClose={false}
      minimal={minimal}
      dataTestId={dt.common.components.banner.verificationBanner}
      truncate={false}
    />
  );
}

export function DuplicateAccountBanner({
  minimal,
  bannerType = BannerType.ERROR,
}: VerificationBannerChildProps) {
  const { t } = useTranslation('common');
  return (
    <Banner
      title={
        <Trans
          i18nKey="common:banner.duplicateAccount.messageMobile"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.duplicateAccount.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="mailto:support@splashsports.com?subject=Splash Support&body=Hello, Splash Support"
                target="_blank"
              />
            ),
          }}
        />
      }
      message={
        <Trans
          i18nKey="common:banner.duplicateAccount.messageDesktop"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.duplicateAccount.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="mailto:support@splashsports.com?subject=Splash Support&body=Hello, Splash Support"
                target="_blank"
              />
            ),
          }}
        />
      }
      type={bannerType}
      allowClose={false}
      minimal={minimal}
      truncate={false}
      dataTestId={dt.common.components.banner.verificationBanner}
    />
  );
}

export function LocationCheckFailedBanner({
  minimal,
  bannerType = BannerType.GEO_WARNING,
}: VerificationBannerChildProps) {
  const { t } = useTranslation('common');
  return (
    <Banner
      truncate={false}
      title={
        <Trans
          i18nKey="common:banner.locationCheckFailed.messageMobile"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.locationCheckFailed.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="https://knowledge.splashsports.com/blog/location-verification"
                target="_blank"
              />
            ),
          }}
        />
      }
      message={
        <Trans
          i18nKey="common:banner.locationCheckFailed.messageMobile"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.locationCheckFailed.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="https://knowledge.splashsports.com/blog/location-verification"
                target="_blank"
              />
            ),
          }}
        />
      }
      type={bannerType}
      allowClose={false}
      minimal={minimal}
      dataTestId={dt.common.components.banner.verificationBanner}
    />
  );
}

export function LocationDeniedBanner({
  minimal,
  bannerType = BannerType.GEO_ERROR,
}: VerificationBannerChildProps) {
  const { t } = useTranslation('common');
  return (
    <Banner
      truncate={false}
      title={
        <Trans
          i18nKey="common:banner.locationDenied.messageMobile"
          components={{
            prefixText: <Text variant="web-label-large">{t('banner.locationDenied.prefix')}</Text>,
            link: (
              <ExternalLink
                href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                target="_blank"
              />
            ),
          }}
        />
      }
      message={
        <Trans
          i18nKey="common:banner.locationDenied.messageDesktop"
          components={{
            prefixText: <Text variant="web-label-large">{t('banner.locationDenied.prefix')}</Text>,
            link: (
              <ExternalLink
                href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                target="_blank"
              />
            ),
          }}
        />
      }
      type={bannerType}
      allowClose={false}
      minimal={minimal}
      dataTestId={dt.common.components.banner.verificationBanner}
    />
  );
}

export function VerificationNeededBanner({
  minimal,
  bannerType = BannerType.WARNING,
}: VerificationBannerChildProps) {
  const { t } = useTranslation('common');
  return (
    <Banner
      truncate={false}
      title={
        <Trans
          i18nKey="common:banner.verificationBanner.messageMobile"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.verificationBanner.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                target="_blank"
              />
            ),
          }}
        />
      }
      message={
        <Trans
          i18nKey="common:banner.verificationBanner.messageDesktop"
          components={{
            prefixText: (
              <Text variant="web-label-large">{t('banner.verificationBanner.prefix')}</Text>
            ),
            link: (
              <ExternalLink
                href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                target="_blank"
              />
            ),
          }}
        />
      }
      secondaryMessage={t('banner.verificationBanner.cta')}
      type={bannerType}
      allowClose={false}
      minimal={minimal}
      dataTestId={dt.common.components.banner.verificationBanner}
    />
  );
}

function VerificationBanner({ minimal = false }: VerificationBannerProps) {
  const router = useRouter();
  const { user, userDetails } = useContext(UserContext);
  const { KYCStatus } = useContext(KYCContext);

  const analyticsMetadata = {
    userDetails,
    KYCStatus,
  };

  if (!user) {
    return null;
  }

  if (isHideBannerRoute(router.route)) {
    return null;
  }

  if (isUserExcluded(userDetails)) {
    const bannerType = BannerType.ERROR;
    reportEvent('User > Verification Banner Shown', {
      type: 'UserExcludedBanner',
      bannerType,
      ...analyticsMetadata,
    });
    return <UserExcludedBanner minimal={minimal} bannerType={bannerType} />;
  }

  if (isUserLocked(userDetails)) {
    const bannerType = BannerType.ERROR;
    reportEvent('User > Verification Banner Shown', {
      type: 'UserLockedBanner',
      bannerType,
      ...analyticsMetadata,
    });
    return <UserLockedBanner minimal={minimal} bannerType={bannerType} />;
  }

  if (isUserDuplicate(userDetails)) {
    const bannerType = BannerType.ERROR;
    reportEvent('User > Verification Banner Shown', {
      type: 'DuplicateAccountBanner',
      bannerType,
      ...analyticsMetadata,
    });
    return <DuplicateAccountBanner minimal={minimal} bannerType={bannerType} />;
  }
}

export default VerificationBanner;
