import queryString from 'query-string';

import { nextApiServiceInstance, nextApiAuthServiceInstance } from './nextApi.instance';

export const getLatestAppVersion = async () => {
  const response = await nextApiServiceInstance.get('/version');

  return response.data;
};

type GetAutocompleteProps = {
  input?: string;
  country?: string;
  placeId?: string;
  sessionToken: string;
};

export const getAutocomplete = async ({
  input,
  country,
  placeId,
  sessionToken,
}: GetAutocompleteProps) => {
  const query = queryString.stringify({ input, country, placeId, sessionToken });

  const response = await nextApiAuthServiceInstance.get(`/autocomplete?${query}`);

  return response.data as
    | google.maps.places.AutocompleteResponse
    | { result: google.maps.places.PlaceResult };
};

export const getExchangeSingleUseToken = async ({
  sut,
  webView,
}: {
  sut: string;
  webView: boolean;
}): Promise<string> => {
  const response = await nextApiAuthServiceInstance.post(`/sut/single-use`, {
    sut,
    webView,
  });

  return response.data;
};
