import { CheckRequestStatus } from '@xpointtech/xpoint-js';

import { LocationProviderQueryKeys } from '~/components/providers/query';
import { reportEvent } from '~/domains/analytics';
import { PermissionStatusState } from '~/utils/geoPermissions';

import { over18UnderLegalAgeStates, specialStates } from '../consts';
import type { AccessChecksProps, ProtectionGuardType } from '../types';

function locationCheck({
  action,
  contestId,
  geoPermissionStatus,
  isLocationLoading,
  locationErrors,
  locationState,
  locationStatus,
  userId,
  userAge,
  closeModalsByType,
  forceRerender,
  handleOnAccessFailed,
  openModalUnique,
}: AccessChecksProps<ProtectionGuardType.LOCATION>) {
  if (isLocationLoading) return;

  const locationTokenQueryKey = LocationProviderQueryKeys.LOCATION_TOKEN(
    userId,
    geoPermissionStatus
  );

  // Analytics reporting
  const reportFailedEvent = () => {
    reportEvent('ProtectionGuard > Location Check Failed', {
      action,
      contestId,
      geoPermissionStatus,
      locationErrors,
      locationState,
      locationStatus,
      userId,
      userAge,
    });
  };

  // No location permission
  if (geoPermissionStatus !== PermissionStatusState.GRANTED) {
    openModalUnique({
      type: 'LocationPermissionRequiredModal',
      onRecheckPermission: () => {
        closeModalsByType('LocationPermissionRequiredModal');
        forceRerender();
      },
    }).then(handleOnAccessFailed);

    reportFailedEvent();
    return false;
  }
  if (geoPermissionStatus === PermissionStatusState.GRANTED) {
    closeModalsByType('LocationPermissionRequiredModal');
  }

  // Location invalid (global xPoint status denied)
  if (locationStatus === CheckRequestStatus.DENIED) {
    openModalUnique({
      type: 'InvalidLocationModal',
      queryKey: locationTokenQueryKey,
    }).then(handleOnAccessFailed);

    reportFailedEvent();
    return false;
  }
  if (locationStatus === CheckRequestStatus.ALLOWED) {
    closeModalsByType('InvalidLocationModal');
  }

  // User is under legal age specific to the state
  if (locationState && specialStates.includes(locationState)) {
    const { age: requiredAge } = over18UnderLegalAgeStates[locationState];

    if (userAge < requiredAge) {
      openModalUnique({
        type: 'AgeRestrictionModal',
        queryKey: locationTokenQueryKey,
      }).then(handleOnAccessFailed);

      reportFailedEvent();
      return false;
    }

    if (userAge >= requiredAge) {
      closeModalsByType('AgeRestrictionModal');
    }
  }

  // Location error
  if (locationErrors && locationErrors.length > 0) {
    openModalUnique({
      type: 'LocationErrorModal',
      locationErrors,
      queryKey: locationTokenQueryKey,
    }).then(handleOnAccessFailed);

    reportFailedEvent();
    return false;
  }
  if (locationErrors && locationErrors.length === 0) {
    closeModalsByType('LocationErrorModal');
  }

  return true;
}

export { locationCheck };
