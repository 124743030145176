import type { ZodRawShape, z } from 'zod';

import { jwtDecodeSafe } from '~/utils/jwt';

const validateTokenOnSchema = <T extends ZodRawShape>(token: string, schema: z.ZodObject<T>) => {
  const validatedToken = jwtDecodeSafe(token);
  return schema.safeParse(validatedToken);
};

export default validateTokenOnSchema;
