import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import type { FullContest } from '@betterpool/api-types';

import { KYCContext } from '~/components/providers/KYCProvider';
import { UserContext } from '~/components/providers/UserProvider';
import { LocationContext } from '~/components/providers/LocationProvider';
import { RootModalsContext } from '~/domains/common/context/RootModalsContext/RootModalsContext';
import { ContestQueryKeys } from '~/domains/contest/constants/query';

import { ProtectionGuardType } from '../types';
import { calculateAge } from '../utils';

import runAccessCheck from './accessChecks';

type UseKYCProtectedGuardProps = {
  action: string;
  protectionLevels: ProtectionGuardType[];
  allowAnnonymous?: boolean;
  contestId?: string;
  checkOnMount?: boolean;
  isApiLocationRestricted?: boolean;
  redirectOnClose?: string | ((redirectTo?: string) => string);
};

function useProtectedGuard({
  action,
  protectionLevels,
  allowAnnonymous = false,
  contestId,
  checkOnMount,
  redirectOnClose,
  isApiLocationRestricted,
}: UseKYCProtectedGuardProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccessible, setIsAccessible] = useState(false);

  const { getRedirect, userId, userDetails } = useContext(UserContext);
  // Using "isFetching" query value because isInitialLoading doesn't fire when new data is fetched...
  const { KYCStatus, isKYCfetching: isKYCloading } = useContext(KYCContext);
  const {
    locationCountry,
    locationState,
    locationStatus,
    locationErrors,
    geoPermissionStatus,
    isLoading: isLocationLoading,
  } = useContext(LocationContext);
  const { openModalUnique, closeModalsByType } = useContext(RootModalsContext);

  const contestQueryKey = ContestQueryKeys.CONTEST_DETAIL({ contestId, userId });
  const queryClient = useQueryClient();
  const isContestLoading = queryClient.isFetching(contestQueryKey);
  const contestData = queryClient.getQueryData<FullContest>(contestQueryKey);

  const router = useRouter();
  const redirectTo = (getRedirect && getRedirect()) || window.location.href;
  const userAge = useMemo(() => calculateAge(userDetails?.dateOfBirth), [userDetails]);

  // Using refs to provide pointers to get around the react lifecycle providing stale props (not updating during the check)
  // Note: isLoading states are most important to be fresh, we can probably live without the other
  const dataRef = useRef({
    contestData,
    geoPermissionStatus,
    isContestLoading: false,
    isKYCloading: false,
    isLocationRestricted: isApiLocationRestricted,
    isLocationLoading: false,
    KYCStatus: null,
    locationCountry,
    locationErrors,
    locationState,
    locationStatus,
    userAge,
    userId,
  });
  dataRef.current.contestData = contestData;
  dataRef.current.geoPermissionStatus = geoPermissionStatus;
  dataRef.current.isContestLoading = isContestLoading > 0;
  dataRef.current.isKYCloading = isKYCloading;
  dataRef.current.isLocationRestricted = isApiLocationRestricted;
  dataRef.current.isLocationLoading = isLocationLoading;
  dataRef.current.KYCStatus = KYCStatus;
  dataRef.current.locationCountry = locationCountry;
  dataRef.current.locationErrors = locationErrors;
  dataRef.current.locationState = locationState;
  dataRef.current.locationStatus = locationStatus;
  dataRef.current.userAge = userAge;
  dataRef.current.userId = userId;

  // Universal onClose handler for all error modals
  const handleOnAccessFailed = useCallback(
    (isSubmit: boolean) => {
      if (isSubmit) return;

      if (redirectOnClose) {
        if (typeof redirectOnClose === 'function') {
          const redirectUrl = redirectOnClose(redirectTo);
          router.push(redirectUrl);
        } else {
          router.push(redirectOnClose);
        }
      }
    },
    [redirectOnClose, redirectTo, router]
  );

  // This is the main function that runs the protection checks on demand with optional callback
  const runProtectionChecks = async (callback?: VoidFunction) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise<boolean | void>(async (resolve) => {
      if (isKYCloading || isLocationLoading || isContestLoading) {
        setIsLoading(true);
      }

      // Get fresh contest data if required (first load might not have location token included)
      if (protectionLevels.includes(ProtectionGuardType.CONTEST)) {
        setIsLoading(true);
        await queryClient.refetchQueries({ queryKey: contestQueryKey });
      }

      // Start polling for the loading states.
      // Note we have to start using refs to drill props into the interval function scope
      const checkLoadingInterval = setInterval(() => {
        if (
          (!dataRef.current.isKYCloading || !protectionLevels.includes(ProtectionGuardType.KYC)) &&
          (!dataRef.current.isLocationLoading ||
            !protectionLevels.includes(ProtectionGuardType.LOCATION)) &&
          (!dataRef.current.isContestLoading ||
            !protectionLevels.includes(ProtectionGuardType.CONTEST))
        ) {
          clearInterval(checkLoadingInterval);

          // Once loading is complete, run the checks and resolve
          const checkResult = runAccessCheck(protectionLevels, {
            ...dataRef.current,
            action,
            allowAnnonymous,
            contestId,
            redirectTo,
            closeModalsByType,
            forceRerender: () => runProtectionChecks(callback),
            handleOnAccessFailed,
            openModalUnique,
          });

          setIsLoading(false);
          setIsAccessible(checkResult);

          if (checkResult && callback) resolve(callback());
          else resolve(checkResult);
        }
      }, 100);
    });

  // Run the checks on mount if desired
  useEffect(() => {
    if (checkOnMount) {
      runProtectionChecks();
    }
  }, []);

  return {
    runProtectionChecks,
    isLoading,
    isAccessible,
    isError: locationErrors && locationErrors.length > 0,
  };
}

export default useProtectedGuard;
