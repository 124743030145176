import type { Icon, TablerIconsProps } from '@tabler/icons-react';
import { IconBrandMastercard, IconBrandVisa, IconCreditCard } from '@tabler/icons-react';

import { Box, Flex, createStyles } from '@mantine/core';
import { CardType } from '~/services/wallet/interfaces/wallets.interface';

export function IconVisa({ size = 24 }: { size?: number }) {
  const height = size;
  const width = size * 1.54667;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.25" y="0.5" width="35" height="23" rx="3.5" stroke="#D1D1D1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8753 16.2582H10.7549L9.16495 10.1924C9.08949 9.91334 8.92925 9.66667 8.69354 9.5504C8.10531 9.25823 7.45712 9.0257 6.75 8.90843V8.6749H10.1657C10.6371 8.6749 10.9906 9.0257 11.0496 9.43313L11.8745 13.8086L13.9938 8.6749H16.0552L12.8753 16.2582ZM17.2338 16.2582H15.2313L16.8802 8.6749H18.8827L17.2338 16.2582ZM21.4734 10.7757C21.5323 10.3673 21.8859 10.1337 22.2983 10.1337C22.9465 10.0751 23.6526 10.1924 24.2419 10.4835L24.5954 8.85081C24.0062 8.61727 23.358 8.5 22.7698 8.5C20.8262 8.5 19.412 9.55041 19.412 11.0082C19.412 12.1173 20.4137 12.6996 21.1208 13.0504C21.8859 13.4002 22.1805 13.6338 22.1216 13.9835C22.1216 14.5082 21.5323 14.7418 20.9441 14.7418C20.2369 14.7418 19.5298 14.5669 18.8827 14.2747L18.5291 15.9085C19.2362 16.1996 20.0012 16.3169 20.7084 16.3169C22.8876 16.3745 24.2419 15.3251 24.2419 13.75C24.2419 11.7665 21.4734 11.6502 21.4734 10.7757ZM31.25 16.2582L29.66 8.6749H27.9522C27.5986 8.6749 27.2451 8.90843 27.1272 9.25823L24.1829 16.2582H26.2443L26.6558 15.1502H29.1886L29.4243 16.2582H31.25ZM28.2467 10.7168L28.8349 13.5748H27.186L28.2467 10.7168Z"
        fill="#172B85"
      />
    </svg>
  );
}

export function IconMasterCard({ size = 24 }: { size?: number }) {
  const height = size;
  const width = size * 1.5;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.25" y="0.5" width="35" height="23" rx="3.5" stroke="#D1D1D1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 17.1569C17.5584 18.1852 16.0128 18.806 14.3238 18.806C10.5551 18.806 7.5 15.7154 7.5 11.903C7.5 8.09057 10.5551 5 14.3238 5C16.0128 5 17.5584 5.62075 18.75 6.64903C19.9416 5.62075 21.4872 5 23.1762 5C26.9449 5 30 8.09057 30 11.903C30 15.7154 26.9449 18.806 23.1762 18.806C21.4872 18.806 19.9416 18.1852 18.75 17.1569Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 17.1569C20.2172 15.8908 21.1475 14.0068 21.1475 11.903C21.1475 9.79917 20.2172 7.91517 18.75 6.64903C19.9416 5.62075 21.4872 5 23.1762 5C26.9449 5 30 8.09057 30 11.903C30 15.7154 26.9449 18.806 23.1762 18.806C21.4872 18.806 19.9416 18.1852 18.75 17.1569Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 17.1569C20.2172 15.8908 21.1475 14.0068 21.1475 11.903C21.1475 9.79917 20.2172 7.91518 18.75 6.64905C17.2829 7.91518 16.3525 9.79917 16.3525 11.903C16.3525 14.0068 17.2829 15.8908 18.75 17.1569Z"
        fill="#FF5E00"
      />
    </svg>
  );
}

const cartTypeToIconComponent: Record<CardType, Icon> = {
  [CardType.MC]: IconBrandMastercard,
  [CardType.VI]: IconBrandVisa,
  [CardType.AM]: IconBrandMastercard,
  [CardType.DI]: IconBrandVisa,
};

export default function CardTypeIcon({ type, ...props }: TablerIconsProps & { type?: CardType }) {
  const Icon = cartTypeToIconComponent[type] ?? IconCreditCard;
  return <Icon {...props} />;
}

const useStyles = createStyles(() => ({
  container: {
    alignItems: 'center',
    gap: 8,
    maxWidth: 80,
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
}));

export function CardTypeIconMultiple() {
  const { classes } = useStyles();
  return (
    <Flex className={classes.container}>
      <Box className={classes.iconBox}>
        <IconVisa />
      </Box>
      <Box className={classes.iconBox}>
        <IconMasterCard />
      </Box>
    </Flex>
  );
}
