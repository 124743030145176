export default {
  breadcrumbs: {
    manageEntrants: 'Manage entrants',
  },
  entrants: {
    title: 'Entrants',
    cta: 'Manage entrants',
  },
  search: {
    placeholder: 'Search entrants by name, handle, or email',
  },
  table: {
    title: 'Manage entrants',
    download: 'Download',
    headers: {
      entrants: 'ENTRANTS ({{count}})',
      email: 'EMAIL',
      handle: 'HANDLE',
      entries: 'ENTRIES',
      enteredAt: 'ENTERED AT',
      entriesMobile: '#',
      entriesWithPicks: 'ENTRIES W/ PICKS',
      notes: 'NOTES',
      edit: 'EDIT',
    },
    pagination: {
      cta: 'Entrants per page',
      display: '{currentRange} of {total} entrants',
    },
    noData: {
      default: {
        title: 'No entries',
        subtitle: 'Invite entrants to your contest to get started.',
      },
      search: {
        title: 'No entries found',
        subtitle:
          "We didn't find any entries that matched your search. You can search for entrants by name, email and handle.",
      },
    },
  },
  modals: {
    bootEntrant: {
      title: 'Warning: Booting entrant is permanent!',
      description:
        'This entrant and all of their entries will be permanently removed from the contest. They will be prevented from joining the contest again. This action can not be undone!',
      descriptionBooted: 'This entrant has already been booted.',
      details: 'Number of Entries that will be removed: {{entries}}',
      submit: 'Boot Entrant',
      cancel: 'Cancel',
    },
    freezeEntrant: {
      title: 'Freeze Entrant',
      description: 'This will prevent entrants from making picks.',
      submit: 'Freeze',
      cancel: 'Cancel',
    },
    unfreezeEntrant: {
      title: 'Unfreeze Entrant',
      description: 'Unfreezing an entrant will allow entrant to make picks.',
      submit: 'Unfreeze',
      cancel: 'Cancel',
    },
    editModal: {
      title: 'ENTRANT INFO',
      labels: {
        name: 'Name:',
        email: 'Email:',
        handle: 'Handle:',
        notesPlaceholder: 'Add notes',
        addNotes: 'Add notes',
      },
      bootEntrant: 'Boot Entrant',
      freezeMember: 'Freeze Entrant',
      unfreezeMember: 'Unfreeze Entrant',
      cta: 'Save changes',
      errors: {
        maxLength: 'Max note length is {{maxNoteLength}} characters',
      },
    },
    download: {
      title: 'Download entrants',
      description: 'Download the list of entrants',
      descriptionLoading: 'Preparing entrants list for download...',
      cta: 'Download .csv',
      failed: {
        title: 'Download Failed',
        description: 'Failed to download entrants. Please try again.',
      },
    },
  },
  error: {
    title: 'Error',
    text: 'There was an error fetching the entrants of this contest.',
  },
  notifications: {
    entrantBooted: {
      error: 'Error booting entrant',
      message: 'Entrant booted',
    },
    entrantFrozen: {
      error: 'Error freezing entrant',
      message: 'Entrant frozen',
    },
    entrantUnfrozen: {
      error: 'Error unfreezing entrant',
      message: 'Entrant unfrozen',
    },
    successBefore: {
      message: 'Notes updating...',
    },
    successAfter: {
      message: 'Notes updated',
    },
    error: {
      title: 'Error',
      message: 'There was an error updating the notes for this entrant.',
    },
  },
};
