import type { APIAuthUserResponse, APIUserResponse, APIUserSsnStatusResponse } from './types';
import { paymentUsersInstance, usersInstance } from './users-instance';

// Returns user email
export const me = async (accessToken?: string): Promise<APIAuthUserResponse> => {
  const response = await usersInstance.get(
    '/universal-auth/me',
    accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : undefined
  );

  if (typeof window !== 'undefined' && !!window.sessionStorage) {
    sessionStorage.setItem('user', JSON.stringify(response.data.data));
  }
  return response.data;
};

// Returns name, address, verification status and current timeouts
export const meDetailed = async (): Promise<APIUserResponse> => {
  const response = await paymentUsersInstance.get('/users/me');
  return response.data;
};

interface InviteUsersPayload {
  inviteLink: string;
  contestId: string;
  // Splash
  userIds?: string[];
  contestIds?: string[];
  // RYP
  rypUserIds?: string[];
  rypPoolIds?: string[];
  // Email
  emails?: string[];
  // OFP
  ofpUserIds?: string[];
  ofpContestIds?: string[];
}

export const sendInvites = async (payload: InviteUsersPayload) => {
  const response = await usersInstance.post('/invite/contest', payload);
  return response.data;
};

export const getUserSsnStatus = async (): Promise<APIUserSsnStatusResponse> => {
  const response = await paymentUsersInstance.get('/user-ssns/status');
  return response.data;
};

type UserSsnPayload = {
  ssn: string;
};

export const postUserSsn = async (payload: UserSsnPayload) => {
  const response = await paymentUsersInstance.post('/user-ssns', payload);

  return response.data;
};
