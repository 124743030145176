import Bugsnag from '@bugsnag/js';

import log from '~/common/utils/log';
import type { User } from '~/services/users/types';
import { APIUserRole } from '~/services/users/types';

import { isBugSnagInitialised } from './initBugSnag';
import { setFullStoryUser, trackFullStoryEvent, trackFullStoryPageView } from './fullStory';
import { isSegmentInitialised } from './initSegment';
import { isGtagInitialised, reportGAevent, setGAuser } from './initGtag';
import {
  deleteUserTraits,
  getGA4SessionData,
  getFBSessionData,
  getUserTraits,
  removeAnalyticsSessionId,
  updateUserTraits,
  getFirstTouchUTM,
  getLastTouchUTM,
  setLastTouchUTM,
  parseBrandMeta,
} from './utils';
import type { AnalyticsUser } from './types';

const getAnalyticsMetaData = () => ({
  ...getGA4SessionData(),
  ...getFBSessionData(),
  first_touch_utm: getFirstTouchUTM(),
  last_touch_utm: getLastTouchUTM(),
});

// ==================== IDENTIFY ====================
const setAnalyticsUser = (user: Partial<User>) => {
  log('Analytics - Set analytics user', user);

  updateUserTraits(user);

  // Bugsnag identify
  if (isBugSnagInitialised()) {
    log('BugSnag - Set user');

    Bugsnag.setUser(`${user.id}`, user.email, user.handle);
  }

  // Fullstory identify
  setFullStoryUser({
    displayName: user.handle,
    email: user.email,
    id: user.id,
  });

  // Segment identify
  if (isSegmentInitialised()) {
    log('Segment - Identify user');

    global.analytics.identify(
      user.id,
      {
        email: user.email,
        emailVerified: user.emailVerified,
        isCommissioner: user.role === APIUserRole.COMMISSIONER,
        username: user.handle,
      },
      { analytics: getAnalyticsMetaData() }
    );
  }

  // Gtag identify
  if (isGtagInitialised()) {
    log('Analytics - Set GTM user data');
    setGAuser(user);
  }
};

const updateAnalyticsUser = (user: Partial<AnalyticsUser>) => {
  log('Segment - Update analytics user', user);
  updateUserTraits(user);

  if (isSegmentInitialised()) {
    global.analytics.identify(
      user.id,
      {
        country: user.country,
        createdAt: user.createdAt,
        geoCountry: user.geo_detected_country,
        geoState: user.geo_detected_state,
        geoStatus: user.geo_status,
        state: user.state,
        verificationStatus: user.verificationStatus,
        // We don't want to overwrite the hasFunds trait until we have the data
        ...(user.walletBallance
          ? {
              hasFunds: user.walletBallance > 0,
              walletBallance: user.walletBallance,
            }
          : {}),
      },
      { analytics: getAnalyticsMetaData() }
    );
  }
};

const clearAnalyticsUser = () => {
  log('Analytics - Clear session user');
  deleteUserTraits();
  removeAnalyticsSessionId();

  if (isBugSnagInitialised()) {
    Bugsnag.setUser(undefined);
  }

  setFullStoryUser(undefined);

  if (isSegmentInitialised()) {
    global.analytics?.user?.().traits?.({});
    global.analytics.reset();
  }
};

// ==================== TRACK & PAGE ====================
const commonProperties = {
  platform: 'web',
  version: process.env.VERSION,
  ...parseBrandMeta(),
};

const reportEvent = (name: string, properties: Record<string, unknown> = {}) => {
  const eventProperties = {
    ...properties,
    ...commonProperties,
  };
  log('Analytics - Track event', name, eventProperties);

  if (isSegmentInitialised()) {
    global.analytics.track(name, eventProperties, {
      traits: getUserTraits(),
      analytics: getAnalyticsMetaData(),
    });
  }

  trackFullStoryEvent(name, eventProperties);

  if (isGtagInitialised()) {
    reportGAevent(name, eventProperties);
  }
};

const reportPageView = (path: string, title: string) => {
  log('Analytics - Page event', path, title);

  // Store last known UTM params
  setLastTouchUTM();

  const pageProperties = {
    title,
    path,
    ...commonProperties,
  };

  if (isSegmentInitialised()) {
    global.analytics.page(pageProperties, { analytics: getAnalyticsMetaData() });
  }

  trackFullStoryPageView(pageProperties);
};

export { clearAnalyticsUser, reportEvent, reportPageView, setAnalyticsUser, updateAnalyticsUser };
