import { AUTH_PATHS } from '~/domains/auth/consts';

function getEoBrand() {
  const queryParams = new URLSearchParams(window.location.search);
  const brand = queryParams.get('brand')?.toUpperCase();
  const path = window.location.pathname;
  const isAuthPath = AUTH_PATHS.includes(path);

  return isAuthPath && (brand === 'RYP' || brand === 'OFP') ? brand : null;
}

export default getEoBrand;
