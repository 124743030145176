import { useLayoutEffect, useRef } from 'react';

export const useInfiniteScroll = ({
  fetchMore,
  hasMore,
  distance = 100,
}: {
  fetchMore: () => void;
  hasMore: boolean;
  distance?: number;
}) => {
  const scrollContainerRef = useRef();
  const loaderRef = useRef();
  const isIntersecting = useRef(false);

  useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    const scrollContainerNode = scrollContainerRef?.current;
    if (!loaderNode || !hasMore) {
      return;
    }

    const options = {
      root: scrollContainerNode ?? null,
      rootMargin: `0px 0px ${distance}px 0px`,
    };

    const listener = (entries: IntersectionObserverEntry[]) => {
      if (entries[entries.length - 1]?.isIntersecting && !isIntersecting.current) {
        isIntersecting.current = true;
        fetchMore();
      }
      if (!entries[entries.length - 1]?.isIntersecting && isIntersecting.current) {
        isIntersecting.current = false;
      }
    };

    const observer = new IntersectionObserver(listener, options);
    observer.observe(loaderNode);

    return () => observer.disconnect();
  }, [hasMore, distance, fetchMore]);

  return {
    loaderRef,
    scrollContainerRef,
  };
};
