import { ContestStatus } from '@betterpool/api-types/contests-service';

import { LocationProviderQueryKeys } from '~/components/providers/query';
import { reportEvent } from '~/domains/analytics';

import type { AccessChecksProps, ProtectionGuardType } from '../types';

function contestCheck({
  action,
  contestId,
  contestData,
  geoPermissionStatus,
  isContestLoading,
  isLocationLoading,
  isLocationRestricted,
  locationCountry,
  locationState,
  userId,

  closeModalsByType,
  handleOnAccessFailed,
  openModalUnique,
}: AccessChecksProps<ProtectionGuardType.CONTEST>) {
  if (isContestLoading || isLocationLoading) return;

  // Self-hosted & spectator mode contests are always available to all users
  const isSelfHosted = contestData?.settings?.entertainmentOnlySettings?.enabled;
  const isSpectatorMode =
    contestData?.public_viewing && contestData?.status !== ContestStatus.SCHEDULED;

  if (isSelfHosted || isSpectatorMode) {
    return true;
  }

  const detectedLocation =
    locationState &&
    locationCountry &&
    `${locationState.toUpperCase()}, ${locationCountry.toUpperCase()}`;
  const locationQueryKey = LocationProviderQueryKeys.LOCATION_TOKEN(userId, geoPermissionStatus);

  // If the contest data is not available, we will assume it is allowed in location
  const isAllowedInLocation = contestData ? contestData?.is_allowed_in_location : true;

  // Contest not allowed in location (either via external API response or contest settings)
  if (isLocationRestricted || !isAllowedInLocation) {
    openModalUnique({
      type: 'LocationRestrictionModal',
      desiredAction: 'making new entries',
      queryKey: locationQueryKey,
      detectedLocation,
    }).then(handleOnAccessFailed);

    reportEvent('ProtectionGuard > Contest Location Check Failed', {
      action,
      contestId,
      locationCountry,
      locationState,
    });

    return false;
  }
  if (isAllowedInLocation || !isLocationRestricted) {
    closeModalsByType('LocationRestrictedModal');
  }

  return true;
}

export { contestCheck };
