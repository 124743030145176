export default {
  connectedChannelHeader: {
    channelBanner: 'dt.channel.connectedChannelHeader.channelBanner',
    channelAvatar: 'dt.channel.connectedChannelHeader.channelAvatar',
    copyLinkButton: 'dt.cahnnel.connectedChannelHeader.copyLinkButton',
    editButton: 'dt.channel.connectedChannelHeader.editButton',
    channelEntriesCount: 'dt.channel.connectedChannelHeader.channelEntriesCount',
    followersButton: 'dt.channel.connectedChannelHeader.followersButton',
    channelFollowersCount: 'dt.channel.connectedChannelHeader.channelFollowersCount',
    channelFollowingCount: 'dt.channel.connectedChannelHeader.channelFollowingCount',
    followingButton: 'dt.channel.connectedChannelHeader.followingButton',
    createdTab: 'dt.channel.connectedChannelHeader.createdTab',
    enteredTab: 'dt.channel.connectedChannelHeader.enteredTab',
    upcomingButton: 'dt.channel.connectedChannelHeader.upcomingButton',
    contestStatusBar: 'dt.channel.connectedChannelHeader.contestStatusBar',
    liveButton: 'dt.channel.connectedChannelHeader.liveButton',
    recentButton: 'dt.channel.connectedChannelHeader.recentButton',
    channelName: 'dt.channel.connectedChannelHeader.channelName',
    channelHandle: 'dt.channel.connectedChannelHeader.channelHandle',
    channelDescription: 'dt.channel.connectedChannelHeader.channelDescription',
    followButton: 'dt.channel.connectedChannelHeader.followButton',
  },
  channelContests: {
    channelContestsEmptyState: 'dt.channel.channelContests.channelContestsEmptyState',
  },
  search: {
    tabs: 'dt.channel.search.tabs',
    followingTab: 'dt.channel.search.followingTab',
    followersTab: 'dt.channel.search.followersTab',
    searchInputfield: 'dt.channel.search.searchInputfield',
    emptyState: 'dt.channel.search.emptyState',
    followersEmptyState: 'dt.channel.search.tyState',
    searchResult: 'dt.channel.search.searchResult',
  },
  channelDetailPage: {
    createaContestButton: 'dt.channel.channelDetailPage.createaContestButton',
  },
  channelEditPage: {
    nameInput: 'dt.channel.channelEditPage.nameInput',
    handleInput: 'dt.channel.channelEditPage.handleInput',
    descriptionInput: 'dt.channel.channelEditPage.description',
    saveChangesButton: 'dt.channel.channelEditPage.saveChangesButton',
  },
  invitesManager: {
    connectedInviteEmails: 'dt.channel.invitesManager.connectedInviteEmails',
    connectedInvitesData: 'dt.channel.invitesManager.connectedInvitesData',
    rypTabCheckBoxAll: 'dt.channel.invitesManager.rypTabCheckBoxAll',
    rypSendNewInviteButton: 'dt.channel.invitesManager.rypSendNewInviteButton',
    invitesMangerUnavailable: 'dt.channel.invitesManager.invitesMangerUnavailable',
    rypDescription: 'dt.channel.invitesManager.rypDescription',
    linkRypButton: 'dt.channel.invitesManager.linkRypButton',
    rypTitle: 'dt.channel.invitesManager.rypTitle',
    rypLogo: 'dt.channel.invitesManager.rypLogo',
    rypTab: 'dt.channel.invitesManager.rypTab',
    ofpSendNewInviteButton: 'dt.channel.invitesManager.ofpSendNewInviteButton',
    ofpDescription: 'dt.channel.invitesManager.ofpDescription',
    linkOfpButton: 'dt.channel.invitesManager.linkOfpButton',
    ofpTitle: 'dt.channel.invitesManager.ofpTitle',
    ofpLogo: 'dt.channel.invitesManager.ofpLogo',
    ofpTab: 'dt.channel.invitesManager.ofpTab',
    emailEmptyState: 'dt.channel.invitesManager.emailEmptyState',
    groupsEmptyState: 'dt.channel.invitesManager.groupsEmptyState',
    followersEmptyState: 'dt.channel.invitesManager.followersEmptyState',
    tabsCheckBox: 'dt.channel.invitesManager.tabsCheckBox',
    groupsTabCheckBoxAll: 'dt.channel.invitesManager.groupsTabCheckBoxAll',
    invitesTableEmptyState: 'dt.channel.invitesManager.invitesTableEmptyState',
    IconChevronLeft: 'dt.channel.invitesManager.IconChevronLeft',
    reviewInvitesFollowersCell: 'dt.channel.invitesManager.reviewInvitesFollowersCell',
    followersTabCheckBoxAll: 'dt.channel.invitesManager.followersTabCheckBoxAll',
    followersTabCheckBox: 'dt.channel.invitesManager.followersTabCheckBox',
    inviteFollowers: 'dt.channel.invitesManager.inviteFollowers',
    textInputField: 'dt.channel.invitesManager.textInputField',
    importButton: 'dt.channel.invitesManager.importButton',
    reviewInviteButton: 'dt.channel.invitesManager.reviewInviteButton',
    tableCellRight: 'dt.channel.invitesManager.tableCellRight',
    trashIcon: 'dt.channel.invitesManager.trashIcon',
    emptyState: 'dt.channel.invitesManager.emptyState',
    clearButton: 'dt.channel.invitesManager.clearButton',
    emailInvitesNumber: 'dt.channel.invitesManager.emailInvitesNumber',
    sendInviteButton: 'dt.channel.invitesManager.sendInviteButton',
    invitesMangerTabs: 'dt.channel.invitesManager.invitesMangerTabs',
    InvitesManagerFollowersTab: 'dt.channel.invitesManager.InvitesManagerFollowersTab',
    linkTab: 'dt.channel.invitesManager.linkTab',
  },
};
