import { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showNotification } from '@mantine/notifications';
import { isAxiosError } from 'axios';
import type { Translate } from 'next-translate';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { FEATURE } from '~/common/enums/feature.enum';
import { KYCContext } from '~/components/providers/KYCProvider';
import { LocationContext } from '~/components/providers/LocationProvider';
import { reportEvent } from '~/domains/analytics';
import { ExternalLink } from '~/domains/common/components/Link/Link';
import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import { bugSnagLog } from '~/utils/bugsnag';
import { UserContext } from '~/components/providers/UserProvider';
import { ContestQueryKeys } from '~/domains/contest/constants/query';
import useProtectedGuard from '~/domains/protectionGuards/hooks/useProtectedGuard';
import { ProtectionGuardType } from '~/domains/protectionGuards/types';

import { createEntry } from '../../services/entries.service.api';

const CONTEST_QUERY_KEYS = ['contests', 'entriesForCurrentUser'];

const BEGINNER_CONTEST_ERROR = 'CONTEST_FOR_BEGINNERS_ONLY';

const isBeginnerContestError = (error: unknown): boolean =>
  isAxiosError(error) && error.response?.data?.error === BEGINNER_CONTEST_ERROR;

const parseResponseError = (error: unknown, t: Translate) => {
  if (isBeginnerContestError(error)) {
    return {
      title: t('enterContest.error.beginnerOnly.title'),
      message: (
        <Trans
          i18nKey="contest:enterContest.error.beginnerOnly.message"
          components={{
            legalLink: (
              <ExternalLink
                href="https://legal.splashsports.com/legal/splash-sports-house-rules"
                target="_blank"
              />
            ),
          }}
        />
      ),
    };
  }

  return {
    title: t('enterContest.error.default.title'),
    message: t('enterContest.error.default.message'),
  };
};

const useCreateOrJoinEntry = (_contestId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('contest');
  const { user } = useContext(UserContext);

  const { refetch: getUserBalance } = useWalletBalance();
  const { locationStatus } = useContext(LocationContext);
  const { KYCStatus } = useContext(KYCContext);

  const isLocationCheckOnLoginEnabled = useIsFeatureEnabled(FEATURE.ENABLE_LOCATION_CHECK_ON_LOGIN);
  const disableDepositAndJoin = !useIsFeatureEnabled(
    FEATURE.ENABLE_CONTEST_DETAILS_JOIN_AND_DEPOSIT_BUTTONS
  );

  // PROTECTION GUARD (KYC, location status, etc)
  const { isLoading: isProtectionLoading, runProtectionChecks } = useProtectedGuard({
    action: 'create an entry',
    protectionLevels: [
      ProtectionGuardType.KYC,
      ProtectionGuardType.LOCATION,
      ProtectionGuardType.CONTEST,
    ],
    contestId: _contestId,
  });

  const handleJoinThisContestClick = useCallback(
    async ({
      contestId,
      entryFee,
      entriesCount,
      callback,
      suppressError,
      isEntertainmentOnly,
    }: {
      contestId: string;
      entryFee: number;
      entriesCount: number;
      callback?: (entryIDs?: string[]) => unknown;
      suppressError?: boolean;
      isEntertainmentOnly?: boolean;
    }) => {
      if (contestId && !isEntertainmentOnly) {
        const isAllowed = await runProtectionChecks();

        if (!isAllowed) {
          if (!suppressError) {
            throw new Error('Protection Checks Failed');
          }
          return;
        }
      }

      try {
        const entryIDs = await createEntry(
          {
            contestId,
            entriesCount,
          },
          {
            skipLocationValidation: isEntertainmentOnly,
          }
        );

        reportEvent('Contest > Join Now', {
          contest_id: contestId,
          entry_ids: entryIDs,
          entry_fee: entryFee,
          entries_count: entriesCount,
          entry_fee_usd: entryFee / 100,
        });

        await getUserBalance();

        callback?.(entryIDs);
        return entryIDs;
      } catch (entryError) {
        const { title, message } = parseResponseError(entryError, t);

        showNotification({
          title,
          message,
          color: 'red',
        });

        reportEvent('Contest > Join Not Allowed', {
          contest_id: contestId,
          entry_fee: entryFee,
          entry_fee_usd: entryFee / 100,
          entries_count: entriesCount,
          reason: 'API Error',
          error: entryError,
        });

        bugSnagLog({
          context: 'Contest Join Error',
          severity: 'warning',
          errorMessage: title,
          errorDetails: { entryError },
          details: {
            title,
            message,
            contestId,
            caught: 'Yes',
          },
        });

        if (!suppressError) {
          throw entryError;
        }
      } finally {
        await Promise.all([
          ...CONTEST_QUERY_KEYS.map((queryKey) =>
            Promise.all([
              queryClient.invalidateQueries({ queryKey: [queryKey, contestId, user?.id] }),
            ])
          ),
          queryClient.invalidateQueries(
            ContestQueryKeys.ENTRIES_FOR_CURRENT_USER({ contestId, userId: user?.id })
          ),
        ]);
        await queryClient.invalidateQueries(
          ContestQueryKeys.ENTRIES_CURRENT_USER_WITH_PICKS({ contestId, userId: user?.id })
        );
      }
    },
    [
      KYCStatus,
      getUserBalance,
      isLocationCheckOnLoginEnabled,
      locationStatus,
      queryClient,
      t,
      user?.id,
    ]
  );

  const { mutateAsync, isLoading, isSuccess, error } = useMutation({
    mutationKey: ['createOrJoinEntry'],
    mutationFn: ({
      contestId,
      entryFee,
      entriesCount,
      callback,
      suppressError,
      isEntertainmentOnly,
    }: {
      contestId: string;
      entryFee: number;
      entriesCount?: number;
      callback?: (entryIDs: string[]) => unknown;
      suppressError?: boolean;
      isEntertainmentOnly?: boolean;
    }) =>
      handleJoinThisContestClick({
        contestId,
        entryFee,
        entriesCount,
        callback,
        suppressError,
        isEntertainmentOnly,
      }),
  });

  return {
    handleCreateOrJoinContest: mutateAsync,
    isCreateorJoinButtonDisabled: disableDepositAndJoin,
    isLoading: isProtectionLoading || isLoading,
    isSuccess,
  };
};

export default useCreateOrJoinEntry;
