import type { PropsWithChildren } from 'react';

import { SplashLoaderOverlay } from '../components/SplashLoaderOverlay/SplashLoaderOverlay';
import useUnifiedLogin from '../hooks/useUnifiedLogin';

function SingleUseTokenProvider({ children }: PropsWithChildren) {
  const { isLoading } = useUnifiedLogin();

  if (isLoading) return <SplashLoaderOverlay />;

  return children;
}

export default SingleUseTokenProvider;
