import { Card, Group, MantineProvider, Select, Stack, createStyles } from '@mantine/core';
import { useColorScheme, useDisclosure, useHotkeys } from '@mantine/hooks';
import { IconTrafficCone } from '@tabler/icons-react';
import { useContext } from 'react';

import { LocationContext } from '~/components/providers/LocationProvider';
import { FEATURE } from '~/common/enums/feature.enum';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import { Title } from '../common/components/Title/Title';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing.xl,
    zIndex: 1000,
    transition: 'transform 300ms ease',
    transform: `translateY(calc(100% + ${theme.spacing.xl}px))`,
    overflow: 'visible',

    '&.isOpened': {
      transform: 'translateY(0)',
    },
  },
}));

export function Debug() {
  const { classes, cx } = useStyles();
  const [opened, handlers] = useDisclosure();
  const {
    isLoading: isLocationLoading,
    locationCountry,
    setLocationCountryNameOverride,
  } = useContext(LocationContext);
  const isFeatureEnabled = useIsFeatureEnabled(FEATURE.ENABLE_DEBUG_MENU);

  const colorScheme = useColorScheme();

  useHotkeys([
    [
      'mod+K',
      () => {
        if (!isFeatureEnabled) return;

        handlers.toggle();
      },
    ],
  ]);

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <MantineProvider inherit theme={{ colorScheme, globalStyles: undefined }}>
      <Card className={cx(classes.container, { isOpened: opened })} shadow="sm">
        <Stack>
          <Title tag="h4">
            <Group spacing="xs">
              <IconTrafficCone />
              Debug
            </Group>
          </Title>
          <Select
            value={locationCountry?.toLowerCase()}
            label="Location"
            description="This will only affect Strapi content"
            placeholder={isLocationLoading ? 'Loading...' : 'Select location'}
            data={[
              { value: 'czechia', label: 'Czechia' },
              { value: 'united states', label: 'United States' },
              { value: 'canada', label: 'Canada' },
            ]}
            disabled={isLocationLoading}
            onChange={setLocationCountryNameOverride}
          />
        </Stack>
      </Card>
    </MantineProvider>
  );
}
