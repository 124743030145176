'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { startFullStoryCapture } from './fullStory';
import { reportPageView } from './events';

function isPathInConfig(url: string, config: string[]) {
  if (!config || !config?.length) return false;

  return config.some((path) => url.match(path));
}

const useReportLocationChange = (fullStoryPaths?: string[]) => {
  const router = useRouter();

  // Report first page render
  useEffect(() => {
    // Used for Customer Support to force FS capture on a specific session
    // Path query param: `?fullstory=true`
    if (router.query.fullstory === 'true') {
      startFullStoryCapture('forced capture');
    }

    if (isPathInConfig(window.document.location.pathname, fullStoryPaths)) {
      startFullStoryCapture('specific path recording (first render)');
    }

    reportPageView(window.document.location.pathname, window.document.title);
  }, []);

  // Report future route changes
  useEffect(() => {
    const reportRouteChange = (url) => {
      if (isPathInConfig(url, fullStoryPaths)) {
        startFullStoryCapture('specific path recording (route change)');
      }

      reportPageView(url, window.document.title);
    };

    router.events.on('routeChangeStart', reportRouteChange);

    return () => {
      router.events.off('routeChangeStart', reportRouteChange);
    };
  }, [router, fullStoryPaths]);
};

export default useReportLocationChange;
