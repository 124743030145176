import type { AccessChecksProps } from '../types';
import { ProtectionGuardType } from '../types';

import { kycCheck, userCheck } from './userChecks';
import { locationCheck } from './locationChecks';
import { contestCheck } from './contestChecks';
/**
 *Map of protection levels to their check functions
 */
const accessChecksValidators = {
  [ProtectionGuardType.USER]: userCheck,
  [ProtectionGuardType.KYC]: kycCheck,
  [ProtectionGuardType.LOCATION]: locationCheck,
  [ProtectionGuardType.CONTEST]: contestCheck,
};

/**
 * @param {ProtectionGuardType[]} protectionLevels - The protection levels to check. Order is important.
 * @param {AccessChecksProps} props - The props to pass to the check functions
 * @returns {boolean} True if all checks pass, false otherwise
 */
function runAccessCheck(
  protectionLevels: ProtectionGuardType[],
  props: AccessChecksProps<ProtectionGuardType.USER> &
    AccessChecksProps<ProtectionGuardType.KYC> &
    AccessChecksProps<ProtectionGuardType.LOCATION> &
    AccessChecksProps<ProtectionGuardType.CONTEST>
) {
  // Anonymous user bypasses all checks when allowed
  if (!props.userId && props.allowAnnonymous) {
    return true;
  }

  return protectionLevels.every((protectionLevel) =>
    accessChecksValidators[protectionLevel](props)
  );
}

export default runAccessCheck;
