import { ActionIcon, type ActionIconProps } from '@mantine/core';
import { useMantineTheme } from '@mantine/core';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { IconChevronLeft } from '~/domains/design-system/icons/IconChevronLeft';

type BackButtonProps = {
  onClick: () => void;
} & Partial<ActionIconProps>;

export function BackButton({ onClick, ...props }: BackButtonProps) {
  const theme = useMantineTheme();
  const isMobile = useIsMobile();

  return (
    <ActionIcon
      onClick={onClick}
      {...(!isMobile ? { pos: 'absolute', top: 0, left: -theme.other.spacing._32 * 2 } : {})}
      h={32}
      w={32}
      {...props}
    >
      <IconChevronLeft size={32} />
    </ActionIcon>
  );
}
