export default {
  header: {
    close: 'Close',
    startsAt: 'Starts: {{startDate}}',
    entriesAnon: 'Entries',
    entries: 'Entries: {{filled}}/{{max}}',
    entry: 'Entry',
    prizes: 'Prizes',
  },
  slatesNav: {
    slateDate: {
      tomorrow: 'Tomorrow {{formattedDate}}',
      today: 'Today {{formattedDate}}',
    },
    segment: 'Segment {{index}}',
  },
  picksBar: {
    submitLabelDefault: 'Save picks',
    submitting: 'Saving...',
    depositToSubmit: 'Deposit to save picks',
    picksMade: '{{picksMade}}/{{requiredPicksCount}} picks made',
    signInToSubmit: 'Log in to save picks',
    createNewEntry: 'Create entry: {{entryFee}}',
    createNewEntryEntertainmentOnly: 'Create entry',
  },
  common: {
    makePicks__hasPlural: {
      one: 'Make pick',
      other: 'Make picks',
    },
    editPicks__hasPlural: {
      one: 'Edit pick',
      other: 'Edit picks',
    },
    picksheetNotReady: 'Picksheet not yet available',
    action: {
      dismiss: 'Dismiss',
    },
    errorSubmittingPicks: {
      title: 'There was an error while saving your picks.',
      defaultMessage: 'Unknown error',
    },
    entriesScheduled: {
      myEntries: 'My entries',
      entryFee: 'Entry',
      maxEntries: 'Max',
    },
    entryScheduled: {
      edit: 'Edit',
      withdraw: 'Withdraw',
    },
    unknownUser: 'Unknown User',
    entryDetail: {
      title: 'Entry details',
      noPicksVisibleYet:
        'No picks are visible yet. All picks will be visible once the picks will be locked for this slate.',
      noPicks: 'This entry has no picks for this slate.',
      slateSublabel: {
        final: 'FINAL',
        live: 'LIVE',
      },
    },
    standings: {
      winLoss: 'W-L',
      points: 'Points',
      empty: {
        beforeStart: 'The entries for this slate will be visible after the slate start',
        afterStart: 'There are no entries for this slate',
      },
      overall: 'Overall',
      segments: 'Segments',
      bySlateDefault: 'Slate',
      segmentItem: 'Segment {{index}}',
      pointsAbbr: 'Pts',
      potentialPointsAbbr: 'Potential',
      entry: {
        errorLoadingDetails: 'Error loading details',
        retry: 'Retry',
      },
    },
    slatesNav: {
      points__hasPlural: {
        one: '{{count}} point',
        other: '{{count}} points',
      },
    },
    availability: {
      nav: {
        available: 'Available',
        unavailable: 'Unavailable',
      },
      entry: {
        viewAll: 'View all',
        noTeams: 'No teams',
        noPlayers: 'No players',
      },
      banner: {
        updatesNotFinal: 'Updates when current {{slateLabel}} is final',
        contestFinal:
          'This contest is finalized. The availability tab shows data from the last {{slateLabel}}.',
        playerTierSlateNotGenerated: 'Updates when next {{slateLabel}}’s player pool is generated',
      },
      entryDetail: {
        available: {
          subtitle: 'Availability for {{slateName}}',
        },
        unavailable: {
          title: 'Unavailable {{items}}: {{entryName}}',
        },
      },
      report: {
        banner: {
          title: 'Availability for:',
          button: 'View availability report',
        },
        title: 'Availability report',
        tableHead: {
          team: 'Team',
          available: 'Available this slate',
        },
        noData: 'No data',
      },
      metrics: {
        projectedFantasyPoints: 'PROJ: {{value}}',
        datagolf_rank: 'Rank: {{value}}',
        world_rank: 'Rank: {{value}}',
        unknown: '{{value}}',
      },
      pickCount: 'Picked: {{pickCount}}/{{picksReuseLimit}}',
    },
    accessError: {
      actions: {
        viewMyEntries: 'View my entries',
        backToHome: 'Back to home',
      },
      maxEntriesReached: {
        title: 'Max entries reached',
        description: 'You have reached the maximum number of entries for this contest',
      },
      invalidEntry: {
        title: 'Invalid entry',
        description: 'You have followed an invalid link',
      },
      unauthViewingNonFirstSlate: {
        title: 'Slate unavailable',
        description:
          'You need to sign in to view other slates. Please sign in or go to the first slate.',
        signIn: 'Sign in',
        goToFirstSlate: 'View the first slate',
      },
    },
    notification: {
      noChanges: 'You have not made any changes to your picks.',
    }
  },
  team: {
    bye: '(BYE)',
    pointsInfo: {
      points: 'Points:',
      potentialPoints: 'Potential points:',
    },
    game: {
      footer: {
        bestPick: 'Best pick',
        confidenceScore: 'Confidence points:',
      },
      header: {
        finalized: {
          title: 'FINAL',
        },
        live: {
          title: 'LIVE',
        },
        scheduled: {
          preview: 'Preview',
        },
        missedPick: 'Missed pick',
      },
      team: {
        alreadyPicked: 'Already picked',
        winProbablity: 'Win prob: {{winProbablity}}%',
      },
    },
    picksList: {
      sortBy: {
        startDate: 'Start Time',
        seed: 'Seed',
      },
    },
    picksheetNotAvailableYet: {
      title: "You're early",
      subtitle: 'This pick sheet is not yet available.<br></br>Check again later.',
    },
    notification: {
      picksLimitMet:
        "You have all of your picks made for this slate. If you'd like to change your picks you must remove them.",
      pickTeamBeforeBestPick: 'You must select a team before setting it as your best pick.',
    },
    pickEm: {
      picksBar: {
        close: 'Close',
        gameStatus: {
          cancelled: 'Cancelled',
          final: 'Final',
          inProgress: 'Live',
        },
        noPick: 'No pick',
        missedPick: 'Missed pick',
      },
      entryOverall: {
        week: 'Week',
        segment: 'Segment',
        winLoss: 'W-L',
        points: 'Points',
      },
      stats: {
        notAvailable: {
          title: 'Week not started',
          description: {
            game: 'The stats tab will populate as each game starts.',
            slate: 'The stats tab will populate after the pick deadline.',
          },
        },
      },
    },
    survivor: {
      stats: {
        subNav: {
          slateSummary: '{{slateLabel}} summary',
          availability: 'Team availability',
        },
        summaryLabels: {
          survivors: 'Survivors',
          eliminated: 'Eliminated',
          missedPick: 'Missed pick',
        },
        notAvailable: {
          title: '{{slateLabel}} not started',
          description: {
            game: 'The stats tab will populate as each game starts.',
            slate: 'The stats tab will populate after the pick deadline.',
            common:
              'To see the teams available for each entry and the number of entries that can select each team for the upcoming week, go to the <link>Availability tab</link>.',
          },
        },
        overallStats: {
          banner: {
            title: 'Overall stats',
            link: 'View',
          },
          modal: {
            title: 'Overall contest stats',
            totalEntries: 'Total entries:',
            totalSurviving: 'Total surviving',
            totalEliminated: 'Total eliminated',
            survivingWithLives__hasPlural: {
              one: 'Surviving with {{count}} life',
              other: 'Surviving with {{count}} lives',
            },
            entries__hasPlural: {
              one: 'entry',
              other: 'entries',
            },
          },
        },
      },
    },
    stats: {
      notAvailable: {
        slateSummary:
          '{{slateLabel}} pick summaries are not available until after the first game of the week has started or the weekly pick deadline has passed',
        availability: 'Available teams will show once the previous week is finalized',
        viewEntries: 'View Entries',
      },
      listColumns: {
        pickedPercentage: '%Picked',
        pickedCount: '#Picked',
        availableCount: 'Available this slate',
        confidenceAverage: 'Avg Pts',
      },
      summaryValueLabels: {
        entries: 'Entries',
        percentage: 'Percentage',
      },
      pickDeadlineBanner: {
        game: 'Games will appear after they start',
        slate: 'All games will appear after the pick deadline',
      },
    },
    leadeboardWidget: {
      myEntries: 'My Entries:',
      mySurvivors: 'My entries alive:',
      entered: 'Entered:',
      entry: 'Entry:',
      won: 'Won:',
      winning: 'Winning:',
      suvivors: 'Alive:',
      tooltip__hasPlural: {
        one: 'Entry {{index}}: {{count}} pt',
        other: 'Entry {{index}}: {{count}} pts',
      },
    },
    gameStats: {
      leagueNotSupported: 'Matchup screen not available for this league',
      odds: {
        title: 'Odds',
        attributes: {
          spread: 'Spread',
          moneyline: 'Moneyline',
          win_probability: 'Implied win probablity',
        },
      },
      overview: {
        title: {
          nfl: 'Matchup',
          ncaab: 'Regular season stats',
          ncaaf: 'Matchup',
        },
        attributes: {
          ppg: 'Points per game',
          pa: 'Points against',
          fieldGoalPercentage: 'Field Goal %',
          reboundsPerGame: 'Rebounds per game',
          assistsPerGame: 'Assists per game',
          blocksPerGame: 'Blocks per game',
          stealsPerGame: 'Steals per game',
        },
      },
      injuries: {
        title: 'Injuries',
        status: {
          Questionable: 'Quest',
          Doubtful: 'Doub',
          Out: 'Out',
        },
      },
      stats: {
        lastGames_title: 'Last {{ count }} games',
        lastMeetings_title: 'Last {{ count }} meetings',
        win: 'W',
        lose: 'L',
      },
    },
    accessError: {
      actions: {
        viewMyEntries: 'View my entries',
        backToHome: 'Back to home',
      },
      maxEntriesReached: {
        title: 'Max entries reached',
        description: 'You have reached the maximum number of entries for this contest',
      },
      invalidEntry: {
        title: 'Invalid entry',
        description: 'You have followed an invalid link',
      },
    },
    entryDetail: {
      noPicksVisibleYet:
        'No picks are visible yet. All picks will be visible once the picks will be locked for this slate.',
      noPicks: 'This entry has no picks for this slate.',
      slateSublabel: {
        final: 'FINAL',
        live: 'LIVE',
      },
    },
  },
  player: {
    common: {
      entryScheduled: {
        missingPicks: 'Undrafted lineup',
        playerPoolNotReady: 'Player pool not yet available',
        injuredPlayer: 'Entry with injuries',
      },
      selectPlayer__hasPlural: {
        one: 'Select player',
        other: 'Select players',
      },
      dropWorstCount__hasPlural: {
        one: 'Worst player score is being dropped',
        other: '{{count}} worst player scores are being dropped',
      },
      playerCard: {
        upcomingPlaceholder: 'shown on game start',
        emptyPick: 'Select',
        dropWorstNth__hasPlural: {
          one: 'Worst',
          other: '{{ordinal}} worst',
        },
      },
      playerPoolNotReady: {
        title: 'Hang Tight!',
        subtitle: 'The player pool for this slate will be generated in {{time}}',
        subtitleNoTime: 'The player pool for this slate will be generated soon.',
      },
      slateBanner: {
        players: 'Players',
        points__hasPlural: {
          one: '{{count}}pt',
          other: '{{count}}pts',
        },
      },
      submit: {
        success: 'Your Lineup has been submitted.',
        error: 'Your Lineup has not been submitted. Please try again.',
      },
      entryDetail: {
        undraftedLineup: {
          title: 'No lineup submitted',
          button: 'Draft lineup',
        },
      },
      playerPool: {
        locked: {
          currentPick: "This player is locked. Your pick's competition has started",
          otherPick: 'This player is locked. Their competition has started',
        },
      },
      playerDetail: {
        actions: {
          draft: 'Draft',
          remove: 'Remove player',
          swap: 'Swap player',
        },
      },
    },
    tier: {
      entryActions: {
        clear: 'Clear',
        submit: 'Save lineup',
      },
      picksCountBanner: {
        picksCount: '{{picksCount}}/{{expectedPicksCount}} players drafted',
      },
      headerMenu: {
        withdraw: 'Withdraw',
      },
      standings: {
        overall: {
          labels: {
            earnings: 'Earnings',
            fpts: 'FPTS',
            score: 'Score',
          },
        },
      },
    },
  },
  spreadDeadlineBar: {
    lock: 'Spread locks: {{date}}',
    finalized: 'Spread finalized',
  },
  picksDeadlineBar: 'Picks lock: {{label}}',
  makeYourPicksBar: 'Make your picks',
  modal: {
    discardChangesModal: {
      title: 'Unsaved picks',
      description: 'You have unsaved picks. Are you sure you want to discard them?',
      submit: 'Discard',
      cancel: 'Cancel',
    },
    missingBestPickSaveModal: {
      title: 'No best pick',
      description:
        'You have not selected a Best Pick. Are you sure you want to save your picks withou a Best Pick?',
      submit: 'Save picks',
      cancel: 'Continue making picks',
    },
    incompletePicksSaveModal: {
      title: 'Missing picks',
      description:
        'Some games are missing picks. Be sure to make all of your picks before the pick deadline.',
      submit: 'Save picks',
      cancel: 'Continue making picks',
    },
    spreadNotSet: {
      title: 'Spread not set',
      description:
        'You have selected a team where the spread has not been set. Are you sure you want to save your picks? When the spread is set, your pick will not change.',
      submit: 'Save picks',
      cancel: 'Cancel',
    },
    withdrawEntryModal: {
      title: 'Are you sure you want to withdraw this entry ?',
      description: 'By withdrawing your entry the entry fee will be credited back to your account.',
      submit: 'Withdraw',
      cancel: 'Cancel',
      error: {
        title: 'Unable to make a withdrawal to this entry.',
        message: 'Please try again.',
      },
      success: {
        message: 'Withdrawal was successfull.',
      },
    },
    withdrawEOEntryModal: {
      title: 'Are you sure you want to withdraw this entry ?',
      description:
        'By withdrawing your entry, your entry will no longer be eligible to compete in this contest.',
      submit: 'Withdraw',
      cancel: 'Cancel',
      error: {
        title: 'Unable to make a withdrawal to this entry.',
        message: 'Please try again.',
      },
      success: {
        message: 'Withdrawal was successfull.',
      },
    },
    clearAllPicksModal: {
      title: 'Clear all picks',
      description: 'Are you sure you want to clear all of your picks?',
      submit: 'Clear',
      cancel: 'Cancel',
    },
    postPickModal: {
      missingPicks: {
        title: 'You’re not done!',
        subtitle: 'You’ve made {{picksCount}}/{{expectedPicksCount}} picks for this slate',
      },
      title__hasPlural: {
        one: "You've made your pick!",
        other: "You've made your picks!",
      },
      addNewEntries: 'Add new entries',
      viewMyEntries: 'Back to my entries',
      makeTheRestOfPicks: 'Make the rest of my picks',
      continueMakingPicks: 'Keep making picks',
    },
  },
  layoutNonScheduled: {
    nav: {
      entries: 'My entries',
      standings: 'Standings',
      stats: 'Stats',
      detail: 'Details',
      availability: 'Availability',
    },
  },
  editSettingsModal: {
    title: 'Contest settings changed',
    description:
      'The commissioner has changed the following settings of this contest. If you would like to withdraw, you can do so on the Entry Details screen.',
  },
  editNameEditModal: {
    title: 'Edit entry name',
    field: 'Entry name',
    submit: 'Save',
    errors: {
      min: "Entry name can't be empty",
      max: "Entry name can't be longer than 30 characters",
      regex: 'Only letters, numbers, hyphen, accented characters and spaces are allowed',
    },
  },
};
