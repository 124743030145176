import useTranslation from 'next-translate/useTranslation';
import type { CheckRequestError } from '@xpointtech/xpoint-js';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { Button } from '~/domains/design-system/Button';
import IconLocationOff from '~/domains/design-system/icons/IconLocationOff';

import StatusModalBody from './components/StatusModalBody';

function LocationErrorModal({
  isOpen,
  onClose,
  locationErrors,
  queryKey,
}: {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
  locationErrors?: CheckRequestError[];
  queryKey: string[];
}) {
  const { t } = useTranslation('contest');

  // We have to specifically use useIsFetching to get the correct value in time, prop drilling doesn't work
  const isFetching = useIsFetching({ queryKey });
  const queryClient = useQueryClient();

  const handleRecheckLocationClick = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return (
    <StatusModalBody
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconLocationOff size={40} />}
      title={t('common.userStatusModals.locationError.title')}
      text={t('common.userStatusModals.locationError.text')}
      text2={locationErrors?.map((error) => error.description).join('. ')}
      cta={
        <Button
          variant="secondary-fill"
          fullWidth
          onClick={handleRecheckLocationClick}
          loading={isFetching > 0}
        >
          {t('common.userStatusModals.common.tryAgainCta')}
        </Button>
      }
    />
  );
}

export default LocationErrorModal;
