import type { PropsWithChildren } from 'react';
import React, { useContext } from 'react';
import { IconAlarm } from '@tabler/icons-react';
import { AppShell, MantineProvider, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { Button } from '~/domains/design-system/Button';
import { Footer } from '~/components/shared/Footer/Footer';
import { Header } from '~/domains/common/components/Header/Header';
import { UserContext } from '~/components/providers/UserProvider';
import useLogout from '~/hooks/useLogout';

import { FullScreenMessage } from '../../../common/components/FullScreenMessage/FullScreenMessage';
import { SplashLogo } from '../../../../components/shared/Logos/SplashLogo';

export function TimeoutWrapper({ children }: PropsWithChildren) {
  const { t } = useTranslation('app');
  const theme = useMantineTheme();
  const { userDetails } = useContext(UserContext);
  const { handleLogout, isLoggingOut } = useLogout();

  const isTimeout = userDetails?.timeout;

  if (!isTimeout) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  const timeoutDate = new Date(userDetails?.timeout?.expiresAt).toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <>
      <AppShell
        padding={0}
        header={
          <Header>
            <SplashLogo />
          </Header>
        }
        styles={{ main: { padding: 0, minHeight: '95vh' } }}
      >
        <MantineProvider inherit theme={{ colorScheme: 'light' }}>
          <FullScreenMessage
            icon={<IconAlarm color={theme.colors.red[5]} />}
            title={t('timeoutWrapper.title')}
            subtitle={t('timeoutWrapper.subtitle', { timeoutDate })}
            actions={
              <Button
                variant="primary-fill"
                size="large"
                disabled={isLoggingOut}
                onClick={() => handleLogout()}
                loading={isLoggingOut}
              >
                {t('timeoutWrapper.signOut')}
              </Button>
            }
          />
        </MantineProvider>
      </AppShell>
      <Footer isRestricted />
    </>
  );
}
