'use client';

import { useEffect } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import Braze from '~/domains/analytics/braze/braze.class';
import { useTreatment } from '~/domains/common/hooks/useTreatment';
import { isEoBrand } from '~/utils/isEoBrand';
import { isBrowserFn } from '~/utils/browser';

import { clearAnalyticsUser, reportEvent, setAnalyticsUser, updateAnalyticsUser } from './events';
import initGtag from './initGtag';
import { initSegment, stopSegment } from './initSegment';
import { startFullStoryCapture, stopFullStoryCapture } from './fullStory';
import { FS_VISIBILITY, useFullStoryRef } from './fullStory/useFullStoryRef';
import { initBugSnag, stopBugSnag } from './initBugSnag';
import useReportLocationChange from './useReportLocationChange';
import { getAnonymousId, getStoredUser, setFirstTouchUTM, setWebViewCookie } from './utils';

function initAnalytics() {
  if (isBrowserFn()) {
    const anonymousId = getAnonymousId();

    // Parse first touch UTM parameters on load and set a webview cookie (fallback if webview redirect loses the url param)
    setFirstTouchUTM();
    setWebViewCookie();

    initBugSnag();
    initSegment(anonymousId);
    initGtag();
    Braze.start();
  }
}

function stopAnalytics() {
  if (isBrowserFn()) {
    stopBugSnag();
    stopFullStoryCapture();
    stopSegment();
    Braze.stop();
  }
}

const useAnalytics = () => {
  const isEOuser = isEoBrand();
  const { isReady, treatment } = useTreatment(FEATURE.CONFIG_FULLSTORY_CAPTURE);
  const fullStoryTreatment = treatment?.treatment as 'on' | 'off' | 'partial';
  const fullStoryConfig = treatment?.config as string[];

  // FS Path config (start record only on specific paths) available only on "partial" treatment
  const fullStoryPaths = fullStoryTreatment === 'partial' ? fullStoryConfig : undefined;

  // Location analytics reporting & partial FullStory capture
  useReportLocationChange(fullStoryPaths);

  // Static analytics initialisation
  useEffect(() => {
    initAnalytics();
    return () => {
      stopAnalytics();
    };
  }, []);

  // FF control initialisation for Fullstory
  useEffect(() => {
    // Never initialise Fullstory for EO auth pages
    if (isEOuser) return;

    if (isReady && fullStoryTreatment === 'on') startFullStoryCapture('full session recording');
  }, [isEOuser, isReady, fullStoryTreatment]);
};

export default useAnalytics;
export {
  FS_VISIBILITY,
  clearAnalyticsUser,
  reportEvent,
  setAnalyticsUser,
  updateAnalyticsUser,
  getAnonymousId,
  getStoredUser,
  initAnalytics,
  isBrowserFn,
  stopAnalytics,
  useFullStoryRef,
  useReportLocationChange,
};
