import { createStyles } from '@mantine/core';
import { useMemo } from 'react';
import packageJSON from '~/../package.json';
import { titleStyles } from '~/domains/design-system/Title';

const useStyles = createStyles((theme, { env }: { env: string }) => ({
  envLabel: {
    position: 'absolute',
    top: 4,
    marginLeft: 110,
    color: theme.colors.gray[4],
    ...titleStyles['subhead-x-small'],

    [theme.fn.smallerThan('md')]: {
      marginLeft: 0,
    },

    ...(() => {
      switch (env) {
        case 'localhost':
          return {
            color: theme.colors.blue[3],
          };
        case 'development':
          return {
            color: theme.colors.blue[3],
          };
        case 'staging':
          return {
            color: theme.colors.orange[3],
          };
        default:
          return {};
      }
    })(),
  },
}));

function EnvLabel() {
  const env = process.env.NEXT_PUBLIC_ENV;
  const { classes } = useStyles({ env });

  if (env === 'production') {
    return null;
  }

  return (
    <div className={classes.envLabel}>
      {env}
      {env === 'staging' ? `@v${packageJSON.version}` : ''}
    </div>
  );
}

export default EnvLabel;
