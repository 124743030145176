import { useContext } from 'react';

import { LocationContext } from '~/components/providers/LocationProvider';

function useIsCanada() {
  const { locationCountry } = useContext(LocationContext);
  return locationCountry === 'canada';
}

export { useIsCanada };
