import { reportEvent } from '~/domains/analytics';
import { SocureKycStatus } from '~/services/users/types';

import type { AccessChecksProps, ProtectionGuardType } from '../types';

function userCheck({ handleOnAccessFailed, userId }: AccessChecksProps<ProtectionGuardType.USER>) {
  if (!userId) {
    handleOnAccessFailed(false);
    return false;
  }

  return true;
}

function kycCheck({
  action,
  contestId,
  isKYCloading,
  KYCStatus,
  redirectTo,
  closeModalsByType,
  handleOnAccessFailed,
  openModalUnique,
}: AccessChecksProps<ProtectionGuardType.KYC>) {
  if (isKYCloading) return;

  // Analytics reporting
  const reportFailedEvent = () => {
    reportEvent('ProtectionGuard > KYC Check Failed', {
      action,
      contestId,
      KYCStatus,
    });
  };

  if (KYCStatus === SocureKycStatus.DOCV_IN_PROGRESS) {
    openModalUnique({
      type: 'VerifyingDocumentsModal',
    }).then(handleOnAccessFailed);

    reportFailedEvent();
    return false;
  }

  if (KYCStatus !== SocureKycStatus.ACCEPT) {
    openModalUnique({
      type: 'UserNotVerifiedModal',
      desiredAction: action,
      redirectTo,
    }).then(handleOnAccessFailed);

    reportFailedEvent();
    return false;
  }
  if (KYCStatus === SocureKycStatus.ACCEPT) {
    closeModalsByType('UserNotVerifiedModal');
  }

  return true;
}

export { userCheck, kycCheck };
