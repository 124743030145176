import { createStyles } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { Button } from '~/domains/design-system/Button';
import Dialog from '~/domains/design-system/components/Dialog/Dialog';
import DialogBody from '~/domains/design-system/components/Dialog/DialogBody/DialogBody';
import { Text } from '~/domains/design-system/Text';
import { Title } from '~/domains/design-system/Title';

const useStyles = createStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
    color: theme.colors.gray2[4],
    marginBottom: theme.other.spacing._24,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.other.spacing._8,
    maxWidth: 420,
    margin: '0 auto',

    [theme.fn.largerThan('sm')]: {
      width: 420,
    },
  },
}));

type StatusModalBodyProps = {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
  icon: React.ReactNode;
  title: string;
  text: string | React.ReactNode;
  text2?: string | React.ReactNode;
  text3?: string | React.ReactNode;
  cta?: React.ReactNode;
  secondaryCta?: React.ReactNode;
};

function StatusModalBody({
  isOpen,
  onClose,

  icon,
  title,
  text,
  text2,
  text3,
  cta,
  secondaryCta,
}: StatusModalBodyProps) {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { t } = useTranslation('contest');

  return (
    <Dialog
      type={{
        mobile: 'drawer',
        desktop: 'modal',
      }}
      isOpen={isOpen}
      onClose={() => onClose(false)}
    >
      <DialogBody className={classes.body}>
        {icon}
        <Title variant="headline-xl" className={classes.title}>
          {title}
        </Title>
        <Text variant="body-large" className={classes.text}>
          {text}
        </Text>
        {text2 && (
          <Text variant="body-large" className={classes.text}>
            {text2}
          </Text>
        )}
        {text3 && (
          <Text variant="body-large" className={classes.text}>
            {text3}
          </Text>
        )}
        {cta}
        {isMobile && (
          <Button variant="tertiary-fill" fullWidth onClick={() => onClose(false)}>
            {t('common.userStatusModals.common.dismissCta')}
          </Button>
        )}
        {secondaryCta}
      </DialogBody>
    </Dialog>
  );
}

export default StatusModalBody;
