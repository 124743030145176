export default {
  maintenanceWrapper: {
    title: "We'll be back",
    subtitle: 'We are performing scheduled maintenance. Please check back soon.',
  },
  timeoutWrapper: {
    title: 'Your account currently has a self-imposed timeout',
    subtitle: 'Your timeout will expire on {{timeoutDate}}',
    signOut: 'Sign Out',
  },
};
