// Swagger: http://localhost:4000/contests-service/api#/contests/ContestsApiController_getContestById
// Tech Spec: https://betterpool.atlassian.net/wiki/spaces/EDGE/pages/78905353/API+Requirements+-+WIP#Get-Contests-Details

import type { ContestCreateResponse, ContestType } from '@betterpool/api-types/contests-service';
import queryString from 'query-string';
import { AxiosError } from 'axios';

import type { CreateContestPayload } from '~/domains/commissioner/interfaces/contest.interface';
import type { SortDirection } from '~/domains/common/enums/sort-direction.enum';
import type { SortOptions } from '~/domains/common/enums/sort-options.enum';
import { createQueryString } from '~/domains/common/utils/queryString';
import {
  contestImageUploadService,
  contestsServiceInstance,
} from '~/domains/contest/domains/services/contest.service.instance';
import { ContestStatus } from '~/domains/contest/domains/services/enums/contests-status.enum';
import type { UserContestAssociation } from '~/domains/contest/domains/services/enums/user-contest-association.enum';
import type { Contest } from '~/domains/contest/domains/services/interfaces/contest.interface';
import type { FullContest } from '~/domains/contest/domains/services/interfaces/full-contest.interface';

import type { PaginatedData } from '../common/interfaces/paginated-data.interface';
import type { SlateGroupPreset } from '../games-service/interfaces/slates.interface';

import type { AssetType } from './enums/contests-assets.enums';
import type { ContestFilters } from './interfaces/contest-filters.interface';
import type { LocationSettings } from './interfaces/contest-location-settings.interface';
import type { ContestTemplate } from './interfaces/contest-templates.interface';
import type { Entrant } from './interfaces/entrant.interface';

export type GetContestsPayload = {
  limit: number;
  offset: number;
  filter?: {
    sports?: string[];
    contestIds?: string[];
    entryTypes?: string[];
    contestTypes?: string[];
    payoutFormats?: string[];
    leagueId?: string;
    entryFee?: {
      min: number;
      max: number;
    };
    followedChannels?: boolean;
  };
  sort?: string;
  dir?: string;
  includeFull?: boolean;
};

export const downloadContestEntrants = async (contestId: string) => {
  const response = await contestsServiceInstance.get<Blob>(
    `/contest-users/contests/${contestId}/csv`,
    {
      responseType: 'blob',
      headers: {
        Accept: 'text/csv',
        'Content-Type': 'text/csv',
      },
    }
  );
  return response.data;
};

export const getContests = async (payload: GetContestsPayload): Promise<PaginatedData<Contest>> => {
  const response = await contestsServiceInstance.post<PaginatedData<Contest>>(
    `/contests/search`,
    payload
  );
  return response.data;
};

export const getContest = async (contestId: string, authToken?: string): Promise<FullContest> => {
  const response = await contestsServiceInstance.get<FullContest>(`/contests/${contestId}`, {
    headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
  });
  return response.data;
};

export const getContestEntriesForInvites = async (
  contestId: string,
  limit = 100,
  offset = 0
): Promise<PaginatedData<Entrant>> => {
  const response = await contestsServiceInstance.get<PaginatedData<Entrant>>(
    `/contests/${contestId}/entries?limit=${limit}&offset=${offset}`
  );
  return response.data;
};

export const getContestsByCommissionerId = async ({
  commissionerId,
  limit = 50,
  offset = 0,
  sort,
  dir,
  minEntries = 0,
  contestType,
  leagueId,
  contestStatuses = [ContestStatus.SCHEDULED],
  includePicksReminder = false,
  association,
}: {
  commissionerId: string;
  limit?: number;
  sort?: SortOptions;
  dir?: SortDirection;
  minEntries?: number;
  offset?: number;
  contestStatuses?: ContestStatus[];
  leagueId?: string;
  contestType?: ContestType;
  includePicksReminder?: boolean;
  association?: UserContestAssociation;
}): Promise<PaginatedData<Contest>> => {
  const query = queryString.stringify({
    limit,
    offset,
    sort,
    dir,
    minEntries,
    contestStatuses,
    includePicksReminder,
    contestType,
    leagueId,
    association,
  });
  const response = await contestsServiceInstance.get(
    `/commissioners/${commissionerId}/contests?${query}`
  );

  return response.data;
};

type CommissionerContestsEntriesSummary = {
  firstTimeEntrantsCount: number;
  usersMissingPicksCount: number;
  entryFeesTotal: number;
  entriesCount: number;
  maxEntriesCount: number;
};

export const getContestEntriesSummaryByCommissionerId = async (
  commissionerId: string,
  contestId: string
): Promise<CommissionerContestsEntriesSummary> => {
  const response = await contestsServiceInstance.get(
    `/commissioners/${commissionerId}/contests/${contestId}/entries/summary`
  );

  return response.data;
};

export const getContestTemplatesByLeagueId = async (
  leagueId: string,
  authToken?: string
): Promise<ContestTemplate[]> => {
  const response = await contestsServiceInstance.get<ContestTemplate[]>(
    `/leagues/${leagueId}/contest-templates`,
    {
      headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
    }
  );
  return response.data;
};

export const getContestTemplatesSlateGroupsPresets = async (
  leagueId: string,
  contestTemplateId: string,
  authToken?: string
): Promise<SlateGroupPreset[]> => {
  // Cache policy is set to 10sec on API level
  try {
    const response = await contestsServiceInstance.get<SlateGroupPreset[]>(
      `/leagues/${leagueId}/contest-templates/${contestTemplateId}/slate-groups-presets`,
      {
        headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      return [];
    }
    throw error;
  }
};

export const getPopularContestForCreation = async (
  authToken?: string
): Promise<{ title: string; contestId: string; subtitle?: string }[]> => {
  // Cache policy is set to 10sec on API level
  try {
    const response = await contestsServiceInstance.get<
      {
        title: string;
        subtitle: string;
        contestId: string;
      }[]
    >(`/contests/create/popular`, {
      headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
    });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      return [];
    }
    throw error;
  }
};

export const postCreateContest = async (payload: CreateContestPayload, authToken?: string) => {
  const response = await contestsServiceInstance.post<ContestCreateResponse>('/contests', payload, {
    headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
  });
  return response.data;
};

export const cancelContest = async (contestId: string) => {
  const response = await contestsServiceInstance.patch(`/contests/${contestId}`, {
    status: ContestStatus.CANCELLED,
  });
  return response.data;
};

export const updateContest = async (
  contestId: string,
  payload: Partial<CreateContestPayload>,
  authToken?: string
) => {
  const response = await contestsServiceInstance.patch(`/contests/${contestId}`, payload, {
    headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
  });
  return response.data;
};

export const uploadContestAsset = async (payload: {
  file: File;
  assetType: AssetType;
}): Promise<{ id: string; url: string }> => {
  const response = await contestImageUploadService.post('/', payload);
  return response.data;
};

export const getContestFiltersByLeagueId = async (
  leagueId: string,
  authToken?: string
): Promise<ContestFilters> => {
  const response = await contestsServiceInstance.get<ContestFilters>(
    `/contests/filters${createQueryString({ leagueId })}`,
    {
      headers: authToken ? { Authorization: `Bearer ${authToken}` } : undefined,
    }
  );
  return response.data;
};

export const getLocationSettings = async (): Promise<LocationSettings> => {
  const response = await contestsServiceInstance.get<LocationSettings>(`/tags/location-settings`);
  return response.data;
};

// ------------------------------ MANAGE ENTRANTS ------------------------------
export type FullEntrant = {
  id: string;
  user: {
    avatar: string;
    email: string;
    firstName: string;
    lastName: string;
    handle: string;
  };
  notes?: string;
  disabled?: boolean;
  booted?: boolean;
  entryCount?: number;
  entriesWithPicks?: number;
  enteredAt?: Date;
}
type GetEntrantsForContestResponse = PaginatedData<FullEntrant>

export const getEntrantsForContest = async (
  contestId: string,
  offset = 0,
  limit = 1,
  search?: string
): Promise<GetEntrantsForContestResponse> => {
  const response = await contestsServiceInstance.get(
    `/contest-users/contests/${contestId}${createQueryString({ offset, limit, search })}`
  );
  return response.data;
};

export const updateEntrantNotes = async (contestId: string, entrantId: string, notes: string) => {
  const response = await contestsServiceInstance.patch(
    `/contest-users/${entrantId}/contests/${contestId}`,
    { notes }
  );
  return response.data;
};

export const updateContestEntrantStatus = async (
  contestId: string,
  entrantId: string,
  disabled: boolean
) => {
  const response = await contestsServiceInstance.patch(
    `/contest-users/${entrantId}/contests/${contestId}`,
    { disabled }
  );
  return response.data;
};

// We don't support unbooting entrants, when we do we can add a boolean to the payload or a separate function
export const bootContestEntrant = async (contestId: string, entrantId: string) => {
  const response = await contestsServiceInstance.patch(
    `/contest-users/${entrantId}/contests/${contestId}`,
    { booted: true }
  );
  return response.data;
};
