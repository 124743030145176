import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import type { APIAuthUserData } from '~/services/users/types';
import { isBrowserFn } from '~/utils/browser';
import {
  deleteSessionStorage,
  readSessionStorage,
  writeSessionStorage,
  updateSessionStorage,
} from '~/utils/storage';
import isWebView from '~/utils/isWebView';

import type { AnalyticsUser } from './types';

type UtmParams = 'utm_source' | 'utm_medium' | 'utm_campaign' | 'utm_term' | 'utm_content';
type ParsedUtmParams = Record<UtmParams, string>;

const ANONYMOUS_ID_KEY = 'ajs_anonymous_id';
const ANONYMOUS_ID_PREFIX = 'anonymous-user-';
const ANALYTICS_SESSION_ID = 'analytics-session-id-fallback';
const USER_DATA_KEY = 'user';
const USER_TRAITS_KEY = 'analytics-user-traits';
const FIRST_TOUCH_UTM_KEY = 'first_touch_utm';
const LAST_TOUCH_UTM_KEY = 'last_touch_utm';
const WEBVIEW_KEY = 'webView';

function parseBrandMeta(): {
  brand: string;
  brandPlatform: string;
  webView: boolean;
} {
  if (!isBrowserFn()) return;

  const urlParams = new URLSearchParams(window.location.search);

  return {
    brand: urlParams.get('brand')?.toLowerCase(),
    brandPlatform: urlParams.get('platform')?.toLowerCase(),
    webView: isWebView(),
  };
}

function parseUTMparamsFromURL(url?: string): ParsedUtmParams | undefined {
  // If not in browser (i.e. tests) and url is not provided, return undefined (to avoid errors)
  if (!isBrowserFn() && !url) return;

  // If URL not provided, use the current URL search params
  const urlParams = new URLSearchParams(url || window.location.search);
  const parsedUTMparameters = {} as ParsedUtmParams;

  urlParams.forEach((value, key) => {
    const queryKey = key.match(/utm_([^=]*)/i)?.[0] as UtmParams | undefined;
    if (queryKey) Object.assign(parsedUTMparameters, { [queryKey.toLowerCase()]: value });
  });

  return parsedUTMparameters;
}

const setAnonymousId = (id = `${ANONYMOUS_ID_PREFIX}${uuid()}`) => {
  Cookies.set(ANONYMOUS_ID_KEY, id, {
    expires: 365,
    domain: 'splashsports.com',
  });
  return id;
};

const getAnonymousId = () => {
  // Get the anonymous ID from the URL if it's set
  const urlParams = new URLSearchParams(window?.location?.search);
  const urlAnonymousID = urlParams.get('ref_anon_id');

  return urlAnonymousID || Cookies.get(ANONYMOUS_ID_KEY) || setAnonymousId();
};

const getStoredUser = () => readSessionStorage<APIAuthUserData>(USER_DATA_KEY);

const getUserTraits = () => readSessionStorage<Partial<AnalyticsUser>>(USER_TRAITS_KEY);
const updateUserTraits = (traits: Partial<AnalyticsUser>) =>
  updateSessionStorage(USER_TRAITS_KEY, traits);
const deleteUserTraits = () => deleteSessionStorage(USER_TRAITS_KEY);

const setWebViewCookie = () => isWebView() && Cookies.set(WEBVIEW_KEY, 'true');
const getFirstTouchUTM = () => readSessionStorage<ParsedUtmParams>(FIRST_TOUCH_UTM_KEY);
const setFirstTouchUTM = () => writeSessionStorage(FIRST_TOUCH_UTM_KEY, parseUTMparamsFromURL());
const getLastTouchUTM = () => readSessionStorage<ParsedUtmParams>(LAST_TOUCH_UTM_KEY);
const setLastTouchUTM = () => updateSessionStorage(LAST_TOUCH_UTM_KEY, parseUTMparamsFromURL());

type GA4SessionData = {
  ga_session_id: string;
  ga_session_number: string;
};
function getGA4SessionData(): GA4SessionData | undefined {
  if (!isBrowserFn()) return;

  // GA4 stream ID that stores the cookie for splashsports.com
  const GAstreamId = process.env.NEXT_PUBLIC_GA4_STREAMID;
  const cookie = Cookies.get(`_ga_${GAstreamId}`);

  // If GA session ID is not stored, get or generate the fallback
  if (!cookie) {
    let sessionID = Cookies.get(ANALYTICS_SESSION_ID);
    if (!sessionID) {
      sessionID = Date.now().toString().slice(0, -3);
      Cookies.set(ANALYTICS_SESSION_ID, sessionID);
    }

    return {
      ga_session_id: sessionID,
      ga_session_number: '1',
    };
  }

  const parsedCookie = cookie.split('.').slice(2, -1);
  return {
    ga_session_id: parsedCookie.shift(),
    ga_session_number: parsedCookie.shift(),
  };
}

type FBSessionData = {
  fbp: string;
  fbc: string;
};
// TODO: Parse from URL, because we won't have a cookie...
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
function getFBSessionData(): FBSessionData | undefined {
  if (!isBrowserFn()) return;

  // Parse URL parameter, fallback on cookie
  const fbClid = window.location.search.match(/[&?]fbclid=([^&]*)/)?.[1];
  const formattedFbc = fbClid ? `fb.1.${Date.now()}.${fbClid}` : undefined;

  const fbp = Cookies.get('_fbp');
  const fbc = formattedFbc || Cookies.get('_fbc');

  return { fbp, fbc };
}

const removeAnalyticsSessionId = () => {
  Cookies.remove(ANALYTICS_SESSION_ID);
};

export {
  // Analytics meta
  getAnonymousId,
  getFBSessionData,
  getGA4SessionData,

  // User data
  getStoredUser,
  deleteUserTraits,
  getUserTraits,
  updateUserTraits,

  // UTM params
  parseBrandMeta,
  setWebViewCookie,
  getFirstTouchUTM,
  setFirstTouchUTM,
  getLastTouchUTM,
  setLastTouchUTM,

  // Misc
  removeAnalyticsSessionId,
};
