import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { useWalletBalance } from '~/domains/payments/hooks/useWalletBalance';
import { RootModalsContext } from '~/domains/common/context/RootModalsContext/RootModalsContext';
import { useContest } from '~/domains/contest/hooks/useContest';
import { Routes } from '~/domains/common/constants/routes';

import { AsideContentType } from '../../../game/domains/common/types/aside';
import type { FullContest } from '../../../services/interfaces/full-contest.interface';
import { useIsGamecenterEnabled } from '../../../gamecenter/domains/common/hooks/useIsGamecenterEnabled';

import useCreateEntries from './useCreateEntries';

const REDIRECT_TIMEOUT = 3000;

const getRef = (router: ReturnType<typeof useRouter>, contest: FullContest) => {
  if (
    router.asPath.startsWith(
      Routes.gameshell({
        contestId: contest.id,
        sport: contest.league.sport,
        contestType: contest.contest_type,
      })
    )
  ) {
    return router.query.ref as string;
  }

  return router.pathname;
};

type AddEntriesModalProps = {
  contestId: string;
  onClose: () => void;
  onSuccess?: (entryIDs: string[]) => void;
};

function useEnterContestModal({ contestId, onClose, onSuccess }: AddEntriesModalProps) {
  const [createdEntryCount, setCreatedEntryCount] = useState(0);
  const nodeRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [currentLocation, setCurrentLocation] = useState(router.pathname);

  const { data: balance, isLoading: isBalanceLoading } = useWalletBalance();
  const { openModal, closeModal, disableClose, enableClose } = useContext(RootModalsContext);
  const closeTimerRef = useRef(null);
  const contest = useContest(contestId, !!contestId);

  const {
    createEntriesErrorMessage,
    isCreateEntriesLoading,
    createEntries,
    isCreateEntriesReady,
    isCreateEntriesSuccess,
  } = useCreateEntries({
    isAfterDeadline: contest.data?.is_after_first_late_swap_deadline,
    isEntertainmentOnly: contest.data?.settings?.entertainmentOnlySettings?.enabled,
    entries: contest.data?.entries,
    isAllowedInLocation: contest.data?.is_allowed_in_location,
    status: contest.data?.status,
    onClose,
    contestId,
  });
  const { isEnabled } = useIsGamecenterEnabled(
    contest.data?.contest_type,
    contest.data?.league.sport
  );

  const isLoading = isBalanceLoading || contest.isLoading;
  const handleCreateSuccess = useCallback(
    (entryIDs: string[]) => {
      if (!contest.data) {
        return;
      }

      onSuccess?.(entryIDs);
      setCreatedEntryCount(entryIDs.length);
      const isEntertainmentOnly = contest.data.settings?.entertainmentOnlySettings?.enabled;

      closeModal();
      const ref = getRef(router, contest.data);
      const pushPath = (() => {
        if (!isEnabled) {
          return Routes.gameshellPicksheet({
            contestId: contest.data.id,
            contestType: contest.data.contest_type,
            sport: contest.data.league.sport,
            referrer: ref,
            ...(entryIDs.length > 1
              ? {
                  aside: AsideContentType.MY_ENTRIES,
                }
              : {
                  entryId: entryIDs[0],
                }),
          });
        }

        if (entryIDs.length > 1) {
          return Routes.gamecenterEntriesOverall(contest.data.id);
        }
        return Routes.gamecenterPicks(contest.data.id, {
          entryId: entryIDs[0],
        });
      })();

      disableClose();
      openModal({
        type: 'EnterContestModalSuccess',
        entryCount: entryIDs.length,
        redirectTo: pushPath,
        balanceInDollars: isEntertainmentOnly ? undefined : balance?.availableBalance,
      });
    },
    [
      balance?.availableBalance,
      closeModal,
      contest.data,
      disableClose,
      isEnabled,
      onSuccess,
      openModal,
      router,
    ]
  );

  const handleCreateEntries = useCallback(
    async (entriesCount: number) => {
      try {
        disableClose();
        await createEntries({
          contestId,
          entryFee: contest.data?.entry_fee || 0,
          entriesCount,
          isEntertainmentOnly: contest.data?.settings?.entertainmentOnlySettings?.enabled,
          callback: (entryIDs) => {
            handleCreateSuccess(entryIDs);
            return Promise.resolve();
          },
        });
      } catch (e) {
        enableClose();
        console.error('Error creating entries', e);
      }
    },
    [
      contest.data?.entry_fee,
      contest.data?.settings?.entertainmentOnlySettings?.enabled,
      contestId,
      createEntries,
      disableClose,
      enableClose,
      handleCreateSuccess,
    ]
  );

  const handleOpenDepositModal = useCallback(() => {
    openModal({
      type: 'DepositModal',
      contestIdForPostDeposit: contestId,
    });
  }, [contestId, openModal]);

  useEffect(
    () => () => {
      clearTimeout(closeTimerRef.current);
    },
    []
  );

  useEffect(() => {
    if (!currentLocation) {
      setCurrentLocation(router.pathname);
    } else if (currentLocation !== router.pathname) {
      onClose();
    }
  }, [currentLocation, onClose, router.pathname]);

  const state = useMemo(() => {
    if (isLoading) {
      return 'loading';
    }
    if (isCreateEntriesLoading) {
      return 'submitting';
    }
    if (isCreateEntriesSuccess) {
      return 'success';
    }

    return 'default';
  }, [isCreateEntriesLoading, isCreateEntriesSuccess, isLoading]);

  return {
    state,
    handleOpenDepositModal,
    handleCreateEntries,
    nodeRef,
    createEntriesErrorMessage,
    createdEntryCount,
    availableBalance: balance?.availableBalance,
    contest,
    isCreateEntriesReady,
  };
}

export default useEnterContestModal;

export { REDIRECT_TIMEOUT };
