export default {
  breadcrumbs: {
    invites: 'Invites',
    newInvite: 'New Invite',
    reviewInvite: 'Review Invite',
    myChannel: 'My Channel',
  },
  tabs: {
    followers: 'Followers',
    followersWithCount: 'Followers ({{followersCount}})',
    groups: 'Groups',
    groupsWithCount: 'Groups ({{groupsCount}})',
    rypPoolsWithCount: 'RYP pools ({{poolsCount}})',
    ofpPoolsWithCount: 'OFP pools ({{poolsCount}})',
    email: 'Email',
    ryp: 'RYP',
    ofp: 'OFP',
    link: 'Link',
  },
  sendNewInvite: 'Send new invite',
  resendInvites: 'Send reminder',
  reviewInvite: 'Review invite',
  sendInvite: 'Send invite',
  sending: 'Sending...',
  availableInMobileApp: 'Currently available only in mobile apps',
  noResults: {
    title: 'No invites sent',
    text: 'Send a contest invite to followers or copy/paste the contest link.',
  },
  error: {
    title: 'Something went wrong',
    text: 'Please try again later.',
  },
  emails: {
    reset: 'Clear',
    submit: 'Import',
    noResults: {
      title: 'No emails imported',
      text: 'Imported emails will appear here for you to manage and add to the invite.',
    },
  },
  notifications: {
    successBefore: {
      message: 'Inviting players... (this may take a few minutes)',
    },
    successAfter: {
      message__hasPlural: {
        one: '1 invite sent successfully!',
        other: '{{count}} invites sent successfully!',
      },
    },
    error: {
      title: 'Could not send invitations',
      message: 'There was an error sending the invitations. Please try again later.',
    },
  },
  stats: {
    emailInvites: 'EMAIL INVITES',
    info: 'A breakdown of Invites, Entered and Pending is only available for invites sent to followers or contests.',
    entriesAvailable: 'ENTRIES AVAILABLE',
    invites: 'INVITES',
    entered: 'ENTERED',
    pending: 'PENDING',
    invited: 'INVITES ({{totalCount}})',
    sent: 'SENT',
    status: 'STATUS',
  },
  noFollowers: {
    title: 'No followers',
    text: 'Here you’ll be able to select individual followers and add them to your contest invite.',
  },
  noGroups: {
    title: 'No groups',
    text: 'When you run a contest, Splash automatically creates a group of your entrants so you can easily invite past entrants to your new contest.',
  },
  review: {
    info: 'Invites will not be sent to players you already invited or players that already entered this contest.',
    noFollowers: {
      title: 'No followers added',
      text: 'Go back to add followers to the invite.',
    },
    noGroups: {
      title: 'No groups added',
      text: 'Go back to add groups to the invite.',
    },
    noRyp: {
      title: 'No RYP pools added',
      text: 'Go back to add RYP pools to the invite.',
    },
    noOfp: {
      title: 'No OFP pools added',
      text: 'Go back to add OFP pools to the invite.',
    },
  },
  form: {
    placeholder: `Enter or paste emails to import.\n •  Separate emails with a comma and/or space.\n •  Duplicate emails will not be imported.`,
    info: 'Need to import a large email list or access an invite list from an RYP or OFP contest? Use <link>VIP Support</link>',
  },
  ryp: {
    title: 'Link your RYP account',
    description: 'With linked RYP account, you can invite players from your RYP pools.',
    link: 'Link RYP',
    cta: 'Send new invite',
    allPools: 'All pools ({{poolsCount}})',
    noPools: {
      title: 'No pools',
      text: 'Looks like you don’t have any RYP pools.',
    },
    csv: 'CSV',
  },
  ofp: {
    title: 'Link your OFP account',
    description: 'With linked OFP account, you can invite players from your OFP pools.',
    link: 'Link OFP',
    cta: 'Send new invite',
    allPools: 'All pools ({{poolsCount}})',
    noPools: {
      title: 'No pools',
      text: 'Looks like you don’t have any OFP pools.',
    },
    csv: 'CSV',
  },
  processing: {
    alert: 'Your invites are still being processed, please wait before you can send more.',
    button: 'Processing...',
  },
  modals: {
    download: {
      title: 'Download invites list',
      description: 'You can download the contact information of your invited members.',
      descriptionLoading:
        'Please wait while we generate the file. This can take a few seconds depending on the number of invites. Your download will start automatically.',
      cta: 'Download .csv',
      failed: {
        title: 'Download Failed',
        failedToQueue: 'Failed to queue download. Please try again later.',
        failedToGenerate: 'Failed to generate CSV file. Please try again.',
        failedToPoll: 'Failed to check download status. Please try again.',
      },
    },
    downloadMembers: {
      title: 'Download members list',
      description: 'You can download the contact information of your members from your past pool.',
      descriptionLoading:
        'Please wait while we generate the file. This can take a few seconds depending on the number of members. Your download will start automatically.',
      cta: 'Download .csv',
      failed: {
        title: 'Download Failed',
        failedMemberDownload: 'Failed to download members. Please try again.',
      },
    },
    tosModal: {
      title: 'Comissioner terms',
      description:
        'As the commissioner, you cannot abuse your list of entrants. Send a new invite to your followers and/or players that entered your contests. Track the status of your invites here.',
      cta: 'Accept and continue',
      failed: {
        title: 'Failed to accept Terms Of Service',
        description: 'There was an error accepting the terms of service. Please try again later.',
      },
    },
    reviewInvites: {
      title: 'Review invite',
      cta: 'Send invite',
    },
  },
  linkModal: {
    description:
      'Copy the link below and paste it into an email, social media site, or any other destination.',
  },
  secondaryActions: {
    downloadInvites: 'Download invites list',
    downloadMembers: 'Download members list for this pool',
    noInvitesToDownload: 'No invites to download',
    noMembersToDownload: 'No members to download',
    copyLink: 'Copy invite link',
    copyLinkButton: 'Copy link',
  },
  commissionerCallout: {
    title: 'Welcome Commissioner!',
    description1: `If you want to send an email from your favorite email client, copy your invite link below.  You can access your List by clicking on the RYP or OFP tab below, selecting your contest name, and then clicking the CSV button. This will download the email addresses of members from that contest.`,
    description2: `You can also send invitations through the Splash Platform. To re-invite past members, click on your pool or contest under the relevant tab and select "Review Invite." Previously hosted pools on RYP or OfficeFootballPool (OFP) can be accessed under their respective tabs. On the Splash platform, "Followers" refers to users who follow you, and "Groups" are contests you've previously hosted. Once invitations are sent, you can track them using the "Invites" widget on the left side of the screen.`,
  },
  inviteListTooltip: {
    overview: 'All names listed below have received an email with an invitation to your contest.',
    entriesAvailable: 'Entries Available: The number of open slots you have left in your contest.',
    invites:
      'Invites: The total number of people you have invited. This includes both ‘Entered’ and ‘Pending’ invites.',
    entered: 'Entered: The number of people who have accepted your invite and joined the contest.',
    pending:
      'Pending: The number of people who have received your invite but have not joined the contest.',
  },
};
