import AgeRestrictionModal from './AgeRestrictionModal';
import InvalidLocationModal from './InvalidLocationModal';
import LocationErrorModal from './LocationErrorModal';
import LocationPermissionRequiredModal from './LocationPermissionRequiredModal';
import LocationRestrictionModal from './LocationRestrictionModal';
import UserNotVerifiedModal from './UserNotVerifiedModal';
import VerifyingDocumentsModal from './VerifyingDocumentsModal';

export {
  AgeRestrictionModal,
  InvalidLocationModal,
  LocationErrorModal,
  LocationPermissionRequiredModal,
  LocationRestrictionModal,
  UserNotVerifiedModal,
  VerifyingDocumentsModal,
};
