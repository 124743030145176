/* eslint-disable no-restricted-syntax */
import type { UseFormReturnType } from '@mantine/form';
import type { ContestType as ContestApiType } from '@betterpool/api-types/contests-service';

import type { ResizingMode } from '~/domains/contest/domains/services/enums/contest-resizable.enum';
import type { ContestStatus } from '~/domains/contest/domains/services/enums/contests-status.enum';

export enum ContestType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export enum CreatedByType {
  ADMIN = 'admin',
  SELF = 'self',
}

export enum ResizingModeType {
  RESIZABLE = 'RESIZABLE',
  GUARANTEED = 'GUARANTEED',
  STANDARD = 'STANDARD',
}

export enum PayoutType {
  TOP_X = 'TOP_X',
  MULTIPLIER = 'MULTIPLIER',
}

export type CreateContestFormStepProps = {
  form: UseFormReturnType<CreateContestFormValues>;
  contestTemplateType?: ContestApiType;
};

interface CreateContestBaseValues {
  rosterCategoryRulesSettings?: {
    // Required for contests where
    // ContestTemplate.contestType === 'player_category'
    // e.g. Flex position
    [key: string]: boolean;
  };
  scoreRulesSettings?: {
    // Not Required. Only applicable for contests where
    // ContestTemplate.contestType === 'player_tier', 'player_category'
    // e.g. "Birdies are worth 5 pts"
    [key: string]: boolean;
  };
  // Step 3
  payoutType: PayoutType;
}

export interface CreateContestFormValues extends CreateContestBaseValues {
  unlisted: ContestType;
  entryApproval: 'autoApprove' | 'manualApprove';
  tierRulesSettings?: {
    numberOfTiers?: number;
    numberPerTier?: number;
    metricName?: string; // default to tierRules/metrics[0].name
  };
  entryCost?: string;
  maxEntries?: string;
  maxEntriesPerUser?: string;
  payoutSettings?: string;
  leagues?: string;
  contestDescription?: string;
  contestTemplate?: string;
  slateGroups: string | string[];
  tags: string;
  createdByAdmin: string;
  resizingMode: ResizingModeType;
  rewardsOptIn: 'yes' | 'no';
  beginnersOnly: 'yes' | 'no';
  // NFL Survivor and NFL Pick'em Only
  expectedPicksCount?: string;
  isDoublePicks?: string;
  doublePicksWeekStart?: string;
  joinValues?: string[];
  makeListedAt?: Date;
  prizePool?: number;
  settings?: {
    pickReuseLimit?: string;
    slateScoringType?: 'default' | 'weighted' | null;
    slateWeights?: {
      [slateGroupId: string]: number;
    };
    bestPicks?: {
      [slateGroupId: string]: number;
    };
    pickDeadlineType?: 'slate' | 'game';
  };
  excludedGames: {
    [key: string]: string[];
  };
  selectedSlateGroupGroup?: string;
}

export interface CreateContestPayload extends CreateContestBaseValues {
  contestName: string;
  description: string;
  bannerId: string;
  bannerUrl: string;
  unlisted: boolean;
  contestTemplateId: string;
  tierRulesSettings?: {
    numberOfTiers?: number;
    numberPerTier?: number;
    metricName?: string; // default to tierRules/metrics[0].name
  };
  schedule: {
    scheduleSlateGroups: {
      id: string;
      optionTagIds?: string[];
      gameIds?: string[];
    }[];
  };
  entryCost: number; // in cents
  maxEntries: number;
  maxEntriesPerUser: number;
  payoutSettings: {
    settings: {
      // Don't pass both
      schedule?: number[]; // (Required when payoutType.TOP_X; arrayOfNumbers sum must equal 100)
      multiplier?: number; // (Required when payoutType.MULTIPLIER; number must be greater than 1)
    };
  };
  createdByAdmin: boolean;
  resizingMode: ResizingModeType;
  rewardsEnabled: boolean;
  makeListedAt: Date; // Send to API in UTC
  settings: {
    pickReuseLimit?: number | string;
    slateScoringType?: 'default' | 'weighted' | null;
    slateWeights?: Record<string, number>;
    bestPicks?: Record<string, boolean>;
    pickDeadlineType?: string;
    dropWorstCount?: number;
    expectedPicksCount?: number;
    additionalLives?: number;
    revivals?: {
      enabled: boolean;
    };
    tiebreakers?: {
      enabled: boolean;
    };
  };
}

export interface CreateContestResponse {
  contestTemplateId: string;
  scheduleId: string;
  ownerId: string;
  contestName: string;
  maxEntries: number;
  maxEntriesPerUser: number;
  entryCost: number;
  rosterCategoryRulesSettings: {
    [key: string]: boolean;
  };
  tierRulesSettings: {
    numberOfTiers?: number;
    numberPerTier?: number;
    metricName?: string;
  };
  scoreRulesSettings: { [key: string]: boolean };
  startDate: Date;
  endDate: Date;
  payoutSettings: {
    settings: {
      schedule: number[];
    };
  };
  resizingMode: ResizingMode;
  commissionerFeePercent: number;
  prizePool: number;
  payoutType: PayoutType;
  payoutSchedule: {
    label: string;
    value: string;
    order: number;
  }[];
  rules: string;
  shortRules: string;
  unlisted: boolean;
  createdByAdmin: boolean;
  settings: {
    expectedPicksCount: number;
    entryLives: number;
  };
  id: string;
  createdAt: Date;
  status: ContestStatus;
}
