/* eslint-disable no-console */
import SingletonRouter from 'next/router';
import type { PropsWithChildren } from 'react';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import log from '~/common/utils/log';

type PrevLocationContextValue = {
  prevPathname: string | null;
  prevAsPath: string | null;
};

const PrevLocationContext = createContext<PrevLocationContextValue>({
  prevPathname: null,
  prevAsPath: null,
});

function PrevLocationContextProvider({ children }: PropsWithChildren) {
  const currentAsPath = useRef<string | null>(null);
  const [prevPathname, setPrevPathname] = useState<string | null>(null);
  const [prevAsPath, setPrevAsPath] = useState<string | null>(null);

  useEffect(() => {
    // @ts-expect-error This is not typed becuse is super low level apparently
    if (!SingletonRouter.router?.change) {
      return;
    }

    // @ts-expect-error This is not typed becuse is super low level apparently
    const originalChangeFunction = SingletonRouter.router.change;

    // @ts-expect-error This is not typed becuse is super low level apparently
    SingletonRouter.router.change = async (...args) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [action, asPath] = args;

      const [currentPathname] = currentAsPath.current?.split('?') ?? [];
      const [newPathname] = asPath.split('?');

      log('newPathname', {
        newPathname,
        currentPathname,
        asPath,
        currentAsPath: currentAsPath.current,
      });
      // On init router returns pathname without params (like [contestUuid] instead of a real id)
      // We don't want these values to be saved
      if (/\[.*\]/g.test(newPathname)) {
        log('newPathname - skipping due to []');
        originalChangeFunction.apply(SingletonRouter.router, args);
        return;
      }

      if (newPathname !== currentPathname) {
        log('newPathname - setting pathname');
        setPrevPathname(currentPathname);
      }

      if (asPath !== currentAsPath.current) {
        log('newPathname - setting asPath');
        setPrevAsPath(currentAsPath.current);
        currentAsPath.current = asPath;
      }

      originalChangeFunction.apply(SingletonRouter.router, args);
    };

    return () => {
      // @ts-expect-error This is not typed becuse is super low level apparently
      SingletonRouter.router.change = originalChangeFunction;
    };
  }, []);

  const value = useMemo(
    () => ({
      prevPathname,
      prevAsPath,
    }),
    [prevPathname, prevAsPath]
  );

  return <PrevLocationContext.Provider value={value}>{children}</PrevLocationContext.Provider>;
}

export { PrevLocationContext, PrevLocationContextProvider };
