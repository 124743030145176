import { ActionIcon, Group, Stack, useMantineTheme } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import dt from '~/testing';
import { BankIcon } from '~/domains/payments/icons/BankIcon';
import type { BankAccount as BankAccountType } from '~/services/wallet/interfaces/wallets.interface';
import { Text } from '~/domains/design-system/Text';

type BankAccountProps = {
  bankAccount: BankAccountType;
  isDeleteBankAccountVisible: boolean;
  isDeletingAeropayAccount: boolean;
  deleteAeropayAccount: (params: { bankAccountId: string }) => void;
};

export function BankAccount({
  bankAccount,
  isDeleteBankAccountVisible,
  isDeletingAeropayAccount,
  deleteAeropayAccount,
}: BankAccountProps) {
  const theme = useMantineTheme();
  return (
    <Group
      data-test-id={dt.payments.withdrawal.components.bankAccount}
      w="100%"
      py={12}
      px={16}
      sx={{
        img: {
          width: 32,
          height: 32,
          borderRadius: 4,
        },
      }}
    >
      <BankIcon bankName={bankAccount.bankName} />
      <Stack align="flex-start" spacing={0} sx={{ flex: 1 }}>
        <Text variant="subhead-small" color="gray.9">
          {bankAccount.name}
        </Text>
        <Text variant="body-small" color="gray.6">
          {bankAccount.bankName}
        </Text>
      </Stack>
      {isDeleteBankAccountVisible && (
        <ActionIcon
          data-test-id={dt.payments.withdrawal.components.deleteBankAccountButton}
          loading={isDeletingAeropayAccount}
          onClick={() => {
            deleteAeropayAccount({ bankAccountId: bankAccount.bankAccountId });
          }}
        >
          <IconTrash size={20} color={theme.colors.gray[6]} />
        </ActionIcon>
      )}
    </Group>
  );
}
