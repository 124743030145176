/* eslint-disable @typescript-eslint/ban-ts-comment */
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { IconCalendarOff } from '@tabler/icons-react';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import { Button, ButtonLink } from '~/domains/design-system/Button';

import StatusModalBody from './components/StatusModalBody';

function AgeRestrictionModal({
  isOpen,
  onClose,
  queryKey,
}: {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
  queryKey: string[];
}) {
  const { t } = useTranslation('contest');

  const isFetching = useIsFetching({ queryKey });
  const queryClient = useQueryClient();

  const handleRecheckLocationClick = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return (
    <StatusModalBody
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconCalendarOff size={40} stroke={1.5} />}
      title={t('common.userStatusModals.ageRestriction.title')}
      text={
        <Trans
          i18nKey="contest:common.userStatusModals.ageRestriction.text"
          components={{
            link: (
              // @ts-ignore children are filled by the Trans component
              <ButtonLink
                mode="inlineLink"
                href="https://splashsports.com/where-can-you-play"
                target="_blank"
              />
            ),
          }}
        />
      }
      text2={
        <Trans
          i18nKey="contest:common.userStatusModals.common.contactSupport"
          components={{
            link: (
              // @ts-ignore children are filled by the Trans component
              <ButtonLink
                mode="inlineLink"
                href="mailto:support@splashsports.com?subject=Invalid Location Check"
                target="_blank"
              />
            ),
          }}
        />
      }
      cta={
        <Button
          variant="secondary-fill"
          fullWidth
          onClick={handleRecheckLocationClick}
          loading={isFetching > 0}
        >
          {t('common.userStatusModals.common.recheckLocationCta')}
        </Button>
      }
    />
  );
}

export default AgeRestrictionModal;
