import { createStyles } from '@mantine/core';

import dt from '~/testing';

const useStyles = createStyles((theme) => ({
  container: {
    padding: theme.other.spacing._24,

    [theme.fn.smallerThan('sm')]: {
      padding: theme.other.spacing._16,
    },
  },
}));

function DialogBody({ children, className, ...props }: React.HTMLProps<HTMLDivElement>) {
  const { classes, cx } = useStyles();

  return (
    <div
      data-test-id={dt.designSystem.components.dialogBody}
      className={cx(classes.container, className)}
      {...props}
    >
      {children}
    </div>
  );
}

export default DialogBody;
