import I18nProvider from 'next-translate/I18nProvider';
import React from 'react';

import accountTranslations from '~/domains/account/locales/en';
import appTranslations from '~/domains/app/locales/en';
import authTranslations from '~/domains/auth/locales/en';
import channelTranslations from '~/domains/channel/locales/en';
import commissionerTranslations from '~/domains/commissioner/locales/en';
import commonTranslations from '~/domains/common/locales/en';
import contestTranslations from '~/domains/contest/locales/en';
import entrantsManagerTranslations from '~/domains/entrants-manager/locales/en';
import faqsTranslations from '~/domains/faqs/locales/en';
import formsTranslations from '~/domains/forms/locales/en';
import homeTranslations from '~/domains/home/locales/en';
import invitesManagerTranslations from '~/domains/invites-manager/locales/en';
import notificationsTranslations from '~/domains/notifications/locales/en';
import oauthTranslations from '~/domains/oauth/locales/en';
import paymentsTranslations from '~/domains/payments/locales/en';
import tutorialsTranslations from '~/domains/tutorials/locales/en';
import userTranslations from '~/domains/user/locales/en';

const NumberFormatters = {
  en: new Intl.NumberFormat('en-EN'),
};

type TranslationsProviderProps = {
  children: React.ReactNode;
};

function TranslationsProvider({ children }: TranslationsProviderProps) {
  return (
    <I18nProvider
      lang="en"
      namespaces={{
        app: appTranslations,
        channel: channelTranslations,
        common: commonTranslations,
        user: userTranslations,
        contest: contestTranslations,
        payments: paymentsTranslations,
        commissioner: commissionerTranslations,
        faqs: faqsTranslations,
        tutorials: tutorialsTranslations,
        forms: formsTranslations,
        oauth: oauthTranslations,
        auth: authTranslations,
        account: accountTranslations,
        home: homeTranslations,
        'invites-manager': invitesManagerTranslations,
        notifications: notificationsTranslations,
        'entrants-manager': entrantsManagerTranslations,
      }}
      config={{
        interpolation: {
          format: (value, format, lang) => {
            if (format === 'number' && NumberFormatters[lang]) {
              return NumberFormatters[lang].format(value);
            }

            return value;
          },
        },
        logger: process.env.NODE_ENV === 'test' ? () => {} : undefined,
      }}
    >
      {children}
    </I18nProvider>
  );
}

export default TranslationsProvider;
