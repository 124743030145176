import { Anchor, Divider, Menu, useMantineTheme } from '@mantine/core';
import { IconInfoTriangle } from '@tabler/icons-react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useContext, useMemo } from 'react';

import { FEATURE } from '~/common/enums/feature.enum';
import { useHomePageEnabled } from '~/common/hooks/useHomePageEnabled';
import { KYCContext } from '~/components/providers/KYCProvider';
import { UserContext } from '~/components/providers/UserProvider';
import { Routes } from '~/domains/common/constants/routes';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { useCopyReferAFriendLink } from '~/domains/common/hooks/useCopyReferAFriendLink';
import { useMyContestsPageEnabled } from '~/domains/common/hooks/useMyContestsPageEnabled';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';
import useLogout from '~/hooks/useLogout';
import dt from '~/testing';
import { isBrowserFn } from '~/utils/browser';
import { SocureKycStatus } from '~/services/users/types';

import { useCurrentUserCommissionerChannel } from '../../hooks/useCurrentUserCommissionerChannel';

import { useStyles } from './HeaderNavigation.styles';

const divider = 'divider';

export function HeaderNavigation() {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();
  const { isHomePageEnabled } = useHomePageEnabled();
  const { isMyContestsPageEnabled } = useMyContestsPageEnabled();
  const isChannelsV1Enabled = useIsFeatureEnabled(FEATURE.ENABLE_CHANNELS_V1);

  const { KYCStatus } = useContext(KYCContext);
  const { isLoading: isLoadingCommissioner, data: channelData } =
    useCurrentUserCommissionerChannel();
  const { handleLogout } = useLogout();
  const { handleCopyLink } = useCopyReferAFriendLink();
  const { classes } = useStyles();

  const theme = useMantineTheme();
  const { userSsnStatus } = useContext(UserContext);

  const isCurrentUserCommissioner = !isLoadingCommissioner && channelData?.data?.slug;

  const links = useMemo(
    () => [
      ...(KYCStatus !== SocureKycStatus.ACCEPT
        ? [{ href: '/user-verification', id: 'verifyAccount' as const }, divider]
        : []),
      ...(isCurrentUserCommissioner
        ? [
            { href: Routes.createAContest(), id: 'createAContest' as const },

            ...(isChannelsV1Enabled
              ? [
                  {
                    href: Routes.channel(channelData?.data?.handle),
                    id: 'channel' as const,
                  },
                ]
              : [
                  {
                    href: Routes.commissionerHub(channelData?.data?.slug),
                    id: 'myCommissionerChannel' as const,
                  },
                ]),
          ]
        : [
            {
              href: Routes.commissionerOnboarding(),
              id: 'becomeACommissioner' as const,
            },
          ]),
      divider,
      ...(isMobile
        ? [
            ...(isHomePageEnabled ? [{ href: Routes.home(), id: 'home' as const }] : []),
            { href: Routes.contestLobby(), id: 'contestLobby' as const },
            {
              href: isMyContestsPageEnabled ? Routes.myContests() : Routes.myEntries(),
              id: isMyContestsPageEnabled ? ('myContests' as const) : ('myEntries' as const),
            },
            {
              href: '/',
              onClick: (event) => {
                event.preventDefault();
                handleCopyLink();
              },
              id: 'referAFriend' as const,
            },
          ]
        : [
            {
              href: Routes.deposit(
                isBrowserFn()
                  ? { redirectTo: `${window.location.pathname}${window.location.search}` }
                  : undefined
              ),
              id: 'deposit' as const,
            },
            {
              href: Routes.withdraw(
                isBrowserFn()
                  ? { redirectTo: `${window.location.pathname}${window.location.search}` }
                  : undefined
              ),
              id: 'withdraw' as const,
            },
          ]),
      divider,
      { href: Routes.account(), id: 'mySplashAccount' as const },
      {
        id: 'logout' as const,
        href: Routes.logout(),
        onClick: (event) => {
          event.preventDefault();
          handleLogout();
        },
      },
    ],
    [
      KYCStatus,
      channelData?.data?.handle,
      channelData?.data?.slug,
      handleCopyLink,
      handleLogout,
      isChannelsV1Enabled,
      isCurrentUserCommissioner,
      isHomePageEnabled,
      isMobile,
      isMyContestsPageEnabled,
    ]
  );

  return (
    <nav>
      <ul className={classes.ul}>
        {links.map((item, index) => {
          if (typeof item === 'string') {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className={classes.li} aria-hidden="true">
                <Divider className={classes.divider} />
              </li>
            );
          }

          const { href, id, onClick } = item;

          return (
            <Menu.Item component="li" key={id} className={classes.li}>
              <Anchor
                component={Link}
                href={href}
                onClick={onClick}
                className={classes.link}
                data-test-id={dt.components.shared.header.avatar.drawer.dropdownLink}
              >
                {t(`headerNavigation.${id}`)}
                {id === 'mySplashAccount' && userSsnStatus === 'required' ? (
                  <IconInfoTriangle size={20} color={theme.colors.gray[9]} fill="#FFD43B" />
                ) : null}
              </Anchor>
            </Menu.Item>
          );
        })}
      </ul>
    </nav>
  );
}
