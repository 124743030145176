import { Flex, useMantineTheme } from '@mantine/core';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import React from 'react';
import { P, match } from 'ts-pattern';

import { CurrencyConvertor } from '~/components/utils/formatters';
import { reportEvent } from '~/domains/analytics';
import { Routes } from '~/domains/common/constants/routes';
import { Text } from '~/domains/design-system/Text';
import { Title } from '~/domains/design-system/Title';
import { IconChevronRight } from '~/domains/design-system/icons/IconChevronRight';
import { IconPlus } from '~/domains/design-system/icons/IconPlus';
import dt from '~/testing';

import EntryButton from '../../../detail/components/EntryButton/EntryButton';

import type { ContestCardProps } from './types';

function Action({
  href,
  children,
  icon,
  onClick,
}: {
  href?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
}) {
  const theme = useMantineTheme();

  return (
    <Text
      data-test-id={dt.common.components.manageInvites}
      href={href}
      component={href ? Link : 'button'}
      onClick={onClick}
      variant="subhead-small"
      sx={{
        background: 'transparent',
        padding: 0,
        border: 0,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.colors.blue[8],
        '&:hover': {
          textDecoration: 'underline',
        },
        svg: {
          pointerEvents: 'none',
        },
      }}
    >
      {children}
      {icon || <IconChevronRight color={theme.colors.blue[8]} />}
    </Text>
  );
}

export function ContestCardActions({
  contest: { following, followed, ...contest },
  isInvitesManagerEnabled,
  isMassCommunicationEnabled,
  isPlayWithPeopleYouFollowEnabled,
  isEntertainmentOnly,
  isEntrantManagerEnabled,
}: {
  contest: ContestCardProps['contest'];
  isInvitesManagerEnabled: boolean;
  isMassCommunicationEnabled: boolean;
  isPlayWithPeopleYouFollowEnabled: boolean;
  isEntertainmentOnly: boolean;
  isEntrantManagerEnabled: boolean;
}) {
  const { t } = useTranslation('contest');
  const theme = useMantineTheme();
  const isSpectatorMode = contest?.public_viewing;

  return (
    <Flex
      justify="space-between"
      mt="17px"
      mx="-md"
      mb="-md"
      px="16px"
      py="6px"
      sx={{
        '.mantine-Grid-root + &': {
          marginTop: '12px',
          borderTop: `1px solid ${theme.colors.gray2[0]}`,
        },
      }}
    >
      {isInvitesManagerEnabled && (
        <Action href={Routes.invitesManager(contest.id)}>
          {t('common.cardActions.inviteManager')}
        </Action>
      )}
      {isEntrantManagerEnabled && (
        <Action href={Routes.entrantsManager(contest.id)}>
          {t('common.cardActions.entrantManager')}
        </Action>
      )}
      {isMassCommunicationEnabled && (
        <Action href="">{t('common.cardActions.massCommunication')}</Action>
      )}

      {isPlayWithPeopleYouFollowEnabled && (
        <Flex w="100%" justify="space-between" align="center">
          <Title variant="title-small">
            {match({ following, followed })
              .with({ following: P.when(() => following > 0) }, () =>
                t('home:playWithPeopleYouFollow.text.followeesEntered__hasPlural', {
                  count: following,
                })
              )
              .with({ followed: P.when(() => followed > 0) }, () =>
                t('home:playWithPeopleYouFollow.text.followersEntered__hasPlural', {
                  count: followed,
                })
              )
              .otherwise(() => null)}
          </Title>
          <EntryButton
            contestId={contest.id}
            entries={contest.entries}
            isAfterDeadline={contest.is_after_first_late_swap_deadline}
            status={contest.status}
            isAllowedInLocation={contest.is_allowed_in_location}
            isEntertainmentOnly={isEntertainmentOnly}
            isSpectatorMode={isSpectatorMode}
            onSuccess={(entryIDs) => {
              reportEvent('Lobby > Add Entries', {
                // How many of my followers are in this contest
                followers_playing: followed,
                // How many of people I follow are in this contest
                followees_playing: following,
                // Entry fee ($) per one entry
                entry_fee_usd: CurrencyConvertor(contest.entry_fee / 100),
                // Number of multiple entries (1 if only single)
                entries_count: entryIDs.length,
              });
            }}
          >
            <Action
              icon={<IconPlus />}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              {t('home:playWithPeopleYouFollow.cta__hasPlural', {
                count: contest.entries.max_per_user ?? 0 - (contest.entries.user ?? 0),
              })}
            </Action>
          </EntryButton>
        </Flex>
      )}
    </Flex>
  );
}
