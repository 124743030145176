import type { FullContest } from '@betterpool/api-types';

import { getContestBanner } from '~/domains/contest/domains/detail/components/ContestHeader/utils/getContestBanner';
import { getHandle } from '~/domains/user/utils/getHandle';

export const getContestPreviewImageData = (fullContest: Partial<FullContest>) => {
  const bannerUrl = getContestBanner({
    contestBannerlUrl: fullContest?.banner_url,
    sport: fullContest.league.sport,
  });

  const isEntertainmentOnly = fullContest?.settings?.entertainmentOnlySettings?.enabled;

  const data = {
    bannerUrl,
    creator: getHandle(fullContest.commissioner),
    prize: fullContest.prize_pool,
    deadline: fullContest.start_date,
    isEntertainmentOnly,
    entries: isEntertainmentOnly
      ? fullContest.entries.filled
      : `${fullContest.entries.filled}/${fullContest.entries.max}`,
  };

  return data;
};
