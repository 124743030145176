import useTranslation from 'next-translate/useTranslation';
import { IconFileAlert } from '@tabler/icons-react';

import { ButtonLink } from '~/domains/design-system/Button';

import StatusModalBody from './components/StatusModalBody';

function VerifyingDocumentsModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (isSubmit?: boolean) => void;
}) {
  const { t } = useTranslation('contest');

  return (
    <StatusModalBody
      isOpen={isOpen}
      onClose={onClose}
      icon={<IconFileAlert size={40} stroke={1.5} />}
      title={t('common.userStatusModals.verifyingDocumentsModal.title')}
      text={t('common.userStatusModals.verifyingDocumentsModal.text')}
      secondaryCta={
        <ButtonLink
          variant="primary-text"
          fullWidth
          href="mailto:support@splashsports.com?subject=Verifying Documents Status"
          onClick={() => {
            onClose(true);
          }}
        >
          {t('common.userStatusModals.verifyingDocumentsModal.contactSupport')}
        </ButtonLink>
      }
    />
  );
}

export default VerifyingDocumentsModal;
