export default {
  sections: {
    submit: 'Create a contest',
  },
  title: {
    main: 'Restart your contest',
    review: 'Review contest settings',
    created: 'Invite your members and get ready to play!',
  },
  subTitleBrand: 'Your existing settings from',
  introModal: {
    wrapperTitle: 'WELCOME TO',
    title: '{{templateName}} has been upgraded',
    text: "All {{templateName}} contests are now hosted on Splash Sports. Don't worry, your contest settings have been saved.",
    features: {
      entertainmentOnly: {
        _1: 'Free to play! No hosting fees for {{templateName}}',
        _2: 'Fast & automated live scoring',
        _3: 'Updated mobile app to follow your contests',
      },
      paid: {
        _1: 'Hassle-free entry fee collection',
        _2: 'Fast & automated live scoring',
        _3: 'Updated mobile app to follow your contests',
      },
    },
    cta: 'Continue',
  },
};
