import { useContext } from 'react';
import { Box } from '@mantine/core';

import { UserContext } from '~/components/providers/UserProvider';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import { FEATURE } from '~/common/enums/feature.enum';
import isWebView from '~/utils/isWebView';

import { AppShellHeader } from '../AppShellHeader/AppShellHeader';
import { AppShellSubheader } from '../AppShellSubheader/AppShellSubheader';
import { useTreatments } from '../../hooks/useTreatments';
import { HEADER_HEIGHT } from '../AppShellHeader2/constants';
import ConnectedAppShellHeader from '../AppShellHeader2/ConnectedAppShellHeader';

export function AppShellHeaderRoot() {
  const isMobile = useIsMobile();
  const { user } = useContext(UserContext);
  const { isReady, treatments } = useTreatments([FEATURE.ENABLE_NEW_HEADER_2]);
  const isEnabled = treatments[FEATURE.ENABLE_NEW_HEADER_2]?.treatment === 'on';

  if (isWebView()) {
    return false;
  }

  if (!isReady) {
    return <Box h={HEADER_HEIGHT} w="100%" bg="black" />;
  }

  if (isEnabled) {
    return <ConnectedAppShellHeader />;
  }

  return (
    <>
      <AppShellHeader isSticky={isMobile || !user} />
      {user && !isMobile ? <AppShellSubheader /> : null}
    </>
  );
}
