/* eslint-disable react/jsx-no-constructed-context-values */
import { SplitFactory } from '@splitsoftware/splitio-react';
import type { ISplitFactoryChildProps } from '@splitsoftware/splitio-react/types/types';
import { createContext, useContext, useEffect, useState } from 'react';

import { getAnonymousId } from '~/domains/analytics';

import { UserContext } from './UserProvider';

const FeatureFlagContext = createContext({
  isReady: false,
  factory: null,
  isTimedout: false,
  lastUpdate: null,
});

function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
  const { user } = useContext(UserContext);
  const [config, setConfig] = useState(null);
  const [anonConfig, setAnonConfig] = useState(null);

  useEffect(() => {
    const setUserConfig = async () => {
      const userConfig = {
        core: {
          authorizationKey: process.env.NEXT_PUBLIC_SPLIT_IO_KEY,
          key: user?.id, // Using user ID to synchronise user tracking with Segment
          custom: {
            email: user?.email,
            username: user?.username,
            isInternal: /@betterpool\.com$/.test(user.email),
            isTester: /^qe_tester[a-z0-9-_+]*@betterpool\.com$/.test(user.email),
          },
        },
      };
      setConfig(userConfig);
    };

    if (!user) {
      // Using global anonymous ID to synchronise anon tracking
      const anonymousId = getAnonymousId();

      const baseSdkConfig: SplitIO.IBrowserSettings = {
        core: {
          authorizationKey: process.env.NEXT_PUBLIC_SPLIT_IO_KEY,
          key: anonymousId,
        },
      };
      setAnonConfig(baseSdkConfig);
    } else {
      setUserConfig();
    }
  }, [user]);

  if (config) {
    return (
      <SplitFactory key={config?.toString()} config={config} updateOnSdkTimedout updateOnSdkUpdate>
        {({ factory, isReady, isTimedout, lastUpdate }: ISplitFactoryChildProps) => (
          <FeatureFlagContext.Provider value={{ factory, isReady, isTimedout, lastUpdate }}>
            {children}
          </FeatureFlagContext.Provider>
        )}
      </SplitFactory>
    );
  }

  if (anonConfig) {
    return (
      <SplitFactory
        key={config?.toString()}
        config={anonConfig}
        updateOnSdkTimedout
        updateOnSdkUpdate
      >
        {({ factory, isReady, isTimedout, lastUpdate }: ISplitFactoryChildProps) => (
          <FeatureFlagContext.Provider value={{ factory, isReady, isTimedout, lastUpdate }}>
            {children}
          </FeatureFlagContext.Provider>
        )}
      </SplitFactory>
    );
  }

  return null;
}

export default FeatureFlagProvider;
